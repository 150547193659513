import { Campaign } from './campaign.model';

export class Schedule {
    forDay: string;
    startTime: string;
    endTime: string;
    campaigns: Campaign[];
    fixed: Campaign[];
    player: any;
}


export class ScheduleTimesOptions {
    times: string[];
    repeatFixedCampaign: boolean;
}
