import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthorizationService } from "../services/authorization.service";
import { Router, ActivatedRoute } from "@angular/router";
import { UserService } from "../user/user.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {
  loading: boolean = false;
  loginError: boolean = false;
  errorCode: number | null = null;
  login: FormGroup = new FormGroup({
    loginName: new FormControl("", Validators.required),
    password: new FormControl("", Validators.required),
  });
  code: FormGroup = new FormGroup({
    otp: new FormControl("", Validators.required),
  });
  showCodeForm:boolean = false;

  constructor(
    private authorizationService: AuthorizationService,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit() {
    this.login.valueChanges.subscribe((value) => {
      this.updateDataModel(value);
    });
  }

  updateDataModel(value: any) {
    if( this.login.get("loginName")?.hasError('incorrect') ) {
      
      this.login.get("loginName")?.setErrors({'incorrect': false});
      this.login.get("loginName")?.updateValueAndValidity();
      this.loginError = false;
    }
  }

  get f() {
    return this.login.controls;
  }

  get f2() {
    return this.code.controls;
  }

  resetPassword() {
    this.router.navigate(["/login-recovery"]).then(() => {});
  }

  
  onSubmit(otp?: boolean ) {

    this.loading = true;

    if (!otp && this.login.invalid) {
      this.loginError = true;
      this.loading = false;
      return;
    }

    if(otp && this.code.invalid) {
      this.loginError = true;
      this.loading = false;
      return;
    }

    let login = {
      login: this.f['loginName'].value, 
      password: this.f['password'].value
    }

    if(otp) {
      login['otp'] = this.f2['otp'].value;
    }

    this.authorizationService
      .login(login)
      .subscribe({
        next: (response: any) => {
          if(response.accessToken) {
            this.userService.saveLocalUser(response);
            this.router.navigate(['/dashboard']);
          }
          switch(response.code) {
            case 200: 
              this.userService.saveLocalUser(response);
              this.router.navigate(['/dashboard']);
            break;
            case 202: 
              this.showCodeForm = true
            break;
          }
          
          this.loading = false;
        },
        error: (error: any) => {
          this.loading = false;
          //this.f['password'].setValue('');
          setTimeout(()=> {
            switch(error.error.code) {
              case 401: 
                if(!otp) this.login.get("loginName")?.setErrors({'incorrect': true});
                if(otp) this.code.get("otp")?.setErrors({'incorrect': true});
              break;
              case 403: 
                this.login.get("loginName")?.setErrors({'incorrect': true});
              break;
              case 404: 
                this.login.get("loginName")?.setErrors({'incorrect': true});
              break;
            }
            this.loginError = true;
            this.errorCode = error.error.code;
          },1)
         
        },
      });
  }
 
}
