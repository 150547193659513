<div class="dialog">
  <div mat-dialog-title >
    <h2 *ngIf="isNew" i18n>Vytvořit nový playlist</h2>
    <h2 *ngIf="!isNew" i18n>Úprava playlistu</h2>
    <button class="dialog__close" mat-icon-button mat-dialog-close i18n-aria-label aria-label="Zavřít dialog">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <div mat-dialog-content class="dialog__content" >
    <mat-form-field class="mat-mdc-form-field--fullwidth" >
      <mat-label i18n>Název playlistu</mat-label>
      <input  matInput i18n-placeholder placeholder="Název playlistu" required [disabled]="isDeleting" [formControl]="playlistForm">
    </mat-form-field>
  </div>
  <div mat-dialog-actions class="dialog__footer dialog__footer--reverse">
    <button mat-raised-button (click)="savePlaylist()" color="primary"><span i18n>Uložit</span><mat-icon color="white">check</mat-icon></button>
    <button mat-raised-button *ngIf="!isNew" (click)="deletePlaylist()" ><span i18n>Odstranit playlist</span><mat-icon color="white">delete</mat-icon></button>
  </div>
</div>