

<div class="login-screen login">
  <div class="login-screen__illustration" >
    <div class="login-screen__claim"><h1>IS media<br>Plánovací systém</h1></div>
  </div>
  <div class="login-screen__forms">
      <div class="login-screen__container" *ngIf="!loading; else loadingTemplate" (ngSubmit)="onSubmit()">
          <div class="login-screen__logo"></div>
            <div *ngIf="!showCodeForm">
              <h2 class="mb-5" i18n>Přihlášení</h2> 
              <form  [formGroup]="login">
                <mat-form-field  appearance="outline" class="login-screen__input mat-mdc-form-field--fullwidth mat-mdc-form-field--rounded ">
                  <mat-label i18n>Přihlašovací email</mat-label>
                  <input autocomplete="username" matInput type="email"  i18n-placeholder placeholder="Přihlašovací email" name="firstName" formControlName="loginName">
                  <mat-error   class="login-screen__error" *ngIf="loginError && (errorCode == 401 || errorCode == 404)" i18n>Přihlašovací údaje nejsou správné</mat-error>
                  <mat-error class="login-screen__error" *ngIf="loginError && errorCode == 403" i18n>Váš email není aktivovaný.</mat-error>
                </mat-form-field>

                <mat-form-field  appearance="outline" class="login-screen__input  mat-mdc-form-field--fullwidth mat-mdc-form-field--rounded">
                    <mat-label i18n>heslo</mat-label>
                    <input autocomplete="current-password" type="password" matInput i18n-placeholder placeholder="Heslo"  formControlName="password">
                    <mat-error class="login-screen__error" *ngIf="f['password'].hasError('require')" i18n>Zadejte vaše heslo.</mat-error>
                </mat-form-field>
                <div  class="login-screen__actions mt-5">
                  <button mat-raised-button (click)="onSubmit()"  color="primary" i18n>Přihlásit se</button>
                  <button class="mt-5" mat-button (click)="resetPassword()" i18n>Zapoměli jste heslo?</button>
               </div>
              </form >
            </div>
            <div *ngIf="showCodeForm">
              <form [formGroup]="code">
                <h2 >Zadejte kód</h2>
                <p class="mb-5">Otevřte vaši autentizační aplikaci a zadejte ověřovací kód.</p>
                <mat-form-field  appearance="outline" class="login-screen__input  mat-mdc-form-field--fullwidth">
                  <mat-label i18n>Kod z aplikace</mat-label>
                  <input autocomplete="current-password" type="text" matInput i18n-placeholder placeholder="Kod z aplikace"  formControlName="otp">
                  <mat-error class="login-screen__error" *ngIf="f2['otp'].hasError('require')" i18n>Zadejte váš kód.</mat-error>
                  <mat-error class="login-screen__error" *ngIf="f2['otp'].hasError('incorrect')" i18n>Špatně zadaný kód.</mat-error>

                </mat-form-field>
                <div  class="login-screen__actions mt-5">
                  <button (click)="onSubmit(true)" mat-raised-button color="primary" i18n>Odeslat kód</button>
               </div>
              </form>
            </div>
      </div>
  </div>
</div>

<ng-template #loadingTemplate>
    <app-loading></app-loading>
</ng-template>
    