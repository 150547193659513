import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmDialogComponent implements OnInit {
  message: string = $localize`Opravdu smazat`;
  action: string = $localize`Smazat`;
  question: string = null;
  header: string = null;
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      this.header = data.header ? data.header : this.header;
      this.message = data.message ? data.message : this.message;
      this.action = data.action ? data.action : this.action;
      this.question = data.question ? data.question : this.question;
    }
  }

  ngOnInit() {}

  confirm(isAccepted: boolean) {
    this.dialogRef.close(isAccepted);
  }
}
