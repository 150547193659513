<form class="campaign-detail dialog"  [formGroup]="campaignFormGroup">
      <div mat-dialog-title class="dialog__title">
            <h2><span i18n>Úprava kampaně</span> - <span *ngIf="!isLoadingResults">{{ campaign.name }}</span></h2>
            <app-campaign-state *ngIf="campaign" class="campaign-detail__state" [state]="campaign.state"></app-campaign-state>
            <button class="dialog__close" mat-icon-button mat-dialog-close i18n-aria-label aria-label="Zavřít dialog">
                <mat-icon>clear</mat-icon>
            </button>
      </div>
      <div mat-dialog-content  class="dialog__content" *ngIf="!isLoadingResults; else loading" >
            <mat-tab-group [(selectedIndex)]="selectedIndex" animationDuration="0" *ngIf="campaign; else errorTemplate" class="campaign-detail__tab"  (selectedTabChange)="tabChanged($event)">
                <mat-tab i18n-label label="Název"> 
                    <app-campaign-name *ngIf="selectedIndex === 0" [campaign]="campaign" (validated)="isCompleted($event, 0)"></app-campaign-name>
                </mat-tab>
                <mat-tab i18n-label label="Typ">
                    <app-campaign-type (validated)="isCompleted($event, 1)" *ngIf="selectedIndex === 1" [campaign]="campaign"></app-campaign-type>
                </mat-tab>
                <mat-tab i18n-label label="Playlist" [disabled]="campaign.type!=='playlist' || (campaign.type=='playlist' && !campaign.playlistId)"> 
                  <ng-template matTabContent>
                    <app-campaign-playlist (playlistChanged)="playlistChanged($event)" *ngIf="selectedIndex === 2"  [campaign]="campaign"></app-campaign-playlist>
                  </ng-template>
                </mat-tab>
                <mat-tab i18n-label label="Časové rozmezí"> 
                    <app-campaign-date *ngIf="selectedIndex === 3"   [campaign]="campaign" ></app-campaign-date>
                </mat-tab>
                <mat-tab i18n-label label="Videa a obsah"> 
                    <app-campaign-content *ngIf="selectedIndex === 4"  [campaign]="campaign" (validated)="isCompleted($event, 4)" ></app-campaign-content>
                </mat-tab>
                <mat-tab i18n-label label="Zařízení"> 
                    <app-campaign-devices *ngIf="selectedIndex === 5"  [campaign]="campaign" ></app-campaign-devices>
                </mat-tab>
                <mat-tab i18n-label label="Informace"  > 
                    <div class="campaign-detail__informations" *ngIf="selectedIndex === 6"> 
                            <div class="campaign-detail__informations__row" *ngIf="campaign.author"><h4 i18n><strong>Vytvořil:</strong></h4><span>{{ campaign.author }}</span></div>
                            <div class="campaign-detail__informations__row" *ngIf="campaign.created"><h4 i18n><strong>Datum vytvoření:</strong></h4> <span>{{ campaign.created | date:'medium'}}</span></div>
                            <div class="campaign-detail__informations__row" *ngIf="campaign.lastEditor"><h4 i18n><strong>Naposled upravil:</strong></h4><span>{{ campaign.lastEditor }}</span></div>
                            <div class="campaign-detail__informations__row"><h4 i18n><strong>Datum změny: </strong></h4> <span>{{ campaign.edited | date:'medium' }}</span></div>
                    </div>
                </mat-tab>
            </mat-tab-group>
            
      </div>
      <div class="campaign-detail__footer dialog__footer" mat-dialog-actions *ngIf="!hasError && !isLoadingResults">
            <div>
                  <button mat-raised-button [matMenuTriggerFor]="campaignMenu" ><span i18n>Možnosti</span> <mat-icon >build</mat-icon></button>
                  <mat-menu #campaignMenu="matMenu">
                        <button mat-menu-item  (click)="deleteCampaign()" ><mat-icon >delete</mat-icon><span i18n>Smazat kampaň</span></button>
                        <button  mat-menu-item (click)="finishCampaign()" ><mat-icon >assignment_turned_in</mat-icon><span i18n>Ukončit kampaň</span></button>
                        <button *ngIf="campaignFormGroup.get('isActive').value"  mat-menu-item (click)="toggleActiveCampaign()" i18n-matTooltip matTooltip="Deaktivací se kampaň přestane přehrávat ale zůstane naplánovaná" i18n-aria-label aria-label="Aktivovat/pozastavit kampaň" ><mat-icon >block</mat-icon><span i18n>Nastavit jako neaktivní</span></button>
                        <button *ngIf="!campaignFormGroup.get('isActive').value" mat-menu-item (click)="toggleActiveCampaign()" i18n-matTooltip matTooltip="Aktivuje kampaň a začne se přehrávat na přiřazených zařízeních" i18n-aria-label aria-label="Aktivovat/pozastavit kampaň" ><mat-icon >done</mat-icon><span i18n>Nastavit jako aktivní</span></button>
                  </mat-menu>
            </div>

            <div class="dialog__footer__group" [matTooltip]="validationMesasge">
                <!--mat-checkbox color="primary" class="campaigns__detail__active" formControlName="isActive" matTooltip="Aktivovat/pozastavit kampň"
                      aria-label="Aktivovat/pozastavit kampň" >Aktivní</mat-checkbox-->
                <button mat-raised-button color="primary"  [disabled]="saving || !isValid" (click)="saveCamaign()"><span i18n>Uložit</span><mat-icon matSuffix color="white">check</mat-icon></button>
                <button mat-raised-button color="primary"  [disabled]="saving || !isValid" (click)="duplicateCamaign()"><span i18n>Uložit jako nový</span><mat-icon matSuffix color="white">library_add</mat-icon></button>
          </div>
 
      </div>
</form>


<ng-template #loading>
      <app-loading class="mb-10"></app-loading>
</ng-template>

<ng-template #errorTemplate>
      <div class="campaign-detail__error" *ngIf="hasError">
            <h3 *ngIf="error.code ==  '404'" i18n>Nelze najít kampaň. Byla pravděpodobně odstraněna</h3>
      </div>
</ng-template>


