<div  class="layouts-list layouts-list--{{previewTemplate}}"  [ngClass]="{'layouts-list--selectable': selectable}" *ngIf="!isLoadingResults; else loading">
    <div class="layouts-list__container" *ngIf="layouts.length > 0; else noLayout">
        <mat-card (click)="setLayout(layout)"  class="layouts-list__item"  [ngClass]="{'is-active' : layout.id == selectedLayout.id, 'layouts-list--no-strips' : !layout.hasStrips }" *ngFor="let layout of (layouts | layoutPreviewFilter: filters )">
            <mat-card-title class="layouts-list__item__title">{{ layout.id }} {{ layout.name }}</mat-card-title>
            <mat-card-content>
                <div class="layouts-list__layout-preview" >
                    <app-layout-preview  [layout]="layout" [previewTemplate]="previewTemplate" ></app-layout-preview>
                </div>
            </mat-card-content>
            <button color="primary" class="layouts-list__item__ok mat-mdc-mini-fab--small" mat-mini-fab aria-label="Selected fixed type" *ngIf="layout.id == selectedLayout.id">
                <mat-icon>done</mat-icon>
            </button>
        </mat-card>
     
    </div>
</div>

<ng-template #noLayout>
    <div class="layouts-list__message">
        <h2 i18n>Nemáte přiřazený žádný layout</h2>
    </div>
</ng-template>


<ng-template #loading>
    <app-loading></app-loading>
</ng-template>

