import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Inject,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  AfterViewInit,
  SimpleChanges,
  OnChanges,
} from "@angular/core";
import { Layout, LayoutPreviewTemplate } from "src/app/models/layout.model";
import { LayoutRegion } from "src/app/models/layout-region.model";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { LayoutOptions } from "src/app/models/layout-options.model";
import { ActivatedRoute, Router } from "@angular/router";
import { B } from "@angular/cdk/keycodes";
import { resourceUsage } from "process";

@Component({
  selector: "app-layout-preview",
  templateUrl: "./layout-preview.component.html",
  styleUrls: ["./layout-preview.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class LayoutPreviewComponent implements AfterViewInit {
  @ViewChild("layoutPreview") layoutPreviewElement: ElementRef;
  @Input() layout: Layout;
  @Input() previewTemplate: LayoutPreviewTemplate = LayoutPreviewTemplate.Large;
  layouts: Layout[];
  layoutOptions: LayoutOptions;
  elementRatio;
  isLoadingPreviw = false;
  constructor(public dialog: MatDialog, private router: Router) {
    this.isLoadingPreviw = true;
  }

  ngAfterViewInit() {
    if (this.layout.width < this.layout.height) {
      this.elementRatio =
        this.layoutPreviewElement.nativeElement.offsetHeight /
        this.layout.height;
    } else {
      this.elementRatio =
        this.layoutPreviewElement.nativeElement.offsetWidth / this.layout.width;
    }
  }
  public calculateRatio() {
    if (this.layout.height > this.layout.width) {
      return (this.layout.width / this.layout.height) * 100;
    } else {
      return (this.layout.height / this.layout.width) * 100;
    }
  }
  public xToPercents(value: number) {
    return (value / this.layout.width) * 100;
  }
  public yToPercents(value: number) {
    return (
      (value / this.layout.width) *
      100 *
      (this.layout.width / this.layout.height)
    );
  }

  public calculateDimension(dimension: number) {
    return dimension * this.elementRatio;
  }

  openStripe(region: LayoutRegion) {
    this.router.navigateByUrl(
      "/layouts/strip/" + region.itemId + "?layoutId=" + this.layout.id
    );
  }
}
