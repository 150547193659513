import { Pipe, PipeTransform } from '@angular/core';
import { Tag } from '../models/tag.model';

@Pipe({
  name: 'tagsGroupFilter'
})
export class TagsFilterPipe implements PipeTransform {

  transform(tags: Tag[], group: string): any {
    if (tags) {
      return tags.filter(tag => tag.group === group);
    } else {
      return
    }
  }
}
