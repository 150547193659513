import {
  Component,
  OnInit,
  Output,
  OnDestroy,
  EventEmitter,
  Input,
} from "@angular/core";
import { ClipsService } from "../clips.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-get-clip-menu",
  templateUrl: "./get-clip-menu.component.html",
  styleUrls: ["./get-clip-menu.component.scss"],
})
export class GetClipMenuComponent implements OnInit, OnDestroy {
  clipsUploadedSubscription: Subscription;
  clipAddedSubscription: Subscription;
  @Input() hideLibrary? = false;
  @Input() openButton? = "button";
  @Input() selectedClips: number[] = [];
  @Output() uploaded = new EventEmitter();
  @Output() added = new EventEmitter();

  constructor(private clipsService: ClipsService) {}

  ngOnInit() {
    this.clipsUploadedSubscription =
      this.clipsService.newClipUploaded.subscribe((clip) => {
        this.uploaded.emit(clip);
        console.log("uploaded");
      });

    this.clipAddedSubscription = this.clipsService.checkedClips.subscribe(
      (clips) => {
        this.added.emit(clips);
        console.log("added");
      }
    );
  }

  ngOnDestroy() {
    this.clipsUploadedSubscription.unsubscribe();
    this.clipAddedSubscription.unsubscribe();
  }

  openUploadDialog(type?: string) {
    this.clipsService.openClipUploadDialog(type);
  }

  openSelectionDialog() {
    this.clipsService.openClipSelectionDialog(this.selectedClips);
  }
}
