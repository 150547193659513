import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  OnDestroy,
  ViewChild,
  SimpleChanges,
  OnChanges,
  ViewEncapsulation,
} from "@angular/core";
import { Playlist } from "src/app/models/playlist.model";
import { PlaylistService } from "../playlist.service";
import { catchError } from "rxjs/operators";
import { of as observableOf, Subscription } from "rxjs";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { PlaylistEditComponent } from "../playlist-edit/playlist-edit.component";
@Component({
  selector: "app-playlist-select",
  templateUrl: "./playlist-select.component.html",
  styleUrls: ["./playlist-select.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PlaylistSelectComponent implements OnInit, OnChanges {
  @Output() playlistIdChanged = new EventEmitter();
  @Input() playlistId? = null;
  @Input() playlist?: Playlist = null;
  playlists: Playlist[];
  slectedName: FormControl = new FormControl("");
  isLoadingResults = true;
  @ViewChild("playlistInput") input;

  constructor(
    private playlistService: PlaylistService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    if (!this.playlistId && !this.playlist) {
      this.playlistId = null;
    } else {
      this.playlistId = this.playlistId ? this.playlistId : this.playlist.id;
    }
    this.fetchPlaylists();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.playlist) {
      this.slectedName.setValue(changes.playlist.currentValue.name);
    }
  }

  fetchPlaylists() {
    this.isLoadingResults = true;
    this.playlistService
      .fetchPlaylists()
      .pipe(
        catchError(() => {
          this.isLoadingResults = false;
          console.log("Error with downloading Playlists");
          return observableOf([]);
        })
      )
      .subscribe((playlists: Playlist[]) => {
        this.isLoadingResults = false;
        this.playlists = playlists;

        if (this.playlistId) {
          const index = this.playlists.findIndex(
            (item) => item.id === this.playlistId
          );
          if (index >= 0) {
            this.slectedName.setValue(
              this.playlists.find((item) => item.id === this.playlistId).name
            );
          } else {
            this.playlistId = null;
            this.playlistIdChanged.emit(null);
          }
        }
      });
  }

  onSelectPlaylist(event) {
    let id = event.option.value;
    if (!id) {
      this.openPlaylistEdit();
    } else {
      this.playlistId = id;
      this.playlist = this.playlists.find(
        (item) => item.id === this.playlistId
      );
      this.slectedName.setValue(this.playlist.name);
      this.playlistIdChanged.emit(this.playlist);
    }
  }
  unselectInput() {
    setTimeout(() => {
      const target = this.input.nativeElement.ownerDocument
        .activeElement as HTMLElement;
      target.blur();
    }, 100);
  }
  openPlaylistEdit() {
    const dialogRef = this.dialog.open(PlaylistEditComponent, {
      disableClose: true,
      width: "900px",
      maxHeight: "100vh",
      data: { playlist: null },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.id) {
        this.playlist = result;
        this.slectedName.setValue(this.playlist.name);
        this.playlistIdChanged.emit(this.playlist);
      } else {
        this.fetchPlaylists();
        this.playlistId = null;
      }
    });
  }
}
