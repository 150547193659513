<div class="campaign-content">
    <div class="campaign-content__settings" >
        <form  [formGroup]="contentFormGroup">
            <mat-checkbox color="primary"  (change)="setFullscreenToAll($event.checked)" [indeterminate]="someFullscreen"  formControlName="fullscreen" i18n>Spouštět všechny klipy na celou obrazovku</mat-checkbox>
            <mat-checkbox color="primary" *ngIf="hasRadio" i18n-matTooltip matTooltip="Automaticky ztlumí rádio v případě aktivního videa se zvukem" formControlName="radioFade" i18n>Ztlumit rádio</mat-checkbox>
        </form>
    </div>
    <div class="campaign-content__body">
        <div class="campaign-content__list">
            <div class="campaign-content__left" >
                <div class="campaign-content__clip-list" *ngIf="campaign.videoclips" cdkDropList cdkDropListOrientation="horizontal"  (cdkDropListDropped)="drop($event)">
                    <div class="campaign-content__clip-item" *ngFor="let clip of campaign.videoclips; let i = index" cdkDrag [cdkDragDisabled]="clip.dragDisabled">
                        <div class="campaign-content__clip-item__order">
                            <button mat-icon-button i18n-matTooltip matTooltip="Přesunout na jinou pozici" cdkDragHandle><mat-icon>drag_indicator</mat-icon></button>
                            <span class="campaign-content__clip-index">{{ i+1 }}.</span>
                        </div>
                        <app-clip-item (clipChange)="updateClip($event)" (removeClip)="removeClip(i)" [type]="'editable'" [clip]="clip" ></app-clip-item>
                        <div class="campaign-content__placeholder" *cdkDragPlaceholder><div>&nbsp;</div></div>
                    </div>
                </div>
                <div class="campaign-content__new">
                    <app-get-clip-menu [selectedClips]="campaign.videoclips" [openButton]="'plus'" (uploaded)="addNewClips($event)" (added)="addCheckedClips($event)"  class="campaigns__content__clip-menu"></app-get-clip-menu>
                </div>
            </div>
        </div>
    </div>
</div>
