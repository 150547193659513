import { Component } from '@angular/core';
import { UserService } from '../user.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-two-factor',
  templateUrl: './two-factor.component.html',
  styleUrls: ['./two-factor.component.scss']
})
export class TwoFactorComponent {
  qrUrl: string;
  secret: string;

  authorization: FormGroup = new FormGroup({
    otp: new FormControl("", Validators.required),
    password: new FormControl("", Validators.required),
  });

  constructor(private userService: UserService,  public dialogRef: MatDialogRef<TwoFactorComponent>,
    ) {
    
  }
  ngOnInit() {
    this.enable2FA();
  }
  get f() {
    return this.authorization.controls;
  }

  enable2FA() {
    this.userService.init2FA().subscribe({
      next: (data) => {
        console.log(data);
        this.qrUrl = data.qrUrl;
        this.secret = data.secret;
      }, 
      error: (error) => {
        console.log(error);
      }
    })
  }
  complete2FA() {
    let data = {
      secret: this.secret,
      password: this.f['password'].value,
      otp: this.f['otp'].value
    }
    this.userService.complete2FA(data).subscribe({
      next: (data) => {
        console.log(data);
        this.dialogRef.close();
      }, 
      error: (error) => {
        console.log(error);
      }
    })
  }
}
