<div class="clips-multiple-actions" *ngIf="selection" >
    <form class="clips-multiple-actions__forms" [formGroup]="clipMultpileGroup">
        <div class="clips-multiple-actions__header">
            <h3 *ngIf="template === 'multiple-actions'" i18n>Hromadné akce</h3>
            <h3 *ngIf="template === 'upload-actions'" i18n>Nastavení pro všechny klipy</h3>
            <p class="clips-multiple-actions__count" i18n>(vybráno {{ selection.length }} klipů)</p>
        </div>
        <div  class="clips-multiple-actions__description">
            <mat-form-field class="mat-mdc-form-field--fullwidth" >
                <textarea  matInput i18n-placeholder placeholder="Popis klipů"  formControlName="commonDescription" ></textarea>
                <mat-hint i18n>Stávající popis se přepíše pro všechny vybrané klipy</mat-hint>
            </mat-form-field>
        </div>
        <app-tag-attach [type]="'clips'" [tags]="commonTags" [autocomplete]="true" (removed)="remove($event)" (attached)="attach($event)"></app-tag-attach>
        <div  *ngIf="template === 'multiple-actions'">
            <button (click)="moveToArchive()" mat-button>
                <mat-icon aria-hidden="false" i18n-aria-label aria-label="Přesunout do archivu">archive</mat-icon>
                <span *ngIf="selection[0].category == 'active'" i18n>Přesunout do archivu</span>
                <span  *ngIf="selection[0].category == 'archive'" i18n>Přesunout do aktivních</span>
            </button>
            <button mat-button disabled><mat-icon aria-hidden="false" i18n-aria-label aria-label="Naplánovat kampaň">date_range</mat-icon><span i18n>Naplánovat kampaň z vybraných</span></button>
        </div>
    </form>
    <div class="clips-multiple-actions__footer" *ngIf="template === 'multiple-actions'">
        <button color="primary" i18n-matTooltip matTooltip="Smazat vybrané"  mat-icon-button (click)="deleteSelected()"><mat-icon aria-hidden="false" aria-label="Smazat">delete</mat-icon></button>
        <button color="primary" mat-raised-button (click)="saveSelected()" i18n-aria-label aria-label="Uložit vybrané klipy"><mat-icon aria-hidden="false" >done</mat-icon><span i18n>Uložit vybrané klipy</span></button>
    </div>
</div>