<mat-chip-listbox 
  *ngIf="!state; else chips"
  i18n-aria-label  aria-label="Stavy kampaní"  
  class="campaign-state" 
  [multiple]="true"
  [selectable]="true"
  (change)="select($event)" >
    <mat-chip-option  [value]="state.value" [selected]="isSelected(state.value)" [ngClass]="'mat-chip--'+state.color" *ngFor="let state of statesList">{{ state.name }}</mat-chip-option>
</mat-chip-listbox>

<ng-template #chips>
    <mat-chip-listbox  (change)="select($event)"  class="campaign-state" selectable="false">
        <mat-chip [ngClass]="'mat-chip--'+activeStates[0].color" >{{ activeStates[0].name }}</mat-chip>
    </mat-chip-listbox>
</ng-template>
