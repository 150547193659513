import { LayoutRegion } from './layout-region.model';

export enum LayoutOrientation {
    PortraitLeft =  "PortraitLeft",
    PortraitRight  =  "PortraitRight",
    Landscape  =  "Landscape"
}

export enum LayoutPreviewTemplate {
    Small = "small",
    Large= "large"
}

export class LayoutPreviewFilters{
    orientation: LayoutOrientation;
}

export class Layout {
    id: number;
    width: 1920;
    height: 1080;
    orientation: LayoutOrientation;
    background: {
        color: string;
        image: string;
        repeat: string;
    };
    note: string;
    tags: string[];
    urlPreview: string;
    urlRender: string;
    regions: LayoutRegion[];
    hasStrips: boolean;
    activeStripId: number;
}


