import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Tag } from '../models/tag.model';
import { DevicesService } from './devices.service';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DevicesComponent implements OnInit {

  constructor(private devicesService: DevicesService) { }

  ngOnInit() {
  }

  selectedTags(tags: Tag[]) {
    this.devicesService.filterDevicesByTags(tags);
  }
}
