import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Layout, LayoutOrientation, LayoutPreviewFilters, LayoutPreviewTemplate } from 'src/app/models/layout.model';
import { Strips } from 'src/app/models/strip.model';
import { StripsService } from 'src/app/strips/strips.service';
import { CustomUtils } from 'src/app/utils/custom-utils.component';
import { LayoutsService } from '../layouts.service';

@Component({
    selector: 'app-layouts-list',
    templateUrl: './layouts-list.component.html',
    styleUrls: ['./layouts-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LayoutsListComponent implements OnInit, OnChanges {
    @Input() previewTemplate? : LayoutPreviewTemplate = LayoutPreviewTemplate.Large;
    @Input() selectable? :boolean = false;
    @Input()  filters?: LayoutPreviewFilters;
    @Output() selected = new EventEmitter();
    
    layouts: Layout[];
    previewHeight = 433;
    previewWidth = 769;
    scaleIndex = 0.4;
    strips: Strips;
    stripId: number;
    isLoadingResults: boolean = false;
    selectedLayout: Layout = new Layout();

    constructor(private layoutsService: LayoutsService) {
    }
    ngOnChanges(simpleChanges: SimpleChanges) {
        if(simpleChanges.filters) {
            this.filters = simpleChanges.filters.currentValue;
        }
    }
    ngOnInit() {
        this.fetchLayout();
        if (this.previewTemplate == "small" ) {
            this.previewHeight = 200;
        } else {
            this.previewHeight = 433;
            this.previewWidth = 769;
            this.scaleIndex = 0.4;
        }
        
    }

    fetchLayout() {
        this.isLoadingResults = true;
        this.layoutsService.fetchLayoutsByCompany().subscribe((data: any) => {
            this.layouts = data;
            this.layouts.forEach(layout => {
                layout.hasStrips = this.checkIfCntainsStrips(layout);
                if (!layout.orientation) {
                    layout.orientation = this.addOrientation(layout.width, layout.height);
                }
            });
            this.isLoadingResults = false;
        });
    }



    checkIfCntainsStrips( layout ): boolean {
        let hasStrip = false;
        if (layout.regions.some(region => region.type == 'strip')) {
            hasStrip = true
        }
        return hasStrip;
    }

    getStrip(id, layout) {
        layout.activeStripId = id;
    }
    setLayoutOptions(layout: Layout) {
        let region = layout.regions.find(item => item.itemId === layout.activeStripId);
        var layoutOptions = {
            background: layout.background,
            top: region.top,
            left: region.left
        };
        return layoutOptions;
    }

    addOrientation(w: number, h: number):LayoutOrientation {
        if (w > h) {
            return LayoutOrientation.Landscape;
        } else {
            return LayoutOrientation.PortraitLeft;
        }
    }
    setLayout(layout: Layout) {
        if(this.selectable) {
            this.selectedLayout = layout;
            this.selected.emit(this.selectedLayout);
        }
    }
}
