import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatAutocompleteSelectedEvent, MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';

import { Tag } from 'src/app/models/tag.model';
import { TagsService } from '../tags.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-tag-attach',
  templateUrl: './tag-attach.component.html',
  styleUrls: ['./tag-attach.component.scss']
})
export class TagAttachComponent implements OnInit {
  @Input() tags: Tag[] = [];
  @Input() type: string;
  @Input() group = 'client';
  @Input() autocomplete? = false;
  @Output() attached = new EventEmitter();
  @Output() removed = new EventEmitter();

  visible = true;
  removable = true;
  selectable = true;
  addOnBlur = true;
  isAdminTags: boolean;

  allTags: Tag[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagsControl = new FormControl();
  filteredTags: Observable<Tag[]>;

  @ViewChild('tagsInput', {static: false}) tagsInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger) matAutocompleteTrigger: MatAutocompleteTrigger;

  constructor( private tagsService: TagsService,  public dialog: MatDialog) {
    this.filteredTags = this.tagsControl.valueChanges.pipe(
      startWith(null),
      map((tagValue: string | null ) => this._filter(tagValue) )
    );
  }

  ngOnInit() {
    this.isAdminTags = this.group === 'client' ? false : true;
    this.tags = (typeof this.tags === 'undefined') ? [] : this.tags;
    this.fetchAllTags();
  }

  fetchAllTags() {
    this.tagsService.fetchTagsByCompany(this.type).pipe(
      map((data) => {
        return data.filter(tag => tag.group === this.group);
      })
    ).subscribe((tags: Tag[]) => {
        this.allTags = tags;
        this.tagsControl.setValue(null);
    }), (error: any) => {
        console.log(error);
    };
  }

  add(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      // Add our tag
      if ((value || '').trim()) {

        const newTag = new Tag();
        newTag.value =  value.trim();
        newTag.type = this.type;
        newTag.group = this.group;

        if (!this.tags.some( item => item.value === newTag.value)) {
            if (!this.allTags.some( item => item.value === newTag.value)) { // if tag doesnt exist create and upload new one
              this.tagsService.addNewTag(newTag).subscribe((messag) => {
                this.tags.push( newTag );
                this.attached.emit(newTag);
              }, error => {
                if (error.status === 400 ) {
                  this.tags.push( newTag );
                  this.attached.emit(newTag);
                }
              });
            } else {
              this.tags.push( newTag );
              this.attached.emit(newTag);
            }
        }
      }

      // Reset the input value
      if (input) {
        input.value = '';
      }

      this.tagsControl.setValue(null);
    }
  }

  remove(tag: Tag): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
      this.removed.emit(tag);
    }
  }

  selectedAutocomplete(event: MatAutocompleteSelectedEvent): void {

    const newTag = new Tag();
    newTag.value =  event.option.viewValue;
    newTag.type = this.type;
    newTag.group = this.group;

    this.tags.push(newTag);
    this.tagsControl.setValue(null);
    this.attached.emit(newTag);
  }

    private _filter(value: string) {
        const allTags = this._filterAttached();
        if (value) {
            const filterValue = value.toLowerCase();
        return allTags.filter(tag => tag.value.toLowerCase().indexOf(filterValue) === 0);
        } else {
            return allTags;
        }
    }
    private _filterAttached() {
        return this.allTags.filter((tag) => {
            return !this.tags.some(item => item.value === tag.value);
        });
    }
}
