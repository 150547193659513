import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  ɵConsole,
  Inject,
  ViewEncapsulation,
} from "@angular/core";
import { ScheduleService } from "./schedule.service";
import { Campaign } from "../models/campaign.model";

import { Schedule } from "../models/schedule.model";
import { ContentClip } from "../models/content-clip.model";
import { Subscription } from "rxjs";
import { FormControl } from "@angular/forms";
import * as dayjs from "dayjs";
import * as customParseFormat from "dayjs/plugin/customParseFormat";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DevicePreview } from "../models/device-preview.model";

dayjs.extend(customParseFormat);

@Component({
  selector: "app-schedule",
  templateUrl: "./schedule.component.html",
  styleUrls: ["./schedule.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ScheduleComponent implements OnInit, OnDestroy, AfterViewInit {
  schedule: Schedule;
  isLoadingResults = false;
  selectedIndex = 0;

  repeatedCampaigns: Campaign[];
  fixedCampaigns: Campaign[];

  camapignsLoops: number[];
  deviceTime: any;
  device: DevicePreview;

  activeLink = "timeline";

  // preview
  activeCampaign: Campaign = null;
  activeClip = null;
  preview: any;
  firstRun: boolean;
  toggleCampaignPreviewSub: Subscription;
  error: any = null;
  day = new FormControl(new Date().toISOString(), { updateOn: "blur" });

  constructor(
    private scheduleService: ScheduleService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.firstRun = true;
    this.preview = {
      x: 0,
      y: 0,
    };

    this.deviceTime = {
      startTime: 0,
      endTime: 0,
      startSecond: 0,
      endSecond: 0,
      workingMinutes: 0,
      workingSeconds: 0,
      workingHours: 0,
      workingTime: 0,
      loopSeconds: 0,
    };
    this.device = data.device;
  }
  ngAfterViewInit() {
    this.activeCampaign = null;
  }

  ngOnInit() {
    this.activeCampaign = null;

    this.toggleCampaignPreviewSub =
      this.scheduleService.toggleCampaignPreview.subscribe((data: any) => {
        if (data) {
          this.openPreview(data);
        } else {
          this.closePreview();
        }
      });

    this.scheduleService.updateScheduleData.subscribe(() => {
      this.fetchScheduleData();
    });

    this.day.valueChanges.subscribe(() => {
      this.fetchScheduleData();
    });

    this.fetchScheduleData();
  }

  ngOnDestroy() {
    this.toggleCampaignPreviewSub.unsubscribe();
  }

  templateChange(index) {
    this.selectedIndex = index;
  }

  fetchScheduleData() {
    this.isLoadingResults = true;
    const isoDay = dayjs(this.day.value).format("YYYY-MM-DD");
    this.scheduleService.fetchScheduleData(this.device.id, isoDay).subscribe(
      (data) => {
        if (data) {
          this.schedule = data;
          this.repeatedCampaigns = data.campaigns;
          this.fixedCampaigns = data.fixed;
          this.setDeviceWorkingTime();
          //this.calculatePlaylist();
          this.error = null;
          this.isLoadingResults = false;
        }
      },
      (error) => (this.error = error) // error path
    );
  }

  calculatePlaylist() {}

  timeToSeconds(time: string) {
    const timeArray = time.split(":").map(Number);
    const seconds: number = timeArray[0] * 3600 + timeArray[1] * 60;
    return seconds;
  }

  secondsToTime(seconds: number) {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    return `${h.toString().padStart(2, "0")}:${m
      .toString()
      .padStart(2, "0")}:00`;
  }

  fixNullDuration(clip: ContentClip) {
    if (clip.clipType === "image" && clip.duration === 0) {
      return 10;
    } else if (clip.duration === 0) {
      return 10;
    } else {
      return clip.duration;
    }
  }

  openPreview(clickedCampaign: any) {
    this.activeCampaign = null;
    setTimeout(() => {
      if (clickedCampaign) {
        if (clickedCampaign.clip) {
          this.activeClip = clickedCampaign.clip;
        } else if (clickedCampaign.clipIndex) {
          this.activeClip =
            clickedCampaign.campaign.videoclips[clickedCampaign.clipIndex];
        } else {
          this.activeClip = clickedCampaign.campaign.videoclips[0];
        }

        this.activeCampaign = this.findCampaignById(
          clickedCampaign.campaign.id
        ); // clickedCampaign.campaign;

        let leftOffset = clickedCampaign.event.clientX || 0; // event.target.offsetLeft -  event.target.offsetParent.offsetLeft ;
        const topOffset = clickedCampaign.event.clientY - 370 || 0; // event.target.offsetTop  -  event.target.offsetParent.offsetTop;

        const previewWidth = 556;
        const previewheight = 370;

        if (window.innerWidth < leftOffset + previewWidth) {
          leftOffset = window.innerWidth - previewWidth;
        }

        this.preview = {
          x: leftOffset,
          y: topOffset,
          width: previewWidth,
          height: previewheight,
        };
      }
    }, 100);
  }

  closePreview() {
    this.activeCampaign = null;
  }

  setDeviceWorkingTime() {
    // reduce device working time
    this.schedule.startTime =
      this.schedule.startTime === null ? "00:00:00" : this.schedule.startTime;
    this.schedule.endTime =
      this.schedule.endTime === null ? "24:00:00" : this.schedule.endTime;

    this.deviceTime.startTime = this.schedule.startTime;
    const statTimes = this.schedule.startTime.split(":").map(Number);

    this.deviceTime.endTime = this.schedule.endTime;
    const endTimes = this.schedule.endTime.split(":").map(Number);

    this.deviceTime.startSecond =
      statTimes[0] * 3600 + statTimes[1] * 60 + statTimes[2];
    this.deviceTime.endSecond =
      endTimes[0] * 3600 + endTimes[1] * 60 + endTimes[2];

    this.deviceTime.workingSeconds =
      this.deviceTime.endSecond - this.deviceTime.startSecond;
    this.deviceTime.workingMinutes = Math.floor(
      this.deviceTime.workingSeconds / 60 +
        (this.deviceTime.workingSeconds % 60)
    );
    this.deviceTime.workingHours = Math.floor(
      this.deviceTime.workingSeconds / 3600
    );

    this.deviceTime.workingTime = `${Math.floor(
      this.deviceTime.workingSeconds / 3600
    )
      .toString()
      .padStart(2, "0")}:${Math.floor(
      (this.deviceTime.workingSeconds % 3600) / 60
    )
      .toString()
      .padStart(2, "0")}:${Math.floor(this.deviceTime.workingSeconds % 60)
      .toString()
      .padStart(2, "0")}`;
    this.deviceTime.forDay = this.day.value;
  }

  private findCampaignById(id: number) {
    let campaign = this.repeatedCampaigns.find((campaign) => campaign.id == id);

    if (!campaign) {
      campaign = this.fixedCampaigns.find((campaign) => campaign.id == id);
    }

    return campaign;
  }
}
