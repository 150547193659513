import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  SimpleChanges,
  OnChanges,
  ViewEncapsulation,
} from "@angular/core";
import { ContentClip } from "src/app/models/content-clip.model";
import { Tag } from "src/app/models/tag.model";
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import { ClipsService } from "../clips.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ConfirmDialogComponent } from "src/app/utils/confirm-dialog/confirm-dialog.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-clips-multiple-actions",
  templateUrl: "./clips-multiple-actions.component.html",
  styleUrls: ["./clips-multiple-actions.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ClipsMultipleActionsComponent implements OnInit, OnChanges {
  @Input() selection: ContentClip[];
  @Input() template = "multiple-actions";
  @Output() changed = new EventEmitter();

  allTags: Tag[] = [];
  commonTags: Tag[] = [];
  commonDescription: string;
  clipMultpileGroup: FormGroup;

  constructor(
    public dialog: MatDialog,
    private clipsService: ClipsService,
    private snackBar: MatSnackBar,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.clipMultpileGroup = this.fb.group({
      commonDescription: new FormControl(this.commonDescription),
    });

    this.clipMultpileGroup
      .get("commonDescription")
      .valueChanges.subscribe((value) => {
        this.selection.forEach((audioclip) => {
          audioclip.description = value;
        });
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selection) {
      this.selection = changes.selection.currentValue;
      this.checkCommonTags();
    }
  }

  checkCommonTags() {
    // create all tags array
    let foundTags: Tag[] = [];
    this.commonTags = [];

    this.selection.forEach((clip, index) => {
      const filteredDuplicity = clip.tags.filter((item) => {
        return !foundTags.some((testItem) => {
          return testItem.value == item.value;
        });
      });
      this.commonTags = foundTags.concat(filteredDuplicity);
    });

    this.selection.forEach((clip, index) => {
      this.commonTags = this.commonTags.filter((foundTag: Tag) => {
        return clip.tags.some((tag) => tag.value === foundTag.value);
      });
    });
  }

  moveToArchive() {
    this.selection.forEach((clip) => {
      if (clip.category === "archive") {
        clip.category = "active";
      } else {
        clip.category = "archive";
      }
    });
    this.saveSelected();
    this.changed.emit(false);
  }
  async deleteSelected() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "300px",
      panelClass: "",
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.selection
          .reduce((p, clip, index: number) => {
            return p.then(() => {
              return new Promise((resolve, reject) => {
                if (clip.isTranscoding) {
                  let message = $localize`nelze smazat klip, který se zpracovává`;
                  this.snackBar.open(
                    index + 1 + "/" + this.selection.length + " " + message,
                    "",
                    {
                      duration: 3000,
                    }
                  );
                  reject();
                } else {
                  this.clipsService.deleteClip(clip.id).subscribe(
                    () => {
                      let message = $localize`odstraněno`;
                      this.snackBar.open(
                        index + 1 + "/" + this.selection.length + " " + message,
                        "",
                        {
                          duration: 3000,
                        }
                      );
                      resolve();
                    },
                    (error) => {
                      let message = $localize`se nepodařilo smazat`;
                      this.snackBar.open(
                        index + 1 + "/" + this.selection.length + " " + message,
                        "",
                        {
                          duration: 3000,
                        }
                      );
                      console.log(error);
                      resolve();
                    }
                  );
                }
              });
            });
          }, Promise.resolve())
          .then(() => {
            this.changed.emit(false);
            this.clipsService.updateClipsList();
          });
      }
    });
  }

  async saveSelected() {
    this.selection
      .reduce((p, clip, index: number) => {
        return p.then(() => {
          return new Promise((resolve, reject) => {
            this.clipsService.updateClip(clip).subscribe(
              (result) => {
                let message = $localize`uloženo`;
                this.snackBar.open(
                  index + 1 + "/" + this.selection.length + " " + message,
                  "",
                  {
                    duration: 3000,
                  }
                );
                resolve();
              },
              (error) => {
                let message = $localize`se nepodařilo uložit`;
                this.snackBar.open(
                  index + 1 + "/" + this.selection.length + " " + message,
                  "",
                  {
                    duration: 3000,
                  }
                );
                resolve();
              }
            );
          });
        });
      }, Promise.resolve())
      .then(() => {
        this.changed.emit(this.selection);
      });
  }

  remove(removedTag: Tag) {
    this.selection.forEach((clip) => {
      clip.tags = clip.tags.filter((item) => item !== removedTag);
    });
  }

  attach(tag: Tag) {
    this.selection.forEach((clip) => {
      const filteredDuplicity = this.commonTags.filter((item) => {
        return !clip.tags.some((testItem) => {
          return testItem.value == item.value;
        });
      });
      clip.tags = clip.tags.concat(filteredDuplicity);
    });
  }
}
