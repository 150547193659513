import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { DevicePreview } from 'src/app/models/device-preview.model';
import { DevicesService } from '../devices.service';
import { ScheduleService } from 'src/app/schedule/schedule.service';

@Component({
  selector: 'app-device-tile',
  templateUrl: './device-tile.component.html',
  styleUrls: ['./device-tile.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DeviceTileComponent implements OnInit {
  htmlPlayerUrl;
  @Input() device: DevicePreview;
  constructor(private devicesService: DevicesService, private scheduleService: ScheduleService) { }

  ngOnInit() {
  }

  addPlaceholder(error: any) {
    this.device.preview.small.url = 'assets/img/placeholders/device-offline.png';
  }
  
  openDetail() {
    this.devicesService.openDevicedetail(this.device.id);
  }

  openTimeline() {
    this.device.isFocused = true;
    console.log("open");
    this.scheduleService.openSchedule(this.device);
  }
  openPreview() {
    this.devicesService.openPreview(this.device);
  }
}
