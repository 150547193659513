import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { AuthorizationService } from "../services/authorization.service";
import { environment } from "src/environments/environment";
import { Company } from "../models/company.model";
import { UserService } from "../user/user.service";
import { CompanyStats } from "../models/company-stats.model";
import { retry } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CompanyService {
  serverUrl: string;
  headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private authorizationService: AuthorizationService,
    private userService: UserService
  ) {
    this.serverUrl = `${environment.serverProtocol}://${environment.serverAddress}:${environment.serverPort}`;
    this.headers = new HttpHeaders().set(
      "Authorization",
      "Bearer " + this.authorizationService.accessTokenValue
    );
    this.headers = this.headers.set(
      "x-company-id",
      this.userService.activeCompanyId + ""
    );
  }

  fetchAllCompanies() {
    const url = `${this.serverUrl}/api2/companies`;
    return this.http
      .get<Company[]>(url, { headers: this.headers })
      .pipe(retry(3));
  }

  fetchCompanyStatistics() {
    const url = `${this.serverUrl}/api2/company/stats`;
    return this.http
      .get<CompanyStats>(url, { headers: this.headers })
      .pipe(retry(3));
  }

  get logo(): string | null {
    if (this.userService.activeCompany) {
      return this.userService.activeCompany.logo;
    } else {
      return null;
    }
  }

  get companyId(): number | null {
    return this.userService.activeCompanyId;
  }

  get companyName(): string | null {
    return this.userService.activeCompany.company;
  }

  public checkModule(name: string): boolean {
    let result = this.userService.activeCompany?.modules[
      name
    ]
      ? this.userService.activeCompany.modules[name]
      : false;
    return result;
  }
}
