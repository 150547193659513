import { Injectable} from '@angular/core';
import { Device } from '../models/device.model';
import { DevicePreview } from '../models/device-preview.model';

import { MatDialog} from '@angular/material/dialog';

import { Tag, TagEvent } from '../models/tag.model';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http'
import { environment } from '../../environments/environment';
import { AuthorizationService } from '../services/authorization.service';

import { BehaviorSubject, Subject} from 'rxjs';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class DevicesService {

  public devicesSubject: BehaviorSubject<DevicePreview[]> = new BehaviorSubject([]);
  public devicesSelectedTagsSubject: BehaviorSubject<Tag[]> = new BehaviorSubject([]);

  public selectDevices: BehaviorSubject<DevicePreview[]> = new BehaviorSubject([]);
  public getSelectedDevicesIds: BehaviorSubject<number[]> = new BehaviorSubject([]);
  public getSelectedDevices: BehaviorSubject<DevicePreview[]> = new BehaviorSubject([]);

  public updateDevicesList = new Subject<any>();
  public filterTags = new Subject<Tag[]>();
  public checkedTags = new Subject<TagEvent>();

  public deviceDetailDialog = new Subject<number>();
  public deviceAuthorizationDialog = new Subject<any>();

  private headers: HttpHeaders;
  private serverUrl: string;

  private devicesList :  DevicePreview[];

  constructor(private userService: UserService, private http: HttpClient, private authorizationService: AuthorizationService,  public dialog: MatDialog) {
    this.devicesList =  [];
    this.serverUrl = `${environment.serverProtocol}://${environment.serverAddress}:${environment.serverPort}`;
    this.headers = new HttpHeaders();
    this.headers = this.headers.set('Authorization', 'Bearer ' +  this.authorizationService.accessTokenValue);
    this.headers = this.headers.set('x-company-id', this.userService.activeCompanyId + '');
    this.headers = this.headers.set('Content-Type', 'application/json');
  }

  fetchUserDevices(ids?: number[]) {
    const idsParam = (typeof ids === 'undefined' || ids.length === 0)  ? '' : `?ids=${ ids.toString() }`;
    const url = `${this.serverUrl}/api2/devices${idsParam}`;
    return this.http.get<DevicePreview[]>(url, { headers: this.headers });
  }

  fetchDevicesByTag(tag: Tag) {
    const tagParam = `?tag=${ tag.value }&group=${ tag.group }`;
    const url = `${this.serverUrl}/api2/devices${tagParam}`;
    return this.http.get<DevicePreview[]>(url, { headers: this.headers });
  }

  fetchDeviceById(id: number) {
    const url = `${this.serverUrl}/api2/device/${id}`;
    return this.http.get<Device>(url, { headers: this.headers });
  }

  saveDevice(device: Device) {
    const url = `${this.serverUrl}/api2/device`;
    return this.http.put<Tag[]>(url, device, { headers: this.headers });
  }
  removeDevice(id: number) {
    const url = `${this.serverUrl}/api2/device/${id}`;
    return this.http.delete<Tag[]>(url, { headers: this.headers });
  }
  attachTag(tag: Tag, devices: number[]) {
    const url = `${this.serverUrl}/api2/devices/attach-tag?ids=${devices.join()}`;
    return this.http.put<Tag[]>(url, {tag}, { headers: this.headers });
  }

  deviceApprove(deviceData: any) {
    const url = `${this.serverUrl}/api2/device/tv-approve`;
    return this.http.post<Tag[]>(url, deviceData, { headers: this.headers });
  }

  filterDevicesByTags(tags: Tag[]) {
    this.filterTags.next(tags);
  }

  checkDeviceByTags(tagsEvent: TagEvent) {
    this.checkedTags.next(tagsEvent);
  }

  selectDeviceByIds(ids: number[]) {
    //this.checkedTags.next(ids);
  }

  //resetTagFilter() {
    //this.devices.next(this.dataStore.devices);
  //}

  get devices() {
    return this.devicesList;
  }

  set devices(devices: DevicePreview[]) {
    this.devicesList = devices;
    this.devicesSubject.next(this.devicesList);
  }

  openDevicedetail(deviceId: number) {
    this.deviceDetailDialog.next(deviceId);
  }
  
  openDeviceAuthorization() {
    this.deviceAuthorizationDialog.next(true);
  }

  public openPreview(device) {
    let htmlPlayerUrl = `${environment.serverProtocol}://${environment.serverAddress}/htmlpreview?deviceid=${device.id}&code=${encodeURIComponent(device.htmlPlayerCode)}`;
    window.open(htmlPlayerUrl, "_blank");
  }

  public updateDevices() {
    this.updateDevicesList.next(true);
  }
  private handleError(error: any) {
    console.log(error)
  }
}
