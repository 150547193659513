import { Component, OnInit, Input, EventEmitter, Output, ViewChild, TemplateRef, SimpleChanges, OnChanges, ChangeDetectorRef, ChangeDetectionStrategy, ViewEncapsulation  } from '@angular/core';
import { ContentClip } from '../../models/content-clip.model';
import { ClipsService } from '../clips.service';
import {FormControl, Validators, FormGroup, FormBuilder} from '@angular/forms';
import { throwToolbarMixedModesError } from '@angular/material/toolbar';
import { T, V } from '@angular/cdk/keycodes';
import { CustomUtils } from 'src/app/utils/custom-utils.component';

@Component({
selector: 'app-clip-item',
templateUrl: './clip-item.component.html',
styleUrls: ['./clip-item.component.scss'],
encapsulation: ViewEncapsulation.None
})
export class ClipItemComponent implements OnInit, OnChanges  {
    isPlaying: boolean;
    editActive = false;
    showVideo = false;
    startPreload = false;
    transcodingInterval: any;
    previewTimeout: any;
    clipFormGroup: FormGroup;
    volumeVisible:boolean;

    @Input() clip: ContentClip;
    @Input() externalPreview?: boolean;
    @Input() controls = false;
    @Input() type ? = 'checkable'; // checkable | editable | multiple-actions | preview
    @Input() isChecked = false;
    @Input() multipleActions = false;
    @Input() selectedIndex ? = null;
    //@Input() fullscreen ? = false;

    @Output() public clipChange = new EventEmitter();
    @Output() public playClip = new EventEmitter();
    @Output() public selected = new EventEmitter();
    @Output() public selectedClone = new EventEmitter();
    @Output() public removeClip = new EventEmitter();

    @ViewChild('noPreview', { static: false }) noPreview: TemplateRef<any>;

    constructor(private clipsService: ClipsService, private fb: FormBuilder, cd: ChangeDetectorRef) {
        this.isPlaying = false;
        this.clipFormGroup = this.fb.group({
            _clipOptions: new FormControl(),
            duration: new FormControl(10, Validators.required),
            fullscreen: new FormControl(false),
            mute: new FormControl(false),
            volume: new FormControl(100),
        });
        this.clipFormGroup.valueChanges.subscribe((value) => { this.updateDataModel(value); });
    }

    ngOnInit() {
        if (this.clip.isTranscoding) {
        this.getTranscodingStatus();
        } else {
            if (this.transcodingInterval) {
                clearInterval(this.transcodingInterval);
            }
        }
    }

    ngOnChanges(change: SimpleChanges): void {
        if (change.type) {
            this.type = change.type.currentValue;
        }
        if (change.multipleActions) {
            this.multipleActions = change.multipleActions.currentValue;
        }

        if (change.clip) {
            change.clip.currentValue.volume =  this.percentsToDB(change.clip.currentValue.volume);
            this.clipFormGroup.patchValue(change.clip.currentValue, {emitEvent: false});
            this.updateOptions();
        }
    }
    get formatLabel() {
        return this.percentsToDB(this.clip.volume);
    }
    getTranscodingStatus() {
        this.transcodingInterval = setInterval(() => {
            this.getClipData();
        }, 20000);
    }
    getClipData() {
        this.clipsService.fetchClipById(this.clip.id).subscribe((data: ContentClip) => {
            this.clip = data;
            if (!this.clip.isTranscoding) {
                clearInterval(this.transcodingInterval);
            }
        })
    }
    toggleVideoPreview(state) {
        if(state) {
            if (this.clip.clipType === 'youtube' || this.clip.clipType === 'video') {
                this.previewTimeout = setTimeout(() => {
                this.startPreload = true;
                }, 400);
            }
        } else {
            this.showVideo = false;
            this.startPreload = false;
            if (this.previewTimeout) {
                clearInterval(this.previewTimeout);
            }
        }
    }

    playVideo() {
        if (!this.externalPreview) {
            if (this.isPlaying) {
                this.isPlaying = false;
            } else {
                this.isPlaying = true;
            }
        } else {
            this.playClip.emit(true);
        }
    }
    get isVideo() {
        if ( this.clip.clipType === 'video' || this.clip.clipType === 'youtube') {
        return true;
        }
        return false;
    }
    muteVideo() {
        this.clipFormGroup.get('mute').setValue(!this.clipFormGroup.get('mute').value)
    }

    addPlaceholder(error) {
        switch(this.clip.clipType) {
        case "live" :
            this.clip.preview.small.url = '/assets/img/placeholders/livecam-placeholder.png';
        break
        case "image" :
            this.clip.preview.small.url = '/assets/img/placeholders/image-placeholder.png';
        break
        case "html" :
            this.clip.preview.small.url = '/assets/img/placeholders/html-placeholder.png';
        break
        default :
            this.clip.preview.small.url = '/assets/img/placeholders/livecam-placeholder.png';
        }
    }

    editClip() {
        this.editActive = !this.editActive;
        this.clip.dragDisabled = !this.editActive;
    }
    closeEdit() {
        this.editActive = false;
        this.clip.dragDisabled = false;
    }
    openClipDetail() {
        this.clipsService.openClipDetailDialog(this.clip);
    }
    select(event: any) {
        this.isChecked = !this.isChecked;
        this.selected.emit(this.clip);
    }
    selectClone() {
        this.selectedClone.emit(this.clip);
    }
    remove() {
        this.isChecked = !this.isChecked;
        this.removeClip.emit();
    }

    updateDataModel(value: any) {
        value.fullscreen = this.updateFullscreen(value._clipOptions);
        value.volume = this.dBtoPercents(value.volume);
        this.clip = Object.assign(this.clip, value);
        this.clipChange.emit(this.clip);
    }

    updateFullscreen(options):boolean {
        if( options.some(action => action == "fullscreen") ) {
            this.clipFormGroup.get('fullscreen').setValue( true  , {emitEvent: false} );
            return true;

        } else {
            this.clipFormGroup.get('fullscreen').setValue( false , {emitEvent: false} );
            return false;
        }
    }
  
    updateOptions() {
        let array = [];
        if(  this.clipFormGroup.get('fullscreen').value ) {
            array.push("fullscreen");
        }
        this.clipFormGroup.get('_clipOptions').setValue(array);
    }

    dBtoPercents(volume) {
        let percentage = Math.pow(10, volume*2 / 20);
        return  Math.round(percentage * 100);
    }
    percentsToDB(perc) {
        let db = 20 * (Math.log(perc * 0.01) / Math.log(10));
        return Math.round(db/2);
    }
    
}
