
<div class="top-bar">
    <div>
        <!--span *ngIf="companyId" >{{ user.company.company }} - Plánovací systém</span-->
        <h1>{{   getTitle() }}</h1>
    </div>
    <div class="top-bar__user-menu">
        <span> {{ user.name }} </span>
        <button  mat-icon-button [matMenuTriggerFor]="profileMenu"  class="top-bar__avatar" i18n-matTooltip  [matTooltip]="'Nastavení'">
            <svg
                id="svg8"
                version="1.1"
                viewBox="0 0 67.733332 67.733335"
                height="256"
                width="256"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:svg="http://www.w3.org/2000/svg"
                xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
                xmlns:cc="http://creativecommons.org/ns#"
                xmlns:dc="http://purl.org/dc/elements/1.1/">
                <defs
                    id="defs2" />
                <g
                    transform="translate(0,-229.26666)"
                    id="layer1">
                    <path
                    id="path4678"
                    style="color:#000000;stroke-width:1.06909;-inkscape-stroke:none;fill-opacity:1"
                    d="m 56.968964,291.36521 c 2.349363,-0.656 2.401811,-1.64014 3.228079,-2.51373 -1.95814,-2.5128 -10.375356,-4.98527 -12.185776,-5.73594 -1.810417,-0.75069 -3.035388,-1.37411 -4.086616,-4.33302 -0.248148,-0.69846 -0.19706,-0.86981 -0.19005,-0.89777 0.0071,-0.028 0.06439,-0.17316 0.449157,-0.60992 0.769544,-0.87349 2.456849,-2.54664 3.787731,-5.77331 0.562967,-1.36487 0.984421,-2.07904 1.267366,-2.4723 0.282943,-0.39324 0.413643,-0.47885 0.787269,-0.85191 0.373626,-0.37306 0.896818,-1.07869 1.192229,-1.91486 0.295413,-0.83616 0.494409,-1.84068 0.714344,-3.42255 0.250018,-1.79823 -0.02884,-3.21604 -0.474019,-4.16948 -0.445177,-0.95343 -0.718007,-1.24819 -0.806053,-1.57896 -0.39975,-1.5018 -0.367831,-4.80323 -0.565729,-9.72402 -0.16519,-4.10751 -2.628211,-7.17944 -5.721375,-8.91189 -3.093163,-1.73244 -6.815372,-2.39605 -10.342505,-2.39604 -3.527133,1e-5 -7.248792,0.6636 -10.341955,2.39604 -3.093164,1.73245 -5.556184,4.80438 -5.721374,8.91189 -0.197898,4.92079 -0.165978,8.22222 -0.565729,9.72402 -0.08804,0.33077 -0.360877,0.62553 -0.806053,1.57896 -0.445175,0.95344 -0.724038,2.37125 -0.474019,4.16948 0.219935,1.58187 0.418379,2.58639 0.713791,3.42255 0.295413,0.83617 0.818604,1.5418 1.192229,1.91486 0.373626,0.37306 0.504324,0.45867 0.787269,0.85191 0.282945,0.39326 0.702742,1.10743 1.265708,2.4723 1.330883,3.22667 3.020398,4.89982 3.789941,5.77331 0.38477,0.43676 0.441595,0.58195 0.448605,0.60992 0.0071,0.028 0.05811,0.19931 -0.190049,0.89777 -1.051228,2.95891 -2.275646,3.58233 -4.086065,4.33302 -1.810418,0.75067 -10.0371228,3.22314 -11.995264,5.73594 1.2651524,2.16179 2.176238,1.84512 3.228079,2.51373 9.271611,3.19752 35.819467,2.63162 45.700834,0 z" />
                    <g
                    id="g6230" />
                </g>
            </svg>

        </button>
        <mat-menu #profileMenu="matMenu">
            <button  mat-menu-item routerLink="/support" i18n>Podpora</button>
            <button  mat-menu-item i18n (click)="enable2FA()" >Zapnout 2FA</button>
            <button  mat-menu-item (click)="logout()" i18n>Odhlášení</button>
        </mat-menu>

        <button mat-icon-button class="top-bar__langmenu" [matMenuTriggerFor]="menu" i18n-matTooltip  [matTooltip]="'Jazyk'"><mat-icon color="primary">translate</mat-icon></button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="selectLanguage('cs')"><img  class="top-bar__langmenu__flag" src="assets/img/flags/cz.svg"><span i18n>Čeština</span></button>
            <button mat-menu-item (click)="selectLanguage('en')"><img class="top-bar__langmenu__flag" src="assets/img/flags/gb.svg"><span i18n>Angličtina</span></button>
        </mat-menu>
    </div>
</div>