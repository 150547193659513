export class Tag {
  type: string;
  value: string;
  group: string;
  isSelected: boolean;
  description: string;
}

export interface TagEvent {
  checkedTags: Tag[];
  removedTags: Tag[];
}
