import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertDataSizes'
})
export class ConvertDataSizesPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    return this.convertDataSize(value);
  }

  convertDataSize( size: number) {

    if ( isNaN(size) ) {
      size = 0;
    }

    if (size < 1024){
      return size + ' Bytes';
    }

    size /= 1024;

    if (size < 1024) {
      return size.toFixed(2) + ' Kb';
    }
    size /= 1024;

    if (size < 1024) {
      return size.toFixed(2) + ' Mb';
    }
    size /= 1024;

    if (size < 1024){
      return size.toFixed(2) + ' Gb';
    }
    size /= 1024;

    return size.toFixed(2) + ' Tb';
  }

}
