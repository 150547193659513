import { Component, OnInit, Inject, ViewEncapsulation } from "@angular/core";
import { Device } from "../../models/device.model";
import { DevicesService } from "../devices.service";

import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from "@angular/material/dialog";
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { TagsService } from "src/app/tags/tags.service";
import { Tag } from "src/app/models/tag.model";
import { UserService } from "src/app/user/user.service";
import { CampaignPreview } from "src/app/models/campaign-preview.model";
import { catchError } from "rxjs/operators";
import { of as observableOf } from "rxjs";
import { ConfirmDialogComponent } from "src/app/utils/confirm-dialog/confirm-dialog.component";
import { environment } from "../../../environments/environment";
import { CampaignsService } from "src/app/campaigns/campaigns.service";
import { Layout } from "src/app/models/layout.model";

@Component({
  selector: "app-device-detail",
  templateUrl: "./device-detail.component.html",
  styleUrls: ["./device-detail.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DeviceDetailComponent implements OnInit {
  device: Device;
  deviceId: number;
  startTime: string;
  endTime: string;
  nonstopPlay: boolean;
  allTags: Tag[];
  deviceFormGroup: FormGroup;
  attachedCampaigns: CampaignPreview[];
  campaignIds: number[];
  displayInstalation: string;
  rotation: number;
  isLoadingResults = false;
  htmlPlayerUrl = "";
  tabIndex = 0;

  //privileges
  isAdmin = false;
  devicePrivilege = false;
  layoutPrivileges = false;

  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    private tagsService: TagsService,
    private devicesService: DevicesService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DeviceDetailComponent>,
    private campaignsService: CampaignsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.startTime = "07:00";
    this.endTime = "23:59";
    this.nonstopPlay = true;
    this.deviceId = data.deviceId;
  }

  ngOnInit() {
    this.isAdmin = this.userService.hasRole("admin");
    this.devicePrivilege = this.userService.hasPrivilege("device");
    this.layoutPrivileges = this.userService.hasPrivilege("layout");

    this.deviceFormGroup = this.fb.group({
      name: new FormControl({ value: null, disabled: true }),
      clientName: new FormControl({ value: null }),
      branch: new FormControl({ value: null }),
      layout: new FormControl({ value: null, disabled: !this.isAdmin }),
      geolocation: new FormControl({ value: null }),
      ipLocal: new FormControl({ value: null, disabled: true }),
    });
    this.fetchDeviceDetail();
    this.deviceFormGroup.valueChanges.subscribe((value) => {
      this.updateDataModel(value);
    });
  }

  fetchDeviceDetail() {
    this.devicesService
      .fetchDeviceById(this.deviceId)
      .pipe(
        catchError((err) => {
          console.log(err);
          this.isLoadingResults = false;
          return observableOf({});
        })
      )
      .subscribe((response: Device) => {
        this.device = response;
        if (!this.device.clientName) {
          this.device.clientName = this.device.name;
        }
        this.device.branch = this.device.branch
          ? this.device.branch
          : "nevyplněno";
        this.deviceFormGroup
          .get("name")
          .setValue(this.device.name, { emitEvent: false });
        this.deviceFormGroup
          .get("clientName")
          .setValue(this.device.clientName, { emitEvent: false });
        this.deviceFormGroup
          .get("branch")
          .setValue(this.device.branch, { emitEvent: false });
        this.deviceFormGroup
          .get("layout")
          .setValue(this.device.layout, { emitEvent: false });
        this.deviceFormGroup
          .get("geolocation")
          .setValue(this.device.geolocation, { emitEvent: false });

        if (this.device.netconf) {
          let ip = this.device.netconf.ipLocal
            ? this.device.netconf.ipLocal
            : "adresa neni přiřazena";
          this.deviceFormGroup
            .get("ipLocal")
            .setValue(ip, { emitEvent: false });
        } else {
          this.deviceFormGroup
            .get("ipLocal")
            .setValue("adresa neni přiřazena", { emitEvent: false });
        }

        if (this.device.type === "Tizen") {
          this.deviceFormGroup.get("layout").enable();
        }
        this.campaignIds = this.device.campaigns.map((campaign) => {
          return campaign.id;
        });

        this.generateHtmlPlayerUrl();
      });
  }

  saveDevice() {
    this.devicesService.saveDevice(this.device).subscribe((message) => {
      this.devicesService.updateDevices();
      this.dialogRef.close();
      this.tagsService.updateTags();
    });
  }

  removeDevice() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "300px",
      panelClass: "",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.devicesService
          .removeDevice(this.device.id)
          .subscribe((message) => {
            this.devicesService.updateDevices();
            this.dialogRef.close();
          });
      }
    });
  }
  attachTag(newTag: Tag) {
    let index = this.device.tags.findIndex((tag) => {
      return tag.value === newTag.value && tag.group === newTag.group;
    });
    if (index === -1) {
      this.device.tags.push(newTag);
    }
  }
  removeTag(removedTag: Tag) {
    let index = this.device.tags.findIndex((tag) => {
      return tag.value === removedTag.value && tag.group === removedTag.group;
    });
    if (index !== -1) {
      this.device.tags.splice(index, 1);
    }
  }
  changeAttachedCampaign(campaigns: CampaignPreview[]) {
    this.device.campaigns = [];
    campaigns.forEach((campaign) => {
      this.device.campaigns.push({
        id: campaign.id,
        name: campaign.name,
        color: campaign.color,
      });
    });
  }

  updateDataModel(value) {
    if (value.geolocation) {
      if (typeof value.geolocation == "string") {
        value.geolocation = value.geolocation.replaceAll("\\s+", "").split(",");
        value.geolocation[0] = parseFloat(value.geolocation[0]);
        value.geolocation[1] = parseFloat(value.geolocation[1]);
      }
    }
    this.device = Object.assign(this.device, value);
  }
  removeAttachedCampaing(id: number) {
    const index = this.device.campaigns.findIndex(
      (campaign) => campaign.id === id
    );
    if (index !== -1) {
      this.device.campaigns.splice(index, 1);
      this.campaignIds = this.device.campaigns.map((campaign) => campaign.id);
    }
  }
  generateHtmlPlayerUrl(): void {
    this.htmlPlayerUrl = `${environment.serverProtocol}://${
      environment.serverAddress
    }/htmlplayer?deviceid=${this.device.id}&code=${encodeURIComponent(
      this.device.htmlPlayerCode
    )}`;
  }
  goToUrl(url): void {
    this.devicesService.openPreview(this.device);
  }
  hasAdvancedFunction(): boolean {
    //return this.device.type === "Tizen" || this.device.type === "android"; TODO
    return true;
  }
  openCampaignDetail(campaignId: number) {
    this.campaignsService.openCampaignDetail(campaignId);
  }
  public handleMissingImage(event: Event) {
    (event.target as HTMLImageElement).style.display = "none";
  }

  onSelectedLayout(layout: Layout) {
    this.device.layoutDetail = layout;
    this.device.layout = layout.urlRender;
  }
  onlayoutUrlChanged(url: string) {
    this.device.layout = url;
  }
}
