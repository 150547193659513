import { Component, OnInit, Input,  Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Campaign } from 'src/app/models/campaign.model';
import { FormControl, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Tag } from 'src/app/models/tag.model';
import { UserService } from 'src/app/user/user.service';

@Component({
selector: 'app-campaign-name',
templateUrl: './campaign-name.component.html',
styleUrls: ['./campaign-name.component.scss'],
encapsulation: ViewEncapsulation.None,
})
export class CampaignNameComponent implements OnInit {
    @Input() campaign: Campaign;
    @Output() validated = new EventEmitter();
    nameFormGroup: FormGroup;
    isAdmin: boolean;

    constructor(private fb: FormBuilder,private userService: UserService)
    { }

    ngOnInit() {
        this.isAdmin = this.userService.hasRole('admin');
        this.nameFormGroup = this.fb.group({
            name: new FormControl(this.campaign.name, Validators.required),
            description: new FormControl(this.campaign.description),
            color: new FormControl(this.campaign.color),
        });
        this.nameFormGroup.valueChanges.subscribe((value) => { this.updateDataModel(value); });
        this.checkValidity();
    }
    updateDataModel(value) {
        this.campaign = Object.assign(this.campaign, value);
        this.checkValidity();
    }
    
    checkValidity() {
        let message = null;
        if (this.campaign.type == "fixed" && this.campaign.fixedStartTime > this.campaign.fixedEndTime) {
            message = "Neni vyplněn název kampaně";
        } else {
            message = null;
        }
        this.validated.emit({status:this.nameFormGroup.status, message});
    }

    attachTag(newTag: Tag) {
        const index = this.campaign.tags.findIndex(tag => {
        return tag.value === newTag.value && tag.group === newTag.group;
        });
        console.log(index);
        if (index === -1) {
        this.campaign.tags.push(newTag);
        }
    }
    removeTag(removedTag: Tag) {
        const index = this.campaign.tags.findIndex(tag => {
        return tag.value === removedTag.value && tag.group === removedTag.group;
        });
        if (index !== -1) {
        this.campaign.tags.splice(index, 1);
        }
    }
}
