import { Tag } from "./tag.model";

export class ContentClip {
  id: number;
  name: string;
  description: string;
  volume: number;
  mute: boolean;
  source: string;
  originalDuration: number;
  duration: number;
  mimeType: string;
  clipType: string;
  tags: Tag[];
  uploadDate: string;
  size: number;
  resolution: {
    width: number;
    height: number;
  };
  preview: {
    small: {
      url: string;
    };
    large: {
      url: string;
    };
  };
  inCampaigns: Array<number>;
  category: string;
  dragDisabled?: boolean;
  companyId: number | null;
  isTranscoding: boolean;
  fullscreen: boolean;
  playOriginal: boolean;
}
