<div class="dialog two-factor">
  <div mat-dialog-title>
      <h2 i18n>Zapnutí Dvoufaktorové autentizace</h2>
      <button class="dialog__close" mat-icon-button mat-dialog-close aria-label="Close dialog">
          <mat-icon>clear</mat-icon>
      </button>
  </div>
  <div mat-dialog-content class="dialog__content">
    <div class="two-factor__container">
      <div [formGroup]="authorization">
          <p i18n>Spusťe vaši mobilní autentizační aplikaci a naskenujte QR kód. Tím aktivujete tak dvoufaktorové ověření.</p>
          
          <mat-form-field  class="two-factor__form" appearance="fill">
            <mat-label>Kód z aplikace</mat-label>
            <input formControlName="otp"type="text"   matInput placeholder="Kód z aplikace">
            <mat-icon matSuffix>key</mat-icon>
            <mat-hint>Zadejte kód z autentizační aplikace</mat-hint>
          </mat-form-field>

          <mat-form-field  class="two-factor__form" appearance="fill">
            <mat-label>Vaše heslo</mat-label>
            <input type="password" formControlName="password" matInput placeholder="Vaše heslo">
            <mat-hint>Zadejte vaše heslo pro změnu</mat-hint>
          </mat-form-field>
      </div>
      <mat-divider vertical></mat-divider>
      <div class="two-factor__qr">
        <div *ngIf="qrUrl; else loading">
          <img  [src]="qrUrl" alt="QR kód">
        </div>
      </div >
    </div>
  </div>
  <div mat-dialog-actions class="dialog__footer dialog__footer--reverse">
      <button mat-raised-button color="primary" [disabled]="!authorization.valid"  (click)="complete2FA()"><span i18n>Nastavit</span><mat-icon color="white">check</mat-icon></button>
  </div>
</div>


<ng-template #loading>
  <app-loading></app-loading>
</ng-template>

