import { B } from '@angular/cdk/keycodes';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { ConfirmDialogComponent } from 'src/app/utils/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-account-delete',
  templateUrl: './account-delete.component.html',
  styleUrls: ['./account-delete.component.scss']
})
export class AccountDeleteComponent {
  loading: boolean = false;
  loginError: boolean = false;
  errorCode: number | null = null;
  login: FormGroup = new FormGroup({
    loginName: new FormControl("", Validators.required),
    password: new FormControl("", Validators.required),
  });
  code: FormGroup = new FormGroup({
    otp: new FormControl("", Validators.required),
  });
  showCodeForm:boolean = false;
  constructor(private authorizationService: AuthorizationService, public dialog: MatDialog,
    ) {

  }
  onSubmit() {
   
  }
  get f() {
    return this.login.controls;
  }
  openDeleteComfirm() {
    
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '300px',
      panelClass: '',
      data: {message: "Chcete opravdu trvale odstranit váš účet?"}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.authorizationService.deleteAccount().subscribe({next: (response: any) => {
          console.log(response);
          this.authorizationService.logout();
        }})
      }
    });
  }
  
} 
