<div class="campaign-recapitulation grid" >
    <div class="grid--4" >
        <h3 i18n>Začátek kampaně</h3>
        <span>{{campaign.startDate | date:'fullDate'}}</span>
        <span> - </span>
        <span>{{campaign.startTime}}</span>
    </div>
    <div class="grid--4" >
        <h3 i18n>Konec kampaně</h3>
        <div *ngIf="!campaign.untilStop; else untilStopTemplate">
            <span>{{campaign.endDate | date:'fullDate'}}</span>
            <span> - </span>
            <span>{{campaign.endTime}}</span>
        </div>
    </div>
    <div class="grid--4" >
        <div class="grid" *ngIf="campaign.type=='weight'">
            <div class="grid--6" >
                <h3 i18n>Typ kampaně</h3>
                <span i18n>Váha</span>
            </div>
            <div class="grid--6" >
                <h3 i18n>Váha</h3>
                <span>{{ campaign.weight }}</span>
            </div>
        </div>
        <div class="grid" *ngIf="campaign.type=='interval'" >
            <div class="grid--6" >
                <h3 i18n>Typ kampaně</h3>
                    <span i18n>Interval</span>
            </div>
            <div class="grid--6" >
                <h3 i18n>Interval</h3>
                <span>{{ campaign.interval }}</span>
            </div>
        </div>
        
        <div class="grid" *ngIf="campaign.type=='fixed'" >
            <div class="grid--6" >
                <h3 i18n>Typ kampaně</h3>
                    <span i18n>Fixní čas</span>
            </div>
            <div class="grid--6" >
                <h3 i18n>Čas</h3>
                <span i18n>Od: {{ campaign.fixedStartTime }}</span> <span i18n>Do: {{ campaign.fixedEndTime }}</span>
            </div>
        </div>
    </div>
</div>

<div>
    <h3 i18n>Obsah kampaně</h3>
    <div class="campaign-recapitulation__videoclips">
        <app-clip-item [multipleActions]="false" [type]="'preview'" [clip]="clip" *ngFor="let clip of campaign.videoclips; let i = index"></app-clip-item>
    </div>
</div>

<div class="grid" *ngIf="campaign.selectedDevices.length > 0">
    <div class="grid--12" >
        <h3 i18n>Přehrávat na zařízení</h3>
        <mat-chip-listbox i18n-aria-label aria-label="Přiřazené zařízení">
            <mat-chip  *ngFor="let device of selectedDevicesInfo" >{{device.name}}</mat-chip>
        </mat-chip-listbox>
    </div>
</div>

<ng-template #untilStopTemplate>
    <span i18n>do zrušení</span>
</ng-template>

<ng-template #loading>
    <app-loading></app-loading>
</ng-template>