import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatStepper } from "@angular/material/stepper";
import {
  Layout,
  LayoutPreviewTemplate,
  LayoutOrientation,
  LayoutPreviewFilters,
} from "src/app/models/layout.model";
import { LayoutsService } from "../layouts.service";

@Component({
  selector: "app-layout-selection",
  templateUrl: "./layout-selection.component.html",
  styleUrls: ["./layout-selection.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class LayoutSelectionComponent implements OnInit, OnChanges {
  @ViewChild("stepper", { static: false }) private layoutStepper: MatStepper;

  @Input() layoutUrl?: string;
  @Input() selectedLayout?: Layout;
  @Output() selected = new EventEmitter();
  @Output() layoutUrlChanged = new EventEmitter();
  layoutSelectionFormGroup: FormGroup;
  layoutTemplateList: LayoutPreviewTemplate = LayoutPreviewTemplate.Small;
  previewTemplate: LayoutPreviewTemplate = LayoutPreviewTemplate.Large;
  isLayoutSelected = false;
  layoutFilters: LayoutPreviewFilters = new LayoutPreviewFilters();
  isLoadingResults: boolean;
  showCustomUrl: boolean = true;
  showLayoutSelection: boolean = false;
  public largeTemplate = LayoutPreviewTemplate.Large;

  constructor(private fb: FormBuilder, private layoutsService: LayoutsService) {
    this.layoutSelectionFormGroup = this.fb.group({
      orientation: new FormControl(
        { value: null, disabled: false },
        Validators.required
      ),
      layoutUrl: new FormControl({ value: this.layoutUrl, disabled: false }),
      pasteLink: new FormControl({ value: false, disabled: false }),
    });

    this.layoutSelectionFormGroup.valueChanges.subscribe((value) => {
      this.updateDataModel(value);
    });
  }

  ngOnInit() {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes.layoutUrl) {
      this.layoutSelectionFormGroup
        .get("layoutUrl")
        .patchValue(changes.layoutUrl.currentValue, { emitEvent: false });
      this.fetchLayout();
    }
  }

  fetchLayout() {
    this.isLoadingResults = true;
    this.layoutsService.fetchLayoutsByCompany().subscribe((data: any) => {
      this.selectedLayout = this.getLayputByUrl(data, this.layoutUrl);
      if (this.selectedLayout) this.showCustomUrl = false;
      this.isLoadingResults = false;
    });
  }

  getLayputByUrl(layouts: Layout[], url: string): Layout {
    let foundedLayout = null;
    layouts.forEach((layout: Layout) => {
      console.log(layout.urlRender);
      if (layout.urlRender == url) {
        foundedLayout = layout;
      }
    });
    return foundedLayout;
  }

  updateDataModel(value) {
    if (this.selectedLayout) {
      this.selectedLayout = Object.assign(this.selectedLayout, value);
      this.selected.emit(this.selectedLayout);
    }
    if (value.layoutUrl) {
      this.layoutUrl = value.layoutUrl;
      this.layoutUrlChanged.emit(this.layoutUrl);
    }
  }
  setOrientation(orientation: string) {
    this.layoutSelectionFormGroup
      .get("orientation")
      .patchValue(orientation, { emitEvent: true });
    this.layoutFilters.orientation = orientation as LayoutOrientation;
    this.layoutFilters = structuredClone(this.layoutFilters);
    this.layoutStepper.next();
  }
  onSelectedLayout(layout: Layout) {
    console.log(layout);
    this.selectedLayout = layout;
    this.showLayoutSelection = false;
    this.selected.emit(this.selectedLayout);
  }
  changeLayout() {
    this.showCustomUrl = false;
    this.showLayoutSelection = true;
  }
  changeLayoutUrl() {
    this.selectedLayout = null;
    this.showCustomUrl = true;
  }
  saveLayoutUrl(url: string) {
    this.layoutUrl = url;
  }
}
