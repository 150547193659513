import { Component, OnInit, Inject, ViewEncapsulation } from "@angular/core";
import { CampaignsService } from "../campaigns.service";

import { Campaign } from "../../models/campaign.model";
import { ContentClip } from "../../models/content-clip.model";

import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from "@angular/material/dialog";
import { catchError } from "rxjs/operators";
import { ConfirmDialogComponent } from "../../utils/confirm-dialog/confirm-dialog.component";
import { FormGroup, FormControl, FormBuilder } from "@angular/forms";
import { ScheduleService } from "src/app/schedule/schedule.service";
import { of as observableOf } from "rxjs";
import { PlaylistService } from "src/app/playlist/playlist.service";
import { Playlist } from "src/app/models/playlist.model";
import { CustomUtils } from "src/app/utils/custom-utils.component";
import { ClipsService } from "src/app/clips/clips.service";

@Component({
  selector: "app-campaign-detail",
  templateUrl: "./campaign-detail.component.html",
  styleUrls: ["./campaign-detail.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CampaignDetailComponent implements OnInit {
  campaignId: number;
  campaign: Campaign;
  currentPlaylist: Playlist;
  contentList: ContentClip[];
  selectedIndex = 0;
  saving = false;
  campaignFormGroup: FormGroup;
  stateCache: string;
  validation: any[] = [true, true, true, true, true, true];
  isValid = true;
  isLoadingResults = true;
  error: any;
  hasError = false;
  validationMesasge = "";
  activeTabIndex = 0;
  constructor(
    public dialog: MatDialog,
    private campaignsService: CampaignsService,
    private scheduleService: ScheduleService,
    private fb: FormBuilder,
    private playlistService: PlaylistService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CampaignDetailComponent>
  ) {
    this.campaignId = this.data.campaignId;
  }

  ngOnInit() {
    this.campaignFormGroup = this.fb.group({
      isActive: new FormControl(false),
    });
    this.campaignFormGroup.valueChanges.subscribe((value) => {
      this.updateDataModel(value);
    });
    this.campaignsService.onRemovePlaylist.subscribe(() => {
      this.tabChanged(2);
      this.campaign.type = "weight";
      this.campaign.playlistId = null;
      this.saveCamaign(false);
    });
    this.fetchCampaignDetail();
  }

  fetchCampaignDetail() {
    this.isLoadingResults = true;
    this.campaignsService
      .fetchCampaignById(this.campaignId)
      .pipe(
        catchError((err) => {
          this.isLoadingResults = false;
          this.error = err.error;
          this.hasError = true;
          return observableOf({});
        })
      )
      .subscribe((result: any) => {
        if (result.id) {
          this.campaign = result;
          this.stateCache = this.campaign.state;
          this.isLoadingResults = false;
          this.campaignFormGroup
            .get("isActive")
            .setValue(this.campaign.isActive);
        }
      });
  }
  tabChanged(event) {
    this.selectedIndex = event.index;
  }
  saveCamaign(closeDialog = true) {
    this.saving = true;
    this.campaignsService
      .updateCampaign(this.campaign)
      .subscribe((response) => {
        if (closeDialog) this.dialogRef.close();
        this.campaignsService.updateCampaignsList();
        this.scheduleService.updateSchedule();
      });

    if (this.campaign.type === "playlist" && this.currentPlaylist) {
      this.playlistService
        .updatePlaylist(this.currentPlaylist)
        .subscribe(() => {
          console.log("playlistUpdated");
        });
    }
  }

  duplicateCamaign() {
    this.saving = true;
    this.campaign.name = this.campaign.name;
    this.campaign.edited = new Date().toISOString();
    this.campaignsService
      .saveNewCampaign(this.campaign)
      .subscribe((response) => {
        this.dialogRef.close();
        this.campaignsService.updateCampaignsList();
        this.scheduleService.updateSchedule();
      });
  }
  deleteCampaign() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "300px",
      panelClass: "",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.campaignsService
          .deleteCampaign(this.campaign.id)
          .subscribe((response) => {
            this.dialogRef.close();
            this.campaignsService.updateCampaignsList();
          });
      }
    });
  }

  updateDataModel(value) {
    this.campaign = Object.assign(this.campaign, value);
  }

  toggleActiveCampaign() {
    if (this.campaign.isActive) {
      this.campaign.state = "paused";
      this.campaignFormGroup.get("isActive").setValue(false);
    } else {
      if (this.campaign.endDate < new Date().toISOString()) {
        this.campaign.state = "ended";
      } else if (this.campaign.startDate > new Date().toISOString()) {
        this.campaign.state = "waiting";
      } else {
        this.campaign.state = "active";
      }

      this.campaignFormGroup.get("isActive").setValue(true);
    }
    this.saveCamaign();
  }

  finishCampaign() {
    let actualDate = new Date().toISOString();

    if (
      (this.campaign.startDate < actualDate && this.campaign.untilStop) ||
      (this.campaign.startDate < actualDate &&
        this.campaign.endDate > actualDate)
    ) {
      this.campaignFormGroup.get("isActive").setValue(true);
      this.campaign.state = "ended";
      this.campaign.untilStop = false;
      this.campaign.endDate = actualDate;
      this.campaign.endTime = CustomUtils.getActualTime();
      this.saveCamaign();
    }
  }

  isCompleted(validation, slideIndex?: number) {
    let isCompleted = validation.status === "VALID" ? true : false;

    if (slideIndex !== undefined) {
      this.validation[slideIndex] = isCompleted;
      this.isValid = true;

      this.validation.forEach((tab: boolean) => {
        if (!tab) {
          this.isValid = false;
        }
      });
    }
    if (!isCompleted) {
      if (validation.message) {
        this.validationMesasge = validation.message;
        console.log(this.validationMesasge);
      }
    } else {
      this.validationMesasge = null;
    }
  }

  playlistChanged(playlist) {
    this.currentPlaylist = playlist;
  }
}
