<div class="dialog dialog--small">
    <div class="dialog__header">
        <h2 i18n>Kontaktujte nás</h2>
    </div>
    <div class="dialog__content"> 
        <p><span  i18n>Vypadá to, že tahle funkce není aktivovaná. Pokud máte zájem ji aktivovat, kontaktujte nás na emailové adrese: </span><a href="mailto:obchod@ismeda.cz">obchod@ismeda.cz</a></p>
    </div>
    <div class="dialog__footer dialog__footer--reverse">
        <button mat-raised-button color="primary" [mat-dialog-close] i18n>Rozumím</button>
    </div>
</div>