
<div mat-dialog-title>
    <h2 i18n>Chyba</h2>
</div>
<div  mat-dialog-content>
    <p i18n>Nejprve zvolte společnost</p>
    <app-company-select [companyId]="selectedCompany ? selectedCompany.companyId : null " (select)="companySelect($event)"></app-company-select>
</div>
<div mat-dialog-actions class="dialog__footer">
    <button *ngIf="selectedCompany" (click)="saveCompany()" mat-raised-button color="primary"><span i18n>Uložit</span><mat-icon color="white">check</mat-icon></button>
</div>
    

