<form [formGroup]="companySelectFormGroup" >
    <mat-form-field  class="mat-mdc-form-field--fullwidth">
        <input type="text"
            i18n-placeholder
            i18n-aria-label
            placeholder="Vyberte společnost"
            aria-label="Společnost"
            matInput
            formControlName="selectedCompanyName"
            [matAutocomplete]="auto"
            (focus)="clearInput()"
            >
      <mat-autocomplete [panelWidth]="300" class="company-select__autocomplete" autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="selectCompany($event.option.value)">
        <mat-option  [value]="null" i18n>Všechno</mat-option>
        <mat-option  class="company-select__option" *ngFor="let company of companiesFiltered | async" [value]="company.companyId">
          <h4>{{company.company}}</h4>
          <p *ngIf="company.shownName">{{company.shownName}}</p>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>