import { Component, OnInit, Input, Output, EventEmitter, SimpleChange, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatChipListboxChange } from '@angular/material/chips';

interface State {
    name: string;
    value:string;
    color: string;
    isSelected: boolean;
}

@Component({
    selector: 'app-campaign-state',
    templateUrl: './campaign-state.component.html',
    styleUrls: ['./campaign-state.component.scss']
})
export class CampaignStateComponent implements OnChanges {
    
    /**
      * Input state filter states and show only current state
    */
    @Input() state?: string | null = null; 
    /**
      * Default active makes states active by default
    */
    @Input() defaultActive = [];

    /**
      * Emmit all selected states
    */
    @Output() selectedStates = new EventEmitter();

    activeStates = [];
    statesList: State[] = [
        {
        name: $localize `aktivní`,
        value: 'active',
        color: 'primary',
        isSelected: false
        },
        {
        name: $localize `čekající`, 
        value: 'waiting',
        color: 'accent',
        isSelected: false
        },
        {
        name:  $localize `skončené`,
        value: 'ended',
        color: 'green',
        isSelected: false
        },
        {
        name: $localize `pozastavené`,
        value: 'paused',
        color: 'red',
        isSelected: false
        }
    ];

    constructor() { }

    ngOnChanges(change: SimpleChanges) {
        if (change['state']) {
          this.state = change.state.currentValue;
          this.activeStates[0] = this.statesList.find(item => item.value === this.state);
        }

        if (change['defaultActive']) {
          this.defaultActive = change.defaultActive.currentValue;
          this.activeStates = [...this.statesList];
          this.activeStates = this.activeStates.filter(item =>  { return this.defaultActive.includes(item.value)});
        }
    }
    select(state: MatChipListboxChange) {
        if(state) {
          this.activeStates = this.activeStates.filter(item =>  { return state.value.includes(item.value)});
          this.selectedStates.emit( state.value );
        } 
    }
    isSelected(value: string):boolean {
      return  this.activeStates.some(state => state.value === value);
    }
    reset() {
        this.activeStates = [];
    } 
}
