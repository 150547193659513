import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ScheduleTimesOptions } from 'src/app/models/schedule.model';
import { ScheduleTimeComponent } from 'src/app/schedule/schedule-time/schedule-time.component';

@Component({
selector: 'app-campaign-time',
templateUrl: './campaign-time.component.html',
styleUrls: ['./campaign-time.component.scss']
})
export class CampaignTimeComponent implements OnInit {
    fixedCampaignOptions: ScheduleTimesOptions;
    optionsCache: ScheduleTimesOptions;
    constructor( @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ScheduleTimeComponent>) {
        this.fixedCampaignOptions =  data ? data :  {};
        this.fixedCampaignOptions.repeatFixedCampaign = data.repeatFixedCampaign == undefined ? false :  data.repeatFixedCampaign;
        this.optionsCache = Object.assign({}, this.fixedCampaignOptions);
     }

    ngOnInit(): void {
    }
    onSelect(event) {
        this.fixedCampaignOptions.times = event.times;
    }
    saveTimes() {
        this.dialogRef.close({data:this.fixedCampaignOptions});
    }
    closeDialog(){
        this.dialogRef.close({event:'close', data: this.optionsCache});
    }
}
