import { Component, OnInit, Input, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-device-state",
  templateUrl: "./device-state.component.html",
  styleUrls: ["./device-state.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DeviceStateComponent implements OnInit {
  @Input() state: any = "";
  @Input() title? = true;
  constructor() {}

  ngOnInit() {}
}
