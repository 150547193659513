import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-delete-snackbar',
  templateUrl: './delete-snackbar.component.html',
  styleUrls: ['./delete-snackbar.component.scss']
})
export class DeleteSnackbarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
