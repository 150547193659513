import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  ViewChild,
  EventEmitter,
  AfterViewInit,
  ViewEncapsulation,
} from "@angular/core";
import { DevicePreview } from "../../models/device-preview.model";
import { DevicesService } from "../devices.service";

import { MatTableDataSource } from "@angular/material/table";
import { MatSort, Sort } from "@angular/material/sort";
import { of as observableOf } from "rxjs";

import { SelectionModel } from "@angular/cdk/collections";
import { Tag, TagEvent } from "src/app/models/tag.model";
import { ScheduleService } from "src/app/schedule/schedule.service";
import { catchError } from "rxjs/operators";
import { UserService } from "src/app/user/user.service";
import { AuthorizationService } from "src/app/services/authorization.service";
import { DeviceFilters, CampaignFilters } from "src/app/models/filters.model";
import { Device } from "src/app/models/device.model";

@Component({
  selector: "app-devices-list",
  templateUrl: "./devices-list.component.html",
  styleUrls: ["./devices-list.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DevicesListComponent implements OnInit, AfterViewInit {
  @Input() checkable = false;
  @Input() checkedDevices: number[] = [];
  @Input() template = "table";

  @Output() checkDevice = new EventEmitter();

  selection: SelectionModel<DevicePreview>;
  dataSource = new MatTableDataSource<DevicePreview>();

  filters: DeviceFilters = { tags: [], fulltext: ""};
  filtersCache: DeviceFilters;

  displayedColumns: string[];
  removable = true;
  isLoadingResults = true;
  isAdmin: boolean;
  devicePrivilege: boolean;
  activeSelectionTags: Tag[] = [];
  
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private authorizationService: AuthorizationService,
    private devicesService: DevicesService,
    private userService: UserService,
    private scheduleService: ScheduleService
  ) {
    this.selection = new SelectionModel<DevicePreview>(true, []);
    this.isAdmin = this.userService.hasRole("admin");
    this.devicePrivilege = this.userService.hasPrivilege("device");
  }

  ngOnInit() {
    this.filtersCache = {...this.filters};

    if (this.checkable) {
      this.displayedColumns = [
        "check",
        "id",
        "branch",
        "name",
        "tags",
        "state",
      ];
    } else {
      this.displayedColumns = ["id", "branch", "name", "state", "tags", "edit"];
    }

    this.loadLocalFilters();

    // subscriptions
    this.devicesService.filterTags.subscribe((tags) => {
      this.filterByTags(tags);
    });

    this.devicesService.checkedTags.subscribe((tagsEvent: TagEvent) => {
      this.checkDevicesByTags(tagsEvent);
    });

    this.devicesService.updateDevicesList.subscribe(() => {
      this.fetchDevices();
    });
  }

  ngAfterViewInit() {
    this.fetchDevices();

    
  }

  loadLocalFilters() {
    if(localStorage.getItem("deviceFilters")) {
      this.filters = JSON.parse(localStorage.getItem("deviceFilters")) as CampaignFilters;
    }
  }
  saveLocalFilters() {
    localStorage.setItem("deviceFilters", JSON.stringify(this.filters));
  }
  clearLocalFilters() {
    localStorage.removeItem("deviceFilters");
  }

  fetchDevices() {
    this.isLoadingResults = true;
    this.devicesService
      .fetchUserDevices()
      .pipe(
        catchError((error) => {
          this.isLoadingResults = false;
          console.log("Error with downloading campaigns");
          console.log(error);
          if (error.status == 401) {
            this.authorizationService.logout();
          }
          return observableOf([]);
        })
      )
      .subscribe((devices: DevicePreview[]) => {
        this.dataSource = new MatTableDataSource(devices);
        this.devicesService.devices = devices;
        this.dataSource.sort = this.sort;
        this.checkDevicesByIds(this.checkedDevices);
        this.isLoadingResults = false;
        this.setFilterPredicable();
        this.setFilters();
      });
  }

  setFilterPredicable() {
    this.dataSource.filterPredicate = (
      data: DevicePreview,
      filter: string
    ): boolean => {
      if (filter == "") {
        return true;
      }

      // filter for tags
      if (this.filters.tags.length > 0) {
        for (let filteredTag of this.filters.tags) {
          let found = data.tags.some((tag) => {
            return tag.value === filteredTag.value;
          });
          if (found) return true;
        }
      }
    
      // filter by fulltext
      if (this.filters.fulltext) {
        if (data.id) {
          let found = data.id
            .toString()
            .trim()
            .toLowerCase()
            .includes(this.filters.fulltext.toLowerCase());
          if (found) return true;
        }

        if (data.name) {
          let found = data.name
            .toLowerCase()
            .trim()
            .includes(this.filters.fulltext.toLowerCase());
          if (found) return true;
        }

        if (data.branch) {
          let found = data.branch
            .toLowerCase()
            .trim()
            .includes(this.filters.fulltext.toLowerCase());
          if (found) return true;
        }

        if (data.tags) {
          let found = data.tags.some(
            (tag) => tag.value == this.filters.fulltext.toLowerCase()
          );
          if (found) return true;
        }
      }

      // show all if filters are empty
      if (this.filters.tags.length == 0 && this.filters.fulltext == "") {
        return true;
      } else {
        return false;
      }
    };
  }

  setFilters() {
    if(this.filters.fulltext) {
      this.filterByFulltext(this.filters.fulltext);
    }

    if(this.filters.tags.length>0) {
      this.filterByTags(this.filters.tags);
    }
  }

  hasFilters() {
    return this.filters.tags.length>0 || this.filters.fulltext.length>0;
  }

  resetFilters() {
    this.filters.fulltext = "";
    this.filters.tags=[];
    this.dataSource.filter = JSON.stringify(this.filters);
    this.clearLocalFilters();
  }


  checkDevicesByIds(ids: number[]) {
    this.dataSource.data.forEach((device) => {
      if (ids) {
        ids.forEach((id) => {
          if (id === device.id) {
            this.selection.select(device);
          }
        });
      }
    });
  }

  checkDevicesByTags(tagsEvent: TagEvent) {

    if (tagsEvent.checkedTags.length > 0) {

      tagsEvent.checkedTags.forEach((tag) => {
          this.dataSource.data.forEach((device) => {
            const isFound = device.tags.some(deviceTag => deviceTag.value === tag.value && deviceTag.group == tag.group);
            if (isFound) {
              this.selection.select(device);
              this.activeSelectionTags.push(tag);
            }
          });
      });
    }

    if (tagsEvent.removedTags.length > 0) {
      tagsEvent.removedTags.forEach((tag) => {
        this.selection.selected.forEach((device) => {
     
          const isFound = device.tags.some(deviceTag => deviceTag.value === tag.value && deviceTag.group == tag.group);

          if (isFound) {
            this.selection.deselect(device);
            const tagIndex = this.activeSelectionTags.findIndex( (activeTag) => activeTag.value === tag.value && activeTag.group == tag.group);
            this.activeSelectionTags.slice(tagIndex, 1);
          }
        });
      });
    }

    //this.getActiveTagsBySelection(); TODO
    this.checkDevice.emit(this.selection.selected);
  }

  isAllSelected() {
    let allSelected = true
    this.dataSource.filteredData.forEach((device) => {
      allSelected = this.selection.isSelected(device) ? allSelected : false
    })

    return allSelected;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.dataSource.filteredData.forEach((device) =>
        this.selection.deselect(device)
      )
      : this.dataSource.filteredData.forEach((device) =>
          this.selection.select(device)
      );
      this.getActiveTagsBySelection();
      this.checkDevice.emit(this.selection.selected);
  }

  onSelectionChange(event, device) {
    
    event ? this.selection.toggle(device) : null;
    this.getActiveTagsBySelection();
    this.checkDevice.emit(this.selection.selected);
  }
  checkboxLabel(device?: DevicePreview): string {
    if (!device) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(device) ? "deselect" : "select"} row ${
      device.name + 1
    }`;
  }

  filterByFulltext(filterValue: string) {
    this.filters.fulltext = filterValue;
    this.dataSource.filter = JSON.stringify(this.filters);
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.saveLocalFilters();
  }

  filterByTags(filteredTags: Tag[]) {
    this.filters.tags = filteredTags;
    this.dataSource.filter = JSON.stringify(this.filters);
    this.saveLocalFilters();
  }

  openDeviceDetail(deviceId: number) {
    this.devicesService.openDevicedetail(deviceId);
  }
  openDeviceAuthorization() {
    this.devicesService.openDeviceAuthorization();
  }
  openDeviceTimeline(device: DevicePreview) {
    this.scheduleService.openSchedule(device);
  }
  openPreview(device: DevicePreview) {
    this.devicesService.openPreview(device);
  }

  getActiveTagsBySelection() {
    this.activeSelectionTags = [];
    this.selection.selected.forEach(device => {
      device.tags.forEach(tag => {
        let isActive = this.isTagSelected(tag );
        
          if (!this.activeSelectionTags.some(sometag => sometag.value === tag.value && sometag.group === tag.group)) {
            if (isActive) {
              this.activeSelectionTags.push(tag);
            }
          } else {
            if (!isActive) {
              this.activeSelectionTags =  this.activeSelectionTags.filter((sometag) => sometag.value != tag.value && sometag.group === tag.group)
            }
          }
      })
    });
    
    setTimeout(()=> {
      console.log("this.activeSelectionTags");
      console.log(this.activeSelectionTags);
      this.devicesService.devicesSelectedTagsSubject.next(this.activeSelectionTags);
    },1)
  }

  isTagSelected(tag: Tag) {
    let allLength = this.devicesService.devices.filter((device)=> {
      return device.tags.some(sometag => sometag.value === tag.value && sometag.group === tag.group );
    }).length;

    let selectionLength = this.selection.selected.filter((device)=> {
      return device.tags.some(sometag => sometag.value === tag.value && sometag.group === tag.group );
    }).length;

    return allLength == selectionLength && allLength > 0;
  }
}
