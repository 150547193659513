<mat-card class="tags card card--large card--soft-shadow">
    <mat-card-title><mat-icon color="primary">label</mat-icon><span i18n>Štítky</span></mat-card-title>
    <div class="tags__container">
        <mat-card class="tags__type card  mat-elevation-z8">
            <mat-card-title i18n>Pro zařízení</mat-card-title>
            <app-tags-list [type]="'devices'" [removable]="true" [selectable]="false"></app-tags-list>
        </mat-card>
        <mat-card class="tags__type card  mat-elevation-z8">
            <mat-card-title i18n>Pro kampaně</mat-card-title>
            <app-tags-list [type]="'campaigns'"  [removable]="true"  [selectable]="false"></app-tags-list>
        </mat-card>
        <mat-card class="tags__type card mat-elevation-z8">
            <mat-card-title i18n>Pro klipy</mat-card-title>
            <app-tags-list [type]="'clips'"  [removable]="true"  [selectable]="false"></app-tags-list>
        </mat-card>
    </div>
</mat-card>