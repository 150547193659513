import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  ViewEncapsulation,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { CampaignsService } from "../campaigns.service";
import { CampaignPreview } from "../../models/campaign-preview.model";
import { MatDialog } from "@angular/material/dialog";
import { of as observableOf } from "rxjs";
import { Tag } from "src/app/models/tag.model";
import { SelectionModel } from "@angular/cdk/collections";
import { CampaignFilters } from "src/app/models/filters.model";
import { Campaign } from "src/app/models/campaign.model";



@Component({
  selector: "app-campaigns-list",
  templateUrl: "./campaigns-list.component.html",
  styleUrls: ["./campaigns-list.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CampaignsListComponent
  implements AfterViewInit, OnInit, OnChanges
{
  @Input() checkable: boolean;
  @Input() multipleActions? = true;
  @Input() short = false;
  @Input() checkedCampaigns: number[] = [];
  @Output() checkCampaign = new EventEmitter();

  displayedColumns: string[];
  dataSource = new MatTableDataSource<Campaign>();
  activeStates: string[] = [];
  selection = new SelectionModel<Campaign>(true, []);
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  filters: CampaignFilters = { tags: [], fulltext: "", states: ["active", "waiting", "paused"] };
  filtersCache: CampaignFilters;

  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private campaignsService: CampaignsService,
    public dialog: MatDialog
  ) {
    this.filtersCache = this.filters;
  }

  ngAfterViewInit() {
    this.fetchCompanyCampaigns();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.checkedCampaigns) {
      this.checkCampaignByIds(changes.checkedCampaigns.currentValue);
    }
  }

  ngOnInit() {

    if (this.short) {
      this.displayedColumns = ["id", "preview", "name", "type", "state"];
    } else {
      this.displayedColumns = [
        "id",
        "color",
        "preview",
        "name",
        "startDate",
        "endDate",
        "type",
        "state",
        "selectedDevices",
        "tags",
        "edit",
      ];
    }
    // fetch it again after create
    this.campaignsService.updateCampaignList.subscribe(() => {
      this.fetchCompanyCampaigns();
    });

    this.selection.changed.subscribe((change) => {
      this.updateCheckedCampaign(change);
    });

    this.loadLocalFilters();
  }

  fetchCompanyCampaigns() {
    this.isLoadingResults = true;
    this.campaignsService.fetchCompanyCampaigns().subscribe({
      next: (campaigns: Campaign[]) => {
        this.isLoadingResults = false;
        this.dataSource = new MatTableDataSource(campaigns);
        this.dataSource.sort = this.sort;
        this.checkCampaignByIds(this.checkedCampaigns);
        this.setFilterPredicable();
        this.setFilters();
      },
      error: (error: any) => {
        this.isLoadingResults = false;
        this.isRateLimitReached = true;
        console.log(error);
        return observableOf([]);
      },
    });
  }
  setFilters() {
    if (!this.filters) {return}

    if(this.filters.states.length>0) {
      this.filterByState(this.filters.states);
    }
    if(this.filters.fulltext) {
      this.filterByFulltext(this.filters.fulltext);
    }
    if(this.filters.tags) {
      this.filterByTags(this.filters.tags);
    }
   
  }
  setFilterPredicable() {
    this.dataSource.filterPredicate = (
      data: Campaign,
      filter: string
    ): boolean => {
      if (filter == "") {
        return true;
      }

      // filter by state
      if (this.filters.states.length > 0) {
        let found = this.filters.states.includes(data.state);
        if (!found) {
          return false;
        }
      }

      // filter for tags
      if (this.filters.tags.length > 0) {
        for (let filteredTag of this.filters.tags) {
          let found = data.tags.some((tag) => {
            return tag.value === filteredTag.value;
          });
          if (found) return true;
        }
      }

      // filter by fulltext
      if (this.filters.fulltext) {
        if (data.name) {
          let found = data.name
            .toLowerCase()
            .trim()
            .includes(this.filters.fulltext.toLowerCase());
          if (found) return true;
        }

        if (data.id) {
          let found = data.id
            .toString()
            .toLowerCase()
            .trim()
            .includes(this.filters.fulltext.toLowerCase());
          if (found) return true;
        }

        if (data.description) {
          let found = data.description
            .toLowerCase()
            .trim()
            .includes(this.filters.fulltext.toLowerCase());
          if (found) return true;
        }

        if (data.companyName) {
          let found = data.companyName
            .toLowerCase()
            .trim()
            .includes(this.filters.fulltext.toLowerCase());
          if (found) return true;
        }

        if (data.author) {
          let found = data.author
            .toLowerCase()
            .trim()
            .includes(this.filters.fulltext.toLowerCase());
          if (found) return true;
        }

        if (data.tags) {
          let found = data.tags.some(
            (tag) => tag.value == this.filters.fulltext.toLowerCase()
          );
          if (found) return true;
        }
      }

      // show all if filters are empty
      if (this.filters.tags.length == 0 && this.filters.fulltext == "") {
        return true;
      } else {
        return false;
      }
    };
  }
  loadLocalFilters() {
    if(localStorage.getItem("campaignFilters")) {
      this.filters = JSON.parse(localStorage.getItem("campaignFilters")) as CampaignFilters;
    }
  }
  saveLocalFilters() {
    localStorage.setItem("campaignFilters", JSON.stringify(this.filters));
  }
  clearLocalFilters() {
    localStorage.removeItem("campaignFilters");
  }
  checkCampaignByIds(ids: number[]) {
    this.selection.clear();
    this.dataSource.data.forEach((campaign) => {
      if (ids) {
        ids.forEach((id) => {
          if (id === campaign.id) {
            this.selection.select(campaign);
          }
        });
      }
    });
  }

  updateCheckedCampaign(change) {
    this.checkCampaign.emit(this.selection.selected);
  }

  filterByFulltext(filterValue: string) {
    this.filters.fulltext = filterValue
    this.dataSource.filter = JSON.stringify(this.filters);
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.saveLocalFilters();
  }

  filterByState(states: string[]) {
    this.filters.states = states;
    this.dataSource.filter = JSON.stringify(this.filters);
    this.saveLocalFilters();
  }

  filterByTags(filteredTags: Tag[]) {
    this.filters.tags = [...filteredTags];
    this.dataSource.filter = JSON.stringify(this.filters);
    this.saveLocalFilters();
  }

  resetFilters() {
    this.filters.fulltext = "";
    this.filters.tags=[];
    this.dataSource.filter = JSON.stringify(this.filters);
    this.clearLocalFilters();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.filteredData.forEach((campaign) =>
          this.selection.select(campaign)
        );
  }

  checkboxLabel(campaign?: Campaign): string {
    if (!campaign) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${
      this.selection.isSelected(campaign) ? "deselect" : "select"
    } row ${campaign.name + 1}`;
  }

  openCampaignDetail(campaignId: number) {
    this.campaignsService.openCampaignDetail(campaignId);
  }

  addPlaceholder(error, index) {
    this.dataSource.data[index].preview.small.url =
      "assets/img/placeholders/device-thumbnail.png";
  }

  openNewCampaign() {
    this.campaignsService.openNewCampaign();
  }
  multipleActionsChanged(event) {
    // TODO
  }
  hasFilters() {
    return this.filters.tags.length>0 || this.filters.fulltext.length>0;
  }
}
