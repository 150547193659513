import { Component, OnInit, Inject } from '@angular/core';
import {
  FormControl, Validators
} from '@angular/forms';
import { ContentClip } from 'src/app/models/content-clip.model';
import { ClipsService } from '../clips.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/utils/confirm-dialog/confirm-dialog.component';
import { CampaignPreview } from 'src/app/models/campaign-preview.model';
import { CampaignsService } from 'src/app/campaigns/campaigns.service';
import { Campaign } from 'src/app/models/campaign.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomUtils } from 'src/app/utils/custom-utils.component';
import { CustomMessageComponent } from 'src/app/utils/custom-message/custom-message.component';

@Component({
  selector: 'app-clip-detail',
  templateUrl: './clip-detail.component.html',
  styleUrls: ['./clip-detail.component.scss']
})
export class ClipDetailComponent implements OnInit {
  linkedCampaigns: Campaign[];
  clip: ContentClip;
  source: FormControl;
  sourceCache: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ClipDetailComponent>,
    public dialog: MatDialog,
    private clipsService: ClipsService,
    private campaignService: CampaignsService,
    private snackBar: MatSnackBar
  ) {
    this.clip = this.data.clip;
    this.linkedCampaigns = [];
  }

  ngOnInit() {

    const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

    this.clip.tags = typeof this.clip.tags === 'undefined' ? [] : this.clip.tags;
    this.source = new FormControl(this.clip.source, [
      Validators.required,
      Validators.pattern(reg)
    ] );
    this.source.valueChanges.subscribe((value) => {
      this.clip.source = CustomUtils.addhttp(value);
    });

    if (this.clip.inCampaigns.length > 0) {
      // fetch more info about linked campaigns
      this.fetchLinkedCampaigns();
    }
    this.updateClipData();

  }

  updateClipData() {
    this.clipsService.fetchClipById(this.clip.id).subscribe((data: ContentClip) => {
      this.clip = data;
      this.sourceCache = data.source;
    });
  }

  fetchLinkedCampaigns() {
    this.campaignService
      .fetchCompanyCampaigns(this.clip.inCampaigns)
      .subscribe(data => {
        this.linkedCampaigns = data;
      });
  }

  saveClip() {
    if ( this.clip.clipType === '' ) {
      this.clip.clipType = this.fixClipType(this.clip);
    };

    if(this.sourceCache != this.clip.source) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '350px',
        panelClass: '',
        data: {message: "Odkaz bude změněn ve všech kampaních, ve kterých se klip nachází.", action: "Změnit", header: "Pozor"}
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.clipsService.updateClip(this.clip).subscribe(result => {
            this.clipsService.updateClipsList();
            this.dialogRef.close();
          });
        }
      });

    } else {
      this.clipsService.updateClip(this.clip).subscribe(result => {
        this.clipsService.updateClipsList();
        this.dialogRef.close();
      });
    }
  }
  
  deleteClip(id: number) {
    if (!this.clip.isTranscoding) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '300px',
        panelClass: '',
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.clipsService.deleteClip(id).subscribe(response => {
            this.clipsService.updateClipsList();
            this.dialogRef.close();
          });
        }
      });
    } else {
      this.snackBar.open('Nelze smazat klip, který se zpracovává', '', {
        duration: 5000});
    }
  }

  openCampaignDetail(id) {
    this.campaignService.openCampaignDetail(id);
  }

  openNewCampaign() {
    const newCampaign = { videoclips: [] };
    newCampaign.videoclips.push(this.clip);
    this.campaignService.openNewCampaign(newCampaign as Campaign);
    this.dialogRef.close();
  }

  fixClipType(clip: ContentClip) {
    console.log(clip.mimeType);
    let type;
    switch (clip.mimeType) {
      case 'image/jpeg':
        type = 'image';
        break;
      case 'image/jpg':
        type = 'image';
        break;
      case 'image/png':
        type = 'image';
        break;
      case 'video/x-youtube':
        type = 'youtube';
        break;
      case 'video/x-youtube-live':
        type = 'live';
        break;
      case 'video/x-live':
        type = 'live';
        break;
      case 'application/x-mpegURL':
        type = 'live';
        break;
      case 'text/html':
        type = 'html';
        break;
      default:
        type = 'video';
    }
    return type;
  }
}
