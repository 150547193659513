<div class="dashboard">
    <div class="dashboard__top">
        <mat-card class="card card--large dashboard__devices card--soft-shadow" >
            <mat-card-title><mat-icon  color="primary">important_devices</mat-icon><span i18n>Zařízení</span></mat-card-title>
            <app-devices-list [template]="'tiles'"></app-devices-list>
        </mat-card>
    </div>
    <div class="dashboard__bottom">
        <div class="dashboard__states">
            <mat-card class="card card--large dashboard__state card--soft-shadow" >
                <mat-card-title><mat-icon color="primary" >star</mat-icon><span i18n>Aktuální stav</span></mat-card-title>
                <app-current-states></app-current-states>
            </mat-card>
        </div>
        <div class="dashboard__map">
            <mat-card class="card card--large dashboard__map card--soft-shadow" >
                <mat-card-title><mat-icon  color="primary">place</mat-icon><span i18n>Mapa zařízení</span></mat-card-title>
                <app-devices-map></app-devices-map>
            </mat-card>
        </div>
    </div>
</div>