<div class="schedule dialog">
    <div mat-dialog-title>
        <h2><span i18n>Harmonogram pro</span> {{ device.name }}</h2>
        <button class="dialog__close" mat-icon-button mat-dialog-close i18n-aria-lablel aria-label="Zavřít dialog">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <div  class="schedule__tabs dialog__content">
        <div class="schedule__date">
            <mat-form-field>
                <input matInput [matDatepicker]="scheduleDay" i18n-placeholder placeholder="Vyberte den"  [formControl]="day">
                <mat-datepicker-toggle matSuffix [for]="scheduleDay"></mat-datepicker-toggle>
                <mat-datepicker #scheduleDay></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="schedule__timeline">
            <app-timeline *ngIf="selectedIndex === 0 && !isLoadingResults; else loading" [repeatedPlaylist]="repeatedCampaigns" [fixedPlaylist]="fixedCampaigns" [deviceTime]="deviceTime"></app-timeline>
        </div>
        <!--<mat-tab-group animationDuration="0" *ngIf="!error; else errorTemplate"  (selectedIndexChange)="templateChange($event)">
            <mat-tab label="Časová osa"> 
                <ng-template matTabContent>
                </ng-template>
            </mat-tab>
             <mat-tab label="Playlist">
                <ng-template matTabContent>
                    <app-schedule-playlist *ngIf="selectedIndex === 1 && !isLoadingResults; else loading"  [repeatedPlaylist]="repeatedPlaylist" [fixedPlaylist]="fixedPlaylist" [deviceTime]="deviceTime" ></app-schedule-playlist>
                </ng-template>
            </mat-tab>
        </mat-tab-group> -->
    </div>
    <app-campaign-thumbnail *ngIf="activeCampaign"  [activeClip]="activeClip" [campaign]="activeCampaign" [properties]="preview" ></app-campaign-thumbnail>

</div>

<ng-template #errorTemplate i18n>
    Nepopdařilo se stáhnout plán pro dané zařízení
</ng-template>

 
<ng-template #loading>
    <app-loading></app-loading>
</ng-template>
  
  