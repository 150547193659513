import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from "@angular/core";
import { Campaign } from "src/app/models/campaign.model";
import { Playlist } from "src/app/models/playlist.model";
import { PlaylistService } from "src/app/playlist/playlist.service";

@Component({
  selector: "app-campaign-playlist",
  templateUrl: "./campaign-playlist.component.html",
  styleUrls: ["./campaign-playlist.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CampaignPlaylistComponent implements OnInit {
  @Input() campaign: Campaign;
  @Output() validated = new EventEmitter();
  @Output() playlistChanged = new EventEmitter();

  constructor(private playlistService: PlaylistService) {}

  ngOnInit(): void {
    if (this.campaign.playlistId) {
      this.validated.emit(true);
    }
  }
  updatePlaylist(playlist: Playlist) {
    
    if (playlist) {
      this.campaign.playlistId = playlist.id;
      this.playlistChanged.emit(playlist);
    } else {
      let message = "Playlist nemůže být prázdný";
      this.validated.emit({ status: "INVALID", message });
    }
  }
}
