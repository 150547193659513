import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog} from '@angular/material/dialog';


@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CampaignsComponent implements OnInit {

  constructor(public dialog: MatDialog) {
  }
  
  ngOnInit() {
  }

}
