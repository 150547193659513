<div class="clips-selection dialog">
    <div mat-dialog-title>
        <h2 i18n>Výběr klipů</h2>
        <button class="dialog__close" mat-icon-button mat-dialog-close i18n-aria-label aria-label="Zavřít dialog">
            <mat-icon>clear</mat-icon>
        </button>
    </div>

    <div class="dialog__content clips-selection__content" mat-dialog-content>
        <app-clips-list [selectedClips]="selectedClips" [type]="'checkable'" (selected)="updateSelection($event)"></app-clips-list>
    </div>

    <div mat-dialog-actions class="dialog__footer dialog__footer--reverse">
        <button mat-raised-button color="primary" (click)="saveSelect()"><span i18n>Vybrat</span><mat-icon color="white">check</mat-icon></button>
    </div>
</div>