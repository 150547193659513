
<div class="clips-list" *ngIf="clips" [ngClass]="{'clips-list--multiple-actions' : multipleActions && selection.selected.length>0}">
    <div class="clips-list__header">
        <div class="clips-list__filters">
            <div class="clips-list__filters__search">
                <mat-form-field class="clips-list__search mat-form-field--fullwidth">
                    <mat-label i18n>Hledej Klip</mat-label>
                    <input [ngModel]="this.filters.fulltext" (keyup)="filterByFulltext($event.target['value'])" matInput i18n-placeholder placeholder="Napiš slovo">
                    <mat-icon matSuffix>search</mat-icon>
                    <mat-hint i18n>Zobrazeno {{ clips.length }} klipů</mat-hint>
                </mat-form-field>
                <button  class="clips-list__filters__clear" *ngIf="hasFilters()" mat-button (click)="resetFilters()">Vymazat filtry</button>
                <app-tag-menu  [selectedTags]="getAllFiltersTags()" (selected)="filterByTags($event)" [tagType]="'clips'" class="clips-list__tag-menu" [selectable]="true" ></app-tag-menu>
            </div>
            <div class="clips-list__header__actions">
                <app-get-clip-menu [hideLibrary]="true"></app-get-clip-menu>
            </div>
        </div>
        <div *ngIf="multipleActions" class="clips-list__sorts">
            <mat-checkbox *ngIf="type==='checkable'" [(ngModel)]="selectedAll" (ngModelChange)="selectAll($event)" [color]="'primary'" i18n>Vybrat vše</mat-checkbox>
            <mat-checkbox *ngIf="multipleActions && selection.selected.length>0"  color="primary" (change)="selectAll($event.checked)" [indeterminate]="someSelected()"><span *ngIf="!allSelected" i18n>Vybrat všechny</span><span *ngIf="allSelected" i18n>Odebrat všechny</span></mat-checkbox>
        </div>
    </div>
    <div  *ngIf="multipleActions==false" class="clips-list__selected-list">
        <div *ngFor="let clip of selection.selected; let i = index" class="clips-list__item">
            <div *ngIf="isAdmin" class="clips-list__item__id" matTooltipPosition="above" [matTooltip]="clip.id.toString()">{{ clip.id }}</div>
            <app-clip-item [multipleActions]="multipleActions && selection.selected.length>0" [type]="type" [clip]="clip"  [isChecked]="selection.isSelected(clip)" (selected)="selectClip($event)" (selectedClone)="selectCloneClip($event)" [selectedIndex]="getSelectedIndex(clip)"></app-clip-item>
        </div>
    </div>
   
    <div class="clips-list__content">
        <div class="clips-list__wrapper">
            <div class="clips-list__list">
                <div *ngFor="let clip of clips; let i = index" class="clips-list__item">
                    <div *ngIf="isAdmin" class="clips-list__item__id" matTooltipPosition="above" [matTooltip]="clip.id.toString()">{{ clip.id }}</div>
                    <app-clip-item [multipleActions]="multipleActions && selection.selected.length>0" [type]="type" [clip]="clip"  [isChecked]="selection.isSelected(clip)" (selected)="selectClip($event)" (selectedClone)="selectCloneClip($event)"  [selectedIndex]="getSelectedIndex(clip)"></app-clip-item>
                </div>
            </div>
        </div>

        <div class="clips-list__actions-bar" *ngIf="multipleActions && selection.selected.length>0">
            <app-clips-multiple-actions [selection]="selection.selected" (changed)="multipleActionsChanged($event)"></app-clips-multiple-actions>
        </div>
        
    </div>
    <mat-paginator [length]="length"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        (page)="changePagination($event)"
        i18n-aria-label
        aria-label="Vyber stranu">
    </mat-paginator>
</div>

<div *ngIf="isLoadingResults" class="clips-list__loading">
    <app-loading></app-loading>
</div>


