export const testEnvironment = {
    production: false,
    serverProtocol: 'http',
    serverAddress: 'localhost',
    serverPort: '4201'
  };

export const environment = {
  production: true,
  serverProtocol: 'https',
  serverAddress: 'aws-market.ismedia.cz',
  serverPort: '443',
  cdnAddress: 'https://dev-market.iscdn.cz'
};