import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  SimpleChanges,
  OnChanges,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  ViewEncapsulation,
} from "@angular/core";

import { Campaign } from "../../models/campaign.model";
import { ContentClip } from "../../models/content-clip.model";
import { FormGroup, FormControl, FormBuilder } from "@angular/forms";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { UserService } from "src/app/user/user.service";
import { ClipsService } from "src/app/clips/clips.service";
import { AuthorizationService } from "src/app/services/authorization.service";
import { CompanyService } from "src/app/company/company.service";

@Component({
  selector: "app-campaign-content",
  templateUrl: "./campaign-content.component.html",
  styleUrls: ["./campaign-content.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CampaignContentComponent implements OnInit, OnChanges {
  @Input() campaign: Campaign;
  @Output() validated = new EventEmitter();

  isVideoOpened: boolean;
  allFullscreen: boolean;
  someFullscreen: boolean = false;

  public hasBaseDropZoneOver: boolean;
  public hasAnotherDropZoneOver: boolean;
  public dropzoneVisible: boolean;
  public hasRadio: boolean;
  contentFormGroup: FormGroup;

  constructor(
    public companyService: CompanyService,
    private fb: FormBuilder,
    private userService: UserService
  ) {
    this.isVideoOpened = false;
    this.hasBaseDropZoneOver = false;
    this.hasAnotherDropZoneOver = false;
    this.dropzoneVisible = false;
  }

  ngOnInit() {
    this.contentFormGroup = this.fb.group({
      fullscreen: new FormControl(this.campaign.fullscreen).enable({
        emitEvent: false,
      }),
      radioFade: new FormControl(this.campaign.radioFade),
    });

    this.contentFormGroup.valueChanges.subscribe((value) => {
      this.updateDataModel(value);
    });
    this.hasRadio = this.companyService.checkModule("hasRadio")
      ? this.companyService.checkModule("hasRadio")
      : false;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.checkValidity();
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  muteToggle(id: number, event: Event): void {
    const item = this.campaign.videoclips.find((x) => x.id === id);
    if (item) {
      item.mute = !item.mute;
    }
    event.preventDefault();
    event.stopPropagation();
  }
  removeClip(index: number, event?: Event): void {
    this.campaign.videoclips.splice(index, 1);
    this.updateCampaignDuration();
    this.checkValidity();
  }

  updateDataModel(value: any): void {
    this.campaign = Object.assign(this.campaign, value);
  }

  drop(event: CdkDragDrop<{ title: string; poster: string }[]>) {
    moveItemInArray(
      this.campaign.videoclips,
      event.previousIndex,
      event.currentIndex
    );
  }

  addNewClips(clips: ContentClip[]): void {
    clips.forEach((item) => {
      item.companyId = this.userService.activeCompanyId;
    });

    this.campaign.videoclips = [...this.campaign.videoclips, ...clips];

    this.updateCampaignDuration();
    this.checkValidity();
    this.updateFullscreen();
  }

  updateCampaignDuration(): void {
    let campaignDuration = 0;
    this.campaign.videoclips.forEach((clip: ContentClip) => {
      campaignDuration += clip.duration;
    });

    this.campaign.duration = campaignDuration;
  }
  addCheckedClips(clips: ContentClip[]): void {
    clips.forEach((newClip) => {
      let foundClip = this.campaign.videoclips.find(
        (clip) => clip.id === newClip.id
      );
      if (foundClip) {
        newClip.fullscreen = foundClip.fullscreen;
        newClip.duration = foundClip.duration;
        newClip.volume = foundClip.volume;
        newClip.mute = foundClip.mute;
      }
      newClip.companyId = this.userService.activeCompanyId;
    });
    this.campaign.videoclips = [];
    this.campaign.videoclips = clips;

    if (this.campaign.fullscreen) {
      this.setFullscreenToAll(true);
    }

    this.updateCampaignDuration();
    this.checkValidity();
    this.updateFullscreen();
  }
  checkValidity() {
    let message = null;
    if (this.campaign.videoclips.length > 0) {
      this.validated.emit({ status: "VALID" });
    } else {
      this.validated.emit({
        status: "INVALID",
        message: "Musíte vybrat alespoň jeden klip",
      });
    }
  }

  getSelectedClipsId(): number[] {
    return this.campaign.videoclips.map((item) => item.id);
  }

  setFullscreenToAll(checked: boolean): void {
    if (checked) {
      this.campaign.videoclips.forEach((clip) => (clip.fullscreen = true));
      this.allFullscreen = true;
      this.campaign.fullscreen = true;
    } else {
      this.campaign.videoclips.forEach((clip) => (clip.fullscreen = false));
      this.allFullscreen = false;
      this.campaign.fullscreen = false;
    }

    this.campaign.videoclips = JSON.parse(
      JSON.stringify(this.campaign.videoclips)
    );
  }
  // updating after clip inside campaign is changed
  updateClip(updatedClip: ContentClip) {
    this.updateFullscreen();
  }

  updateFullscreen() {
    const fullscreenCount = this.campaign.videoclips.filter(
      (contentClip) => contentClip.fullscreen
    ).length;

    if (fullscreenCount === this.campaign.videoclips.length) {
      this.contentFormGroup
        .get("fullscreen")!
        .setValue(true, { emitEvent: false });
      this.allFullscreen = true;
      this.someFullscreen = false;
    } else if (fullscreenCount === 0) {
      this.contentFormGroup
        .get("fullscreen")!
        .setValue(false, { emitEvent: false });
      this.allFullscreen = false;
      this.someFullscreen = false;
    } else {
      this.contentFormGroup
        .get("fullscreen")!
        .setValue(false, { emitEvent: false });
      this.allFullscreen = false;
      this.someFullscreen = true;
    }
  }
}
