<div class="campaign-time dialog">
    <div mat-dialog-title  class="dialog__title">
        <h2 i18n>Časy přehrávání kapaně</h2>
        <button class="dialog__close" mat-icon-button (click)="closeDialog()" i18n-aria-label aria-label="Zavřít dialog">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <div class="dialog__content" >
        <app-schedule-time [fixedCampaignOptions]="fixedCampaignOptions" (selected)="onSelect($event)"></app-schedule-time>
    </div>
    <div mat-dialog-actions class="dialog__footer dialog__footer--reverse">
        <button mat-raised-button color="primary"  (click)="saveTimes()"><span i18n>Uložit</span><mat-icon color="white">check</mat-icon></button>
    </div>
</div>
