import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Campaign } from "src/app/models/campaign.model";
import { CampaignsService } from "../campaigns.service";

@Component({
  selector: "app-campagins-selection",
  templateUrl: "./campagins-selection.component.html",
  styleUrls: ["./campagins-selection.component.scss"],
})
export class CampaginsSelectionComponent implements OnInit {
  checkedCampaignIds = [];
  onlyOne: boolean = true;
  selection: Campaign[];
  hasSalection = false;
  constructor(
    private campaignsService: CampaignsService,
    public dialogRef: MatDialogRef<CampaginsSelectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.checkedCampaignIds = this.data.selection;
  }

  changeAttachedCampaign(event) {
    this.selection = event;

    if (this.onlyOne) {
      let lastSelected = this.selection[ this.selection.length - 1];

      if( lastSelected.id == this.checkedCampaignIds[0] ) {
        return
      } else {
        this.selection = [lastSelected];
      }
      setTimeout(() => {
        this.saveSelect();
      }, 10);
    }
  }
  saveSelect() {
    this.campaignsService.checkedCampaigns.next(this.selection);
    this.dialogRef.close();
  }
}
