import { Component, OnInit, Input, ViewEncapsulation } from "@angular/core";
import { UserService } from "src/app/user/user.service";
import { CompanyService } from "../company.service";
import { Company } from "src/app/models/company.model";
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";

@Component({
  selector: "app-company-select",
  templateUrl: "./company-select.component.html",
  styleUrls: ["./company-select.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CompanySelectComponent implements OnInit {
  @Input() companyId: number | null = null;
  @Input() valid = false;

  companies?: Company[];
  companiesFiltered!: Observable<Company[]>;
  companySelectFormGroup: FormGroup;

  constructor(
    public userService: UserService,
    private companyService: CompanyService,
    private fb: FormBuilder
  ) {
    this.companySelectFormGroup = this.fb.group({
      selectedCompanyName: new FormControl(),
    });
  }

  ngOnInit() {
    this.companyService.fetchAllCompanies().subscribe({
      next: (companies) => {
        if (!companies) {
          // TODO no company fallback
          console.error("No companies were found");
          return;
        }
        
        this.companies = companies;
        let selectedCompany = this.companies.find((item) => {
          return item.companyId === this.companyId;
        });

        if (selectedCompany) {
          this.companySelectFormGroup.controls["selectedCompanyName"].setValue(
            selectedCompany.company
          );
        }

        this.companiesFiltered = this.companySelectFormGroup.controls[
          "selectedCompanyName"
        ].valueChanges.pipe(
          startWith(""),
          map((company) =>
            company ? this.filterCompanies(company) : this.companies!.slice()
          )
        );
      },
      error: (error) => {
        console.error("Fetching companies failed...");
        console.error(error);
      },
    });
  }

  private filterCompanies(value: string): Company[] {
    if (!value) {
      return this.companies!;
    }
    const filterValue = value.toString().toLowerCase();
    return this.companies!.filter((company) => {
      const shownName = company.shownName ? company.shownName : "";
      return (
        company.company.toLowerCase().indexOf(filterValue) === 0 ||
        shownName.toLowerCase().indexOf(filterValue) === 0 ||
        company.branch.toLowerCase().indexOf(filterValue) === 0
      );
    });
  }

  get selectedItem() {
    return this.companySelectFormGroup.get("selectedItem") as FormControl;
  }
  clearInput() {
    this.companySelectFormGroup.controls["selectedCompanyName"].setValue("");
  }

  selectCompany(id: number) {
    const company = this.companies?.find((company) => company.companyId === id);
    if (company) this.userService.activeCompany = company;
    location.replace("/");
  }
}
