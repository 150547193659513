import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { Tag } from "src/app/models/tag.model";
import { TagsService } from "../tags.service";
import { UserService } from "src/app/user/user.service";

@Component({
  selector: "app-tag-menu",
  templateUrl: "./tag-menu.component.html",
  styleUrls: ["./tag-menu.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TagMenuComponent implements OnInit, OnChanges {
  @Input() tags: Tag[] | null = null;
  @Input() selectedTags?: Tag[] = [];
  @Input() selectable: false;
  @Input() saveLocally?: false;
  @Input() tagType = "";
  @Output() selected = new EventEmitter();
  @Input() groupCount? = 0;

  noTag: string = $localize`Není přiřazen žádný tag`;
  assigned: string = $localize`Kliknutím zobrazíte přiřazené tagy`;
  isAdmin: boolean;

  constructor(
    private tagsService: TagsService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.isAdmin = this.userService.hasRole("admin");

    if (!this.tags) {
      this.fetchAllTags();
    } else {
      this.tags = this.removeDuplicatedTags(this.tags);
    }
  }
  ngOnChanges(changes: SimpleChanges) {
  
    if(changes['selectedTags']) {
      this.selectedTags = [];
      this.selectedTags = changes['selectedTags'].currentValue;
      if(this.tags) {
        this.tags.forEach((tag) => {
          tag.isSelected = changes['selectedTags'].currentValue.some(selectedTag => {return selectedTag.value == tag.value});
        });
      }
    }
  }

  fetchAllTags() {
    this.tagsService.fetchTagsByCompany(this.tagType).subscribe((data) => {
      this.tags = this.removeDuplicatedTags(data);
    });
  }

  removeDuplicatedTags(tags: Tag[]): Tag[] {
    let filteredArray: Tag[] = tags.filter((tag, pos) => {
      let index = tags.findIndex(
        (item) => item.value == tag.value && item.group == tag.group
      );
      return index == pos;
    });
    return filteredArray;
  }

  unselectTags() {
    this.selectedTags = [];
    this.tags.forEach((tag) => {
      tag.isSelected = false;
    });
    this.selected.emit(this.selectedTags);
  }

  selectTag(selectedTag: Tag) {

    const index = this.selectedTags.findIndex(
      (tag) =>
        tag.value === selectedTag.value && tag.group === selectedTag.group
    );

    if (index === -1) {
      this.selectedTags.push(selectedTag);
      selectedTag.isSelected = true;
    } else {
      this.selectedTags.splice(index, 1);
      selectedTag.isSelected = false;
    }
    
    this.selected.emit(this.selectedTags);
  }
  isSelected(tag: Tag) {
    return this.selectedTags.some((item) => tag == item);
  }
}
