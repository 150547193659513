import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthorizationService } from "src/app/services/authorization.service";

@Component({
  selector: "app-login-recovery",
  templateUrl: "./login-recovery.component.html",
  styleUrls: ["./login-recovery.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class LoginRecoveryComponent implements OnInit {
  isValid: boolean = true;
  loading: boolean;
  loginError: boolean = false;
  recoveryRequest: FormGroup = new FormGroup({
    email: new FormControl("", Validators.required),
  });
  emailSent = false;

  constructor(
    private authorizationService: AuthorizationService,
    public router: Router,
    private route: ActivatedRoute
  ) {
    this.loading = false;
  }

  ngOnInit(): void {}
  onSubmit() {
    if (this.recoveryRequest.valid) {
      this.loading = true;
      this.authorizationService
        .loginRecovery(this.recoveryRequest.get("email")?.value)
        .subscribe({
          next: (response: any) => {
            if (response) {
              if (response.code == 200) {
                this.emailSent = true;
              } else {
                this.loginError = true;
              }
              this.loading = false;
            }
          },
          error: () => {
            this.loading = false;
            this.loginError = true;
          },
        });
    } else {
      this.isValid = false;
    }
  }
}
