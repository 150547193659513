<form>
    <mat-form-field class="tags__form mat-mdc-form-field--fullwidth" appearance="fill">
      <mat-label *ngIf="isAdminTags" i18n>Administrační štítky</mat-label>
      <mat-label *ngIf="!isAdminTags" i18n>Štítky</mat-label>

      <mat-chip-grid #chipGrid i18n-aria-label aria-label="Výběr štítků">
        <mat-chip-row *ngFor="let tag of tags"  [removable]="removable" (removed)="remove(tag)">
            {{tag.value}}
          <button matChipRemove i18n-aria-label [attr.aria-label]="'Odebrat štítek ' + tag.value">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      </mat-chip-grid>
      <input i18n-placeholder placeholder="Nový štítek" #fruitInput [formControl]="tagsControl"
        [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)"/>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedAutocomplete($event)">
        <mat-option *ngFor="let tag of filteredTags | async" [value]="tag.value">
            {{tag.value}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
</form>