import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { User } from "../models/user.model";
import { RequestStatus } from "../models/request-status";
import { UserService } from "../user/user.service";

@Injectable({
  providedIn: "root",
})
export class AuthorizationService {
  private serverUrl: string = `${environment.serverProtocol}://${environment.serverAddress}:${environment.serverPort}`;
  headers = new HttpHeaders({
    "x-company-id": "none",
    "Content-Type": "application/json",
  });

  constructor(private http: HttpClient, private userService: UserService) {}
  
  public get ServerUrl(): string {
    return this.serverUrl;
  }

  public get accessTokenValue(): string | null {
    return this.userService.user!.accessToken;
  }

  login(login: any) {
    const url = `${this.serverUrl}/api2/user/login`;
    return this.http
      .post<User>(url, login, {headers: this.headers})
  }
 
  loginRecovery(login: string) {
    const httpOptions = {
      headers: this.headers,
    };
    const url = `${this.serverUrl}/api2/user/password-request`;
    return this.http
      .post<RequestStatus>(
        url,
        {
          login: login,
          resetFormUrl: "/password-change",
        },
        httpOptions
      )
  }
  changePassword(login: string, token: string, password: string) {
    const httpOptions = {
      headers: this.headers,
    };
    const url = `${this.serverUrl}/api2/user/password-reset`;
    return this.http
      .post<RequestStatus>(
        url,
        {
          login: login,
          token: token,
          password: password,
        },
        httpOptions
      )
  }

  logout() {
    localStorage.removeItem("currentUser");
    window.location.replace("/");
  }

  deleteAccount() {
    this.headers = this.headers.set(
      "Authorization",
      "Bearer " + this.accessTokenValue,
    );
    this.headers = this.headers.set(
      "x-company-id",
      this.userService.activeCompanyId + ""
    );

    const url = `${this.serverUrl}/api2/user`;
    return this.http
      .delete<User>(url,{headers:  this.headers})
  }

}
