import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  ViewEncapsulation,
} from "@angular/core";
import { Campaign, CampaingEndAction } from "../../models/campaign.model";
import { CampaignsService } from "../campaigns.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatStepper } from "@angular/material/stepper";
import { CampaignContentComponent } from "../campaign-content/campaign-content.component";

import * as dayjs from "dayjs";
import { Playlist } from "src/app/models/playlist.model";

@Component({
  selector: "app-campaign-stepper",
  templateUrl: "./campaign-stepper.component.html",
  styleUrls: ["./campaign-stepper.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CampaignStepperComponent implements OnInit {
  @ViewChild("stepper", { static: true }) private stepper: MatStepper;
  @ViewChild(CampaignContentComponent)
  campaignContentComponent: CampaignContentComponent;

  isLinear = true;
  campaign: Campaign;
  validation: boolean[] = [false, false, true, true, false, true, true];
  activeIndex = 0;
  totalStepsCount: number;
  saving = false;
  transcodingClip = false;
  stepsCounter = 0;
  selectedIndex = 0;
  currentPlaylist: Playlist = null;
  validationMesasge;

  constructor(
    private campaignsService: CampaignsService,
    public dialogRef: MatDialogRef<CampaignStepperComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.campaign = new Campaign();
    if (this.data.campaign) {
      this.campaign = Object.assign(this.campaign, this.data.campaign);
    }
  }

  ngOnInit() {}

  saveNewCampaign() {
    this.saving = true;
    this.campaign.edited = new Date().toISOString();
    this.campaignsService
      .saveNewCampaign(this.campaign)
      .subscribe((response) => {
        this.dialogRef.close();
        this.campaignsService.updateCampaignsList();
      });
  }
  isCompleted(validation, slideIndex?: number) {
    let isCompleted = validation.status === "VALID" ? true : false;

    if (slideIndex !== undefined) {
      this.validation[slideIndex] = isCompleted;
    }
    if (!isCompleted) {
      if (validation.message) {
        this.validationMesasge = validation.message;
        console.log(this.validationMesasge);
      }
    } else {
      this.validationMesasge = null;
    }
  }
  isCampaignValid() {
    return !this.validation.some((validation) => !validation);
  }
  onStepChange(event) {
    this.selectedIndex = event.selectedIndex;
  }
  playlistChanged(playlist) {
    this.currentPlaylist = playlist;
  }
  updateSelectedDevices(selectedDevices) {
    this.campaign.selectedDevices = selectedDevices;
    this.campaign = Object.assign({}, this.campaign);
  }
}
