import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { AuthorizationService } from "../services/authorization.service";
import { UserService } from "../user/user.service";
import { Observable, Subject } from "rxjs";
import { Playlist } from "../models/playlist.model";

@Injectable({
  providedIn: "root",
})
export class PlaylistService {
  public editPlaylistDialog = new Subject<Playlist>();

  private headers: HttpHeaders;
  serverUrl: string;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private authorizationService: AuthorizationService
  ) {
    this.serverUrl = `${environment.serverProtocol}://${environment.serverAddress}:${environment.serverPort}`;
    this.headers = new HttpHeaders();
    this.headers = this.headers.set(
      "Authorization",
      "Bearer " + this.authorizationService.accessTokenValue
    );
    this.headers = this.headers.set(
      "x-company-id",
      this.userService.activeCompanyId + ""
    );
  }

  fetchPlaylists(): Observable<Playlist[]> {
    const url = `${this.serverUrl}/api2/playlists`;
    return this.http.get<Playlist[]>(url, { headers: this.headers });
  }

  fetchPlaylist(playlistId: number): Observable<Playlist> {
    const url = `${this.serverUrl}/api2/playlist/${playlistId}`;
    return this.http.get<Playlist>(url, { headers: this.headers });
  }

  updatePlaylist(palylist: Playlist): any {
    const url = `${this.serverUrl}/api2/playlist`;
    return this.http.put<any>(url, palylist, { headers: this.headers });
  }
  saveNewPlaylist(palylist: Playlist): any {
    const url = `${this.serverUrl}/api2/playlist`;
    return this.http.post<any>(url, palylist, { headers: this.headers });
  }
  deletePlaylist(id: number): any {
    const url = `${this.serverUrl}/api2/playlist/${id}`;
    return this.http.delete<any>(url, { headers: this.headers });
  }
  openPlaylistEdit(playlist) {
    this.editPlaylistDialog.next(playlist);
  }
}
