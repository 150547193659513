<div class="campaign-stepper  dialog">
    <div mat-dialog-title class="dialog__title" >
        <h2  i18n>Nová kampaň <span *ngIf="campaign.name">- {{campaign.name}}</span> </h2> 
        <button class="dialog__close" mat-icon-button mat-dialog-close  i18n-aria-label aria-label="Zavřít dialog">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <mat-dialog-content class="dialog__content" >
        <mat-vertical-stepper  [linear]="isLinear" #stepper [@.disabled]="true" selectedIndex="0" (selectionChange)="onStepChange($event)">
            <mat-step [completed]="validation[0]">
                <ng-template matStepLabel>
                    <div class="mat-step-label-title" i18n>Název kampaně</div>
                </ng-template>
                <h2 i18n>Název kampaně</h2>
                <div class="campaign-stepper__content">
                    <app-campaign-name [campaign]="campaign" (validated)="isCompleted($event, 0)"></app-campaign-name>
                </div>
                <div class="dialog__footer dialog__footer--reverse">
                    <div  [matTooltipDisabled]="validation[0]" i18n-matTooltip matTooltip="Zadejte jméno kampaně" >
                        <button mat-raised-button color="primary"[disabled]="!validation[0]" matStepperNext><span i18n>Další</span><mat-icon color="white">chevron_right</mat-icon></button>
                    </div>
                </div>
            </mat-step>
            <mat-step [completed]="validation[1]">
                <ng-template matStepLabel>
                    <div class="mat-step-label-title" i18n>Typ kampaně</div>
                </ng-template>
                <h2 i18n>Typ kampaně</h2>
                <div class="campaign-stepper__content">
                    <app-campaign-type [campaign]="campaign"  (validated)="isCompleted($event, 1)"  *ngIf="selectedIndex ===1" ></app-campaign-type>
                </div>
                <div class="dialog__footer dialog__footer--reverse">
                    <div class="campaign-stepper__actions">
                        <button mat-button matStepperPrevious><mat-icon color="white">chevron_left</mat-icon><span i18n>Předchozí</span></button>
                        <button mat-raised-button color="primary" [disabled]="!validation[1]" matStepperNext><span i18n>Další</span><mat-icon color="white">chevron_right</mat-icon></button>
                    </div>
                </div>
            </mat-step>
            <mat-step *ngIf="campaign.type==='playlist'">
                <ng-template matStepLabel>
                    <div class="mat-step-label-title" i18n>Playlist</div>
                </ng-template>
                <h2 i18n>Playlist kampaně</h2>
                <div class="campaign-stepper__content">
                    <app-campaign-playlist [campaign]="campaign"  *ngIf="selectedIndex === 2" (palylistChanged)="playlistChanged($event)" ></app-campaign-playlist>
                </div>
                <div class="dialog__footer dialog__footer--reverse">
                    <div class="campaign-stepper__actions">
                        <button mat-button matStepperPrevious><mat-icon color="white">chevron_left</mat-icon><span i18n>Předchozí</span></button>
                        <button mat-raised-button color="primary"  matStepperNext><span i18n>Další</span><mat-icon color="white">chevron_right</mat-icon></button>
                    </div>
                </div>
            </mat-step>
            <mat-step >
                <ng-template matStepLabel>
                    <div class="mat-step-label-title" i18n>Časové rozmezí</div>
                </ng-template>
                <h2 i18n>Časové rozmezí</h2>
                <div class="campaign-stepper__content">
                    <app-campaign-date [campaign]="campaign" (validated)="isCompleted($event, 3)"></app-campaign-date>
                </div>
                <div class="dialog__footer dialog__footer--reverse">
                    <div class="campaign-stepper__actions">
                        <button mat-button matStepperPrevious><mat-icon color="white">chevron_left</mat-icon><span i18n>Předchozí</span></button>
                        <span [matTooltipDisabled]="validation[3]" matTooltip="Zadejte datum a čas začátku kampaně">
                            <button mat-raised-button color="primary"  [disabled]="!validation[3]" matStepperNext><span i18n>Další</span><mat-icon color="white">chevron_right</mat-icon></button>
                        </span>
                    </div>
                </div>
            </mat-step>
            <mat-step [completed]="validation[4]">
                <ng-template matStepLabel>
                    <div class="mat-step-label-title" i18n>Videa a obsah</div>
                </ng-template>
                <h2 i18n>Videa a obsah</h2>
                <div class="campaign-stepper__content">
                    <app-campaign-content [campaign]="campaign" (validated)="isCompleted($event, 4)"></app-campaign-content>
                </div>
                <div class="dialog__footer dialog__footer--reverse">
                    <div class="campaign-stepper__actions">
                        <button mat-button matStepperPrevious><mat-icon color="white">chevron_left</mat-icon><span i18n>Předchozí</span></button>
                        <span [matTooltipDisabled]="validation[4]" i18n-matTooltip matTooltip="Přidejte alespoň jeden klip">
                            <button mat-raised-button color="primary"  [disabled]="!validation[4]" matStepperNext><span i18n>Další</span><mat-icon color="white">chevron_right</mat-icon></button>
                        </span>
                    </div>
                </div>
            </mat-step>
            <mat-step >
                <ng-template matStepLabel>
                    <div class="mat-step-label-title" i18n>Zařízení</div>
                </ng-template>
                <h2 i18n>Zařízení</h2>
                <div class="campaign-stepper__content">
                    <app-campaign-devices [campaign]="campaign" (selected)="updateSelectedDevices($event)"></app-campaign-devices>
                </div>
                <div class="dialog__footer dialog__footer--reverse">
                    <div class="campaign-stepper__actions">
                        <button mat-button matStepperPrevious><mat-icon color="white">chevron_left</mat-icon><span i18n>Předchozí</span></button>
                        <span [matTooltipDisabled]="validation[5]" i18n-matTooltip matTooltip="Připojte zařízení, na kterých se má kampaň přehrávat" >
                            <button mat-raised-button color="primary"  matStepperNext><span i18n>Další</span><mat-icon color="white">chevron_right</mat-icon></button>
                        </span>
                    </div>
                </div>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>
                    <div class="mat-step-label-title" i18n>Rekapitulace</div>
                </ng-template>
                <h2 i18n>Rekapitulace - {{campaign.name}}</h2>
                <div class="campaign-stepper__content">
                    <app-campaign-recapitulation [campaign]="campaign" *ngIf="isCampaignValid()"></app-campaign-recapitulation>
                </div>
                <div class="dialog__footer dialog__footer--reverse">
                    <div class="campaign-stepper__actions">
                        <button mat-button matStepperPrevious><mat-icon color="white">chevron_left</mat-icon><span i18n>Předchozí</span></button>
                        <button mat-raised-button color="primary" [disabled]="saving" matStepperNext (click)="saveNewCampaign()"><span i18n>Uložit kampaň</span><mat-icon color="white">check</mat-icon></button>
                    </div>
                </div>
            </mat-step>
        </mat-vertical-stepper>
    </mat-dialog-content>
</div>