export class TimelineCampaign {
  color: string = "";
  companyId: number = 0;
  duration: number = 0;
  name: string = "";
  isTemporarilyInactive: boolean = false;
  videoclips: TimelineClip[] = [];
  id: number = 0;
  fixed: number = 0;
  startTime: number = 0;
  repeatFixedCampaign: boolean = false;
  fixedRepeatDuration: number;
  campaignName: string;
  startTimeFormated: string;
}

export class TimelineClip {
  duration: number = 0;
  id: number = 0;
  name: string = "";
  preview: any = {};
  source: string = "";
  description: string = "";
}
