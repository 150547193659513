import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ScheduleTimesOptions } from 'src/app/models/schedule.model';

@Component({
selector: 'app-schedule-time',
templateUrl: './schedule-time.component.html',
styleUrls: ['./schedule-time.component.scss'],
encapsulation: ViewEncapsulation.None
})
export class ScheduleTimeComponent implements OnInit {

    constructor() { }
    @Input() fixedCampaignOptions?:ScheduleTimesOptions = new ScheduleTimesOptions();
    @Output() public selected = new EventEmitter();

    stepInMinutes = 5;
    visibleHours = [];
    wholeDay = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23];
    shortDay = [8,9,10,11,12,13,14,15,16,17,18];
    steps: string[] = [];
    fullDay: boolean = false;
    hoveredStep: "0";

    ngOnInit(): void {
        this.calculateStep();
        this.visibleHours = this.shortDay;
    }

    calculateStep() {
        this.steps = [];
        let currentStep = 0;
        while (currentStep < 60) {
            this.steps.push(String(currentStep).padStart(2, '0'));
            currentStep += this.stepInMinutes;
        }
    }
    onChange(event) {
    }
    setFinalTime(hour, minute) {
        return  String(hour).padStart(2, '0') + ":"+ String(minute).padStart(2, '0');
    }
    selectTime(hour, minute, overwrite?:boolean, selectAll?:boolean) {
        let time = this.setFinalTime(hour, minute);
        overwrite = overwrite == undefined ? false : overwrite;
        selectAll = selectAll == undefined ? true : selectAll;

        if(!overwrite) {
            if (!this.fixedCampaignOptions.times.some(selectedTime => time == selectedTime )) {
                this.fixedCampaignOptions.times.push(time);
            } else {
                this.fixedCampaignOptions.times.splice( this.fixedCampaignOptions.times.indexOf(time), 1)
            }
        } else {

            if (!this.fixedCampaignOptions.times.some(selectedTime => time == selectedTime )) {
                if(selectAll) {
                    this.fixedCampaignOptions.times.push(time);
                }
            } else {
                if(!selectAll) {
                    this.fixedCampaignOptions.times.splice( this.fixedCampaignOptions.times.indexOf(time), 1)
                }
            }
        }


        this.selected.emit(this.fixedCampaignOptions);
    }
    filledSteps(hour, minute, step) {
        return [];
    }
    isSelected(hour, minute) {
        let time = this.setFinalTime(hour, minute);
        if(this.fixedCampaignOptions.times) {
            if (!this.fixedCampaignOptions.times.some(selectedTime => time == selectedTime )) {
            return false
            } else {
                return true
            }
        }
        return false
    }


    changeStep(event) {
        this.fixedCampaignOptions.times = [];
        this.stepInMinutes = event.value
        this.calculateStep();
    }
    changeRepeat(event) {
        console.log(event);
        this.stepInMinutes = 5;
        this.calculateStep();
    }
    changeType(event) {
        this.fullDay = event.checked
        if(this.fullDay) {
            this.visibleHours = this.wholeDay;
        } else {
            this.visibleHours = this.shortDay;
        }
    }
    selectColumn(hour) {
        let minutePreflight = 0;
        let minute = 0;
        let selectedItemsCount = 0;
        let selectAll = false;
        let allItemsCount = 60 / this.stepInMinutes;
        
        while (minutePreflight < 60) {
            if(this.isSelected(hour, String(minutePreflight).padStart(2, '0'))) {
                selectedItemsCount++;
            }
            minutePreflight+=this.stepInMinutes;
        }

        if (selectedItemsCount / allItemsCount < 0.5) {
            selectAll = true;
        }

        while (minute < 60) {
            this.selectTime(hour, String(minute).padStart(2, '0'), true, selectAll)
            minute+=this.stepInMinutes;
        }
    }

    selectRow(step) {

        let selectedItemsCount = 0;
        let selectAll = false;
        let allItemsCount = this.fullDay ?   this.wholeDay.length : this.shortDay.length;
     
        this.visibleHours.forEach(hour => {

            if(this.isSelected(hour, step)) {
                selectedItemsCount++;
            }
        })

        if (selectedItemsCount / allItemsCount < 0.5) {
            selectAll = true;
        }

        this.visibleHours.forEach(hour => {
            
            this.selectTime(hour, step, true, selectAll)
        })
    }
    overRow(step) {
        this.hoveredStep = step;
    }
}
