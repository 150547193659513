import { Component, ViewEncapsulation } from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthorizationService } from "src/app/services/authorization.service";
import { PassMismatchValidator } from "src/app/shared/pass-mismatch.directive";

@Component({
  selector: "app-password-change",
  templateUrl: "./password-change.component.html",
  styleUrls: ["./password-change.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PasswordChangeComponent {
  isValid: boolean = true;
  loading: boolean;
  loginError: boolean;
  passwordForms: FormGroup = new FormGroup(
    {
      newPassword: new FormControl('', {
        validators: [Validators.required, Validators.minLength(8)],
        updateOn: 'change',
      }),
      confirmPassword: new FormControl('', {
        validators: [Validators.required, PassMismatchValidator('newPassword')],
        updateOn: 'change',
      }),
    },
    { validators: this.checkPasswords }
  );
  returnUrl: string;
  succesfullyChanged = false;
  login: string;
  token: string;
  invalidToken = false;
  errorCode: number | null = null;
  formError = false;

  constructor(
    private route: ActivatedRoute,
    private authorizationService: AuthorizationService,
    public router: Router
  ) {
    this.loading = false;
  }

  checkPasswords(group: FormGroup) {
    const pass = group.controls.newPassword.value;
    const confirmPass = group.controls.confirmPassword.value;

    return pass === confirmPass ? null : { notSame: true };
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe((params: any) => {
      this.login = params.params.login;
      this.token = params.params.token;
    });
  }
  
  get f() {
    return this.passwordForms.controls;
  }

  onSubmit() {
    if (!this.passwordForms.valid) {
      this.passwordForms
        .get('newPassword')!
        .setErrors({ mismatchedPasswords: true });
      this.passwordForms
        .get('confirmPassword')!
        .setErrors({ mismatchedPasswords: true });
      this.isValid = false;
      return;
    }

    this.isValid = true;
    this.loading = true;
    this.authorizationService
      .changePassword(
        this.login,
        this.token,
        this.passwordForms.get('newPassword')!.value
      )
      .subscribe({
        next: (response: any) => {
          if (response) {
            if (response.code == 200) {
              this.succesfullyChanged = true;
              this.redirectToLogin(6000);
            } else {
              this.loginError = true;
            }
            this.loading = false;
          }
        },
        error: (error) => {
          console.log(error);
          if (error.status == 401) {
            this.invalidToken = true;
            this.redirectToLogin(6000);
          }
          this.loading = false;
          this.loginError = true;
        },
      });
  }
  redirectToLogin(delay: number) {
    setTimeout(() => {
      this.router.navigate(['/login']);
    }, delay);
  }
}
