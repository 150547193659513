import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";
import { Playlist } from "src/app/models/playlist.model";
import { of as observableOf } from "rxjs";
import { catchError } from "rxjs/operators";
import { PlaylistService } from "../playlist.service";
import { SortableOptions } from "sortablejs";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { PlaylistEditComponent } from "../playlist-edit/playlist-edit.component";
import { CampaignsService } from "src/app/campaigns/campaigns.service";
import { Campaign } from "src/app/models/campaign.model";

@Component({
  selector: "app-playlist-detail",
  templateUrl: "./playlist-detail.component.html",
  styleUrls: ["./playlist-detail.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PlaylistDetailComponent implements OnChanges {
  @Input() playlistId?: number;
  @Input() campaign?: Campaign;
  @Input() template? = "edit";
  @Output() playlistChanged = new EventEmitter();
  options: SortableOptions;
  isLoadingResults: boolean = true;
  playlist: Playlist;

  constructor(
    private playlistService: PlaylistService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PlaylistEditComponent>,
    private campaignService: CampaignsService
  ) {
    this.options = {
      onSort: (event: any) => {
        this.playlistChanged.emit(this.playlist);
      },
    };
  }

  playlistValueChanged() {
    this.playlist = Object.assign({}, this.playlist);
    this.playlistChanged.emit(this.playlist);
  }

  ngOnChanges(simpleCanges: SimpleChanges) {
    if (simpleCanges['playlistId']) {
      this.playlistId = simpleCanges['playlistId'].currentValue;
      if (this.playlistId) {
        this.fetchPlaylist();
      } else {
        this.createPlaylist();
      }
    }
    if (simpleCanges['campaign']) {
      this.campaign = simpleCanges['campaign'].currentValue;
    }
  }

  createPlaylist() {
    this.playlist = new Playlist();
    this.playlist.weight = 50;
    this.playlist.campaigns = [];
  }

  fetchPlaylist() {
    this.playlist = null;
    this.isLoadingResults = true;

    this.playlistService
      .fetchPlaylist(this.playlistId)
      .pipe(
        catchError(() => {
          this.isLoadingResults = false;
          console.log("Error with downloading Playlists");
          return observableOf([]);
        })
      )
      .subscribe((playlist: Playlist) => {
        this.playlist = playlist;
        this.addCurrentCampaign();
        this.isLoadingResults = false;
      });
  }
  addCurrentCampaign() {
    if (this.campaign) {
      if( !this.playlist.campaigns.some(campaign => { return campaign.id === this.campaign.id})) {
        this.playlist.campaigns.push(this.campaignService.convertCampaignToCampaignPreview(this.campaign));
      }
    }
  }
  onSelectedNewPlaylist(playlist: Playlist) {
    this.playlistId = playlist.id;
    this.playlist = playlist;
    this.playlistChanged.emit(this.playlist);
  }
  openPlaylistEdit() {
    const dialogRef = this.dialog.open(PlaylistEditComponent, {
      disableClose: true,
      width: "500px",
      maxHeight: "100vh",
      maxWidth: "95vw",
      data: { playlist: this.playlist },
    });

    dialogRef.afterClosed().subscribe((playlist: Playlist) => {
      if (playlist) {
        if (playlist.id) {
          this.playlistId = playlist.id;
          this.playlist.name = playlist.name;
          this.playlistValueChanged();
        }
        if (playlist == null) {
          this.playlistChanged.emit(null);
        }
      } else {
        // playlist deleted
        this.campaignService.removePlaylist();
      }
    });
  }
}
