import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http'
import { environment } from '../../environments/environment';
import { Schedule } from '../models/schedule.model';

import { BehaviorSubject, Subject, throwError  } from 'rxjs';
import { DevicePreview } from '../models/device-preview.model';

import { catchError } from 'rxjs/operators';
import { AuthorizationService } from '../services/authorization.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {
  private headers: HttpHeaders;

  public openScheduleSubject = new Subject<DevicePreview>();
  public scheduleReady = new Subject<any>();

  public toggleCampaignPreview: BehaviorSubject<any> = new BehaviorSubject(null);
  public updateScheduleData = new Subject<any>();

  serverUrl: string;
  
  constructor(private http: HttpClient, private userService: UserService, private authorizationService: AuthorizationService ) {
    this.serverUrl  = `${environment.serverProtocol}://${environment.serverAddress}:${environment.serverPort}`;
    this.headers = new HttpHeaders();
    this.headers = this.headers.set('Authorization', 'Bearer ' +  this.authorizationService.accessTokenValue);
    this.headers = this.headers.set('x-company-id', this.userService.activeCompanyId + '');
   
  }

  fetchScheduleData(deviceId: number, day: string) {
    const url = `${this.serverUrl}/api2/device/${deviceId}/loop?day=${day}`;
    // now returns an Observable of Config
    return this.http.get<Schedule>(url, { headers: this.headers }).pipe(
      catchError(this.handleError)
    );
  }


  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };
  public openSchedule(device: DevicePreview) {
    this.openScheduleSubject.next(device);
  }
  public updateSchedule() {
    this.updateScheduleData.next(true);
  }
  public openCampaingPreview(data: any) {
    if (data) {
      this.toggleCampaignPreview.next(data);
    }
  }
  public closeCampaignPreview() {
    this.toggleCampaignPreview.next(null);
  }
}
