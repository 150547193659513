import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { CampaignsService } from "../campaigns/campaigns.service";
import { DevicesService } from "../devices/devices.service";
import { ClipsService } from "../clips/clips.service";
import { Subscription } from "rxjs";
import { MatDialog } from "@angular/material/dialog";

import { CampaignStepperComponent } from "../campaigns/campaign-stepper/campaign-stepper.component";
import { CampaignDetailComponent } from "../campaigns/campaign-detail/campaign-detail.component";
import { DeviceDetailComponent } from "../devices/device-detail/device-detail.component";

import { ClipUploadComponent } from "../clips/clip-upload/clip-upload.component";
import { ClipDetailComponent } from "../clips/clip-detail/clip-detail.component";
import { ClipsSelectionComponent } from "../clips/clips-selection/clips-selection.component";

import { ContentClip } from "../models/content-clip.model";
import { CompanyService } from "../company/company.service";
import { UserService } from "../user/user.service";
import { CompanySelectDialogComponent } from "../company/company-select-dialog/company-select-dialog.component";
import { DeviceAuthorizationComponent } from "../devices/device-authorization/device-authorization.component";
import { ScheduleComponent } from "../schedule/schedule.component";
import { ScheduleService } from "../schedule/schedule.service";
import { DevicePreview } from "../models/device-preview.model";
import { PlaylistService } from "../playlist/playlist.service";
import { PlaylistEditComponent } from "../playlist/playlist-edit/playlist-edit.component";
import { CampaginsSelectionComponent } from "../campaigns/campagins-selection/campagins-selection.component";
import { HomepageService } from "./homepage.service";
import { MatSidenav } from "@angular/material/sidenav";
import { Playlist } from "../models/playlist.model";

@Component({
  selector: "app-homepage",
  templateUrl: "./homepage.component.html",
  styleUrls: ["./homepage.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HomepageComponent implements OnInit, OnDestroy {
  role: string[];
  isNavOpen: boolean = true;
  @ViewChild("sidenav") sidenav: MatSidenav;

  newCampaginSubscription: Subscription;
  campaignDetailSubscription: Subscription;
  deviceDetailSubscription: Subscription;
  deviceAuthorizationSubscription: Subscription;
  clipUploadSubscription: Subscription;
  clipDetailSubscription: Subscription;
  clipsSelectionSubscription: Subscription;
  scheduleSubscription: Subscription;
  newPlaylistSubscription: Subscription;
  campaignsSelectionSubscription: Subscription;

  constructor(
    public dialog: MatDialog,
    private campaignsService: CampaignsService,
    private devicesService: DevicesService,
    private clipsService: ClipsService,
    public companyService: CompanyService,
    public userService: UserService,
    private scheduleService: ScheduleService,
    private playlistService: PlaylistService,
    private homepageService: HomepageService
  ) {
    this.homepageService.isNavOpenSubject.subscribe((value) => {
      this.isNavOpen = value;
    });

    // subscriptions
    this.newCampaginSubscription =
      this.campaignsService.campaignDetailDialog.subscribe((campaignId) => {
        this.openCampaignDetail(campaignId);
      });
    this.campaignDetailSubscription =
      this.campaignsService.newCampaignDialog.subscribe((newCampaign) => {
        this.openNewCampaign(newCampaign);
      });
    this.deviceDetailSubscription =
      this.devicesService.deviceDetailDialog.subscribe((deviceId) => {
        this.openDeviceDetail(deviceId);
      });

    this.deviceAuthorizationSubscription =
      this.devicesService.deviceAuthorizationDialog.subscribe(() => {
        this.openDeviceAuthorization();
      });

    this.clipUploadSubscription = this.clipsService.clipUploadDialog.subscribe(
      (type) => {
        this.openClipUpload(type);
      }
    );

    this.clipDetailSubscription = this.clipsService.clipDetailDialog.subscribe(
      (clip) => {
        this.openClipDetail(clip);
      }
    );

    this.clipsSelectionSubscription =
      this.clipsService.clipSelectionDialog.subscribe((selection) => {
        this.openClipSelection(selection);
      });

    this.scheduleSubscription =
      this.scheduleService.openScheduleSubject.subscribe(
        (device: DevicePreview) => {
          if (device) {
            this.openTimeline(device);
          }
        }
      );
    this.newPlaylistSubscription =
      this.playlistService.editPlaylistDialog.subscribe(
        (playlist: Playlist) => {
          this.openNewPlaylist(playlist);
        }
      );

    this.campaignsSelectionSubscription =
      this.campaignsService.campaignsSelectionDialog.subscribe((selected?: number[]) => {
        this.openCampaignSelection(selected);
      });
  }

  ngOnInit() {
    if (!this.userService.activeCompany) {
      this.openCompanySelect();
    }
  }

  ngOnDestroy() {
    this.newCampaginSubscription.unsubscribe();
    this.campaignDetailSubscription.unsubscribe();
    this.clipUploadSubscription.unsubscribe();
    this.deviceDetailSubscription.unsubscribe();
    this.deviceAuthorizationSubscription.unsubscribe();
    this.clipDetailSubscription.unsubscribe();
    this.clipsSelectionSubscription.unsubscribe();
    this.scheduleSubscription.unsubscribe();
    this.newPlaylistSubscription.unsubscribe();
    this.campaignsSelectionSubscription.unsubscribe();
  }

  toggleNav() {
    this.isNavOpen = !this.isNavOpen;
  }
  openNewCampaign(campaign) {
    this.dialog.open(CampaignStepperComponent, {
      disableClose: true,
      width: "1600px",
      maxHeight: "100vh",
      maxWidth: "95vw",
      data: { campaign },
    });
  }
  openCampaignDetail(campaignId: number) {
    this.dialog.open(CampaignDetailComponent, {
      disableClose: true,
      width: "1600px",
      maxHeight: "100vh",
      maxWidth: "95vw",
      panelClass: "campaigns__detail__dialog",
      data: { campaignId },
    });
  }
  openDeviceDetail(deviceId: number) {
    this.dialog.open(DeviceDetailComponent, {
      disableClose: true,
      width: "1200px",
      maxHeight: "100vh",
      maxWidth: "95vw",
      panelClass: "campaigns__detail__dialog",
      data: { deviceId },
    });
  }
  openDeviceAuthorization() {
    this.dialog.open(DeviceAuthorizationComponent, {
      disableClose: true,
      width: "1200px",
      maxHeight: "100vh",
      maxWidth: "95vw",
      panelClass: "campaigns__detail__dialog",
      data: {},
    });
  }
  openClipUpload(type: string) {
    this.dialog.open(ClipUploadComponent, {
      disableClose: true,
      width: "1200px",
      maxHeight: "100vh",
      maxWidth: "95vw",
      panelClass: "",
      data: { type },
    });
  }
  openClipDetail(clip: ContentClip) {
    this.dialog.open(ClipDetailComponent, {
      disableClose: true,
      width: "1200px",
      maxHeight: "100vh",
      maxWidth: "95vw",
      panelClass: "",
      data: { clip },
    });
  }
  openClipSelection(selection: number[]) {
    this.dialog.open(ClipsSelectionComponent, {
      disableClose: true,
      width: "1200px",
      maxHeight: "100vh",
      maxWidth: "95vw",
      panelClass: "clips-selection__dialog",
      data: { selection },
    });
  }

  openCompanySelect() {
    const dialogRef = this.dialog.open(CompanySelectDialogComponent, {
      disableClose: true,
      width: "400px",
      minHeight: "300px",
      maxWidth: "95vw",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log(result);
      }
    });
  }

  openTimeline(device: DevicePreview) {
    this.dialog.open(ScheduleComponent, {
      disableClose: true,
      width: "95vw",
      maxWidth: "95vw",
      maxHeight: "100vh",
      minHeight: "400px",
      panelClass: "",
      data: { device },
    });
  }

  openNewPlaylist(playlist: Playlist) {
    const dialogRef = this.dialog.open(PlaylistEditComponent, {
      disableClose: true,
      width: "500px",
      maxHeight: "100vh",
      maxWidth: "95vw",
      data: { playlist },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log(result);
      }
    });
  }
  openCampaignSelection(selected?: number[]) {
    const dialogRef = this.dialog.open(CampaginsSelectionComponent, {
      disableClose: true,
      width: "900px",
      maxHeight: "100vh",
      maxWidth: "95vw",
      data: {selection: selected},
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log(result);
      }
    });
  }
}
