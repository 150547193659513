<button *ngIf="openButton==='button'" mat-raised-button color="primary" [matMenuTriggerFor]="menu" i18n-aria-label aria-label="Přidat nový obsah">
    <mat-icon color="white" >add</mat-icon>
    <span i18n>přidat nový obsah</span>
</button>

<button *ngIf="openButton==='plus'"mat-fab [matMenuTriggerFor]="menu" color="primary" i18n-aria-label aria-label="Přidat nový obsah">
    <mat-icon color="white" >add</mat-icon>
</button>

<mat-menu #menu="matMenu">
    <button  *ngIf="!hideLibrary" mat-menu-item  (click)="openSelectionDialog()">
        <mat-icon>cloud_download</mat-icon>
        <span i18n>Vložit z knihovny</span>
    </button>
    <button mat-menu-item (click)="openUploadDialog()">
        <mat-icon>attach_file</mat-icon>
        <span i18n>Nahrát video/obrázek</span>
    </button>
    <button mat-menu-item (click)="openUploadDialog('external')">
        <mat-icon>link</mat-icon>
        <span i18n>Vytvořit z odkazu</span>
    </button>
    <button mat-menu-item (click)="openUploadDialog('live')">
        <mat-icon>camera_front</mat-icon>
        <span i18n>Vytvořit live video</span>
    </button>
</mat-menu>

