<div class="clip-detail dialog">
    <div mat-dialog-title>
        <h2 i18n>Detail klipu</h2>
        <button class="dialog__close" mat-icon-button mat-dialog-close aria-label="Close dialog">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <div mat-dialog-content class="dialog__content">
        <div class="clip-detail__container">
            <app-clip-detail-forms [clip]="clip"></app-clip-detail-forms>
            <div  class="clip-detail__preview">
                <mat-form-field class="mat-form-field--fullwidth" *ngIf="clip.clipType === 'live' || clip.clipType === 'youtube' || clip.clipType === 'html'" >
                    <mat-label i18n>Odkaz</mat-label>
                    <input  matInput i18n-placeholder placeholder="Odkaz" [formControl]="source" >
                </mat-form-field>
                <div class="clip-detail__player">
                    <app-clip-player [clip]="clip" class="clips__player"></app-clip-player>
                </div>
                <div class="clip-detail__campaigns" >
                    <div class="clip-detail__campaign-title" >
                        <h3 i18n>V kampani</h3> 
                        <button  mat-icon-button i18n-matTooltip matTooltip="Vytvoříte novou kampaň s tímto klipem" (click)="openNewCampaign()"><mat-icon color="primary">add</mat-icon></button>
                    </div>
                    <div *ngIf="clip.inCampaigns.length > 0; else noCampaign">
                        <mat-chip-list *ngIf="linkedCampaigns.length > 0; else loading">
                            <mat-chip  *ngFor="let campaign of linkedCampaigns" (click)="openCampaignDetail(campaign.id)">{{campaign.name}}</mat-chip>
                        </mat-chip-list>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div mat-dialog-actions class="dialog__footer dialog__footer--reverse">
        <button mat-raised-button color="primary"  (click)="saveClip()"><span i18n>Uložit</span><mat-icon color="white">check</mat-icon></button>
        <div i18n-matTooltip matTooltip="Nelze smazat klip obsažený v kampani">
            <button matSuffix mat-raised-button color="primary" [disabled]="clip.inCampaigns.length > 0" (click)="deleteClip(clip.id)"><span i18n>Smazat klip</span><mat-icon color="white">delete</mat-icon></button>
        </div>
    </div>
</div>


<ng-template #loading>
    <app-loading></app-loading>
</ng-template>

<ng-template #noCampaign>
    <div class="clips__upload__no-campaign" i18n>
        Klip se nepřehává v žádné kampani.
    </div>
</ng-template>