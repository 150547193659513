<form class="device-authorization dialog" [formGroup]="deviceAuthFormGroup">
    <div mat-dialog-title>
        <h2 i18n>Přidat nové zařízení</h2>
        <button class="dialog__close" mat-icon-button mat-dialog-close i18n-aria-label aria-label="Zavřít dialog">
            <mat-icon>clear</mat-icon>
        </button>
    </div>

    <div mat-dialog-content class="device-authorization__container dialog__content" *ngIf="!isLoadingResults; else loading">
        <div *ngIf="!error" class="device-authorization__forms">
            <div class="flex gap-5">
                <mat-form-field class="mat-mdc-form-field--fullwidth" >
                    <input  matInput i18n-placeholder placeholder="Název zařízení"  formControlName="name">
                </mat-form-field>
                <mat-form-field class="u__form--400" >
                  <input  matInput i18n-placeholder placeholder="Pobočka"  formControlName="branch">
              </mat-form-field>
            </div>
            <div>
                <mat-form-field class="mat-mdc-form-field--fullwidth" >
                    <input  matInput i18n-placeholder placeholder="Kód zařízení XXXXXXXX" required  formControlName="code">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="mat-mdc-form-field--fullwidth" >
                    <input  matInput i18n-placeholder placeholder="Výchozí layout"  formControlName="layout">
                </mat-form-field>
            </div>
        </div>
        <div *ngIf="error" class="device-authorization__error">
            <h3 [matTooltip]="error.message" i18n>Zařízení se nepodařilo registrovat</h3>
        </div>
    </div>  
    <div mat-dialog-actions class="dialog__footer dialog__footer--reverse">
        <button *ngIf="!error" mat-raised-button (click)="saveDevice()" color="primary"><span i18n>Uložit</span><mat-icon color="white">check</mat-icon></button>
        <button *ngIf="error" mat-raised-button (click)="reset()" color="primary"><span i18n>Zkusit znovu</span><mat-icon color="white">check</mat-icon></button>
    </div>
</form>

<ng-template #loading>
    <app-loading></app-loading>
</ng-template>
