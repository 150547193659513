<div class="current-states">
    <div class="current-states__wrapper" *ngIf="!isLoadingResults; else loading">
        <div class="current-states__item">
            <img src="assets/illustrations/ONLINE-01.svg">
            <div class="current-states__data">{{ stats.devices.online }}</div>
            <h3 class="current-states__title" i18n>zařízení online</h3>
        </div>
        <div class="current-states__item">
            <img src="assets/illustrations/OFFLINE_01.svg">
            <div class="current-states__data">{{ stats.devices.offline }}</div>
            <h3 class="current-states__title" i18n>zařízení offline</h3>
        </div>
        <div class="current-states__item">
            <img src="assets/illustrations/ACTIVE-01.svg">
            <div class="current-states__data">{{ stats.campaigns.active }}</div>
            <h3 class="current-states__title" i18n>aktivních kampaní</h3>
        </div>
    </div>
</div>

<ng-template #loading>
    <app-loading></app-loading>
</ng-template>
