<form class="clip-detail-forms" [formGroup]="clipFormGroup">
    <div  class="clip-detail-forms__name">
        <mat-form-field class="mat-mdc-form-field--fullwidth" >
            <mat-label i18n>Název klipu</mat-label>
            <input  matInput i18n-placeholder placeholder="Název klipu" required  formControlName="name">
        </mat-form-field>
    </div>
   
    <div  class="clip-detail-forms__description">
        <mat-form-field class="mat-mdc-form-field--fullwidth">
            <mat-label i18n>Popis klipu</mat-label>
            <textarea matInput i18n-placeholder placeholder="Popis klipu" formControlName="description"></textarea>
        </mat-form-field>
    </div>

    <div class="clip-detail-forms__tags">
        <app-tag-attach (attached)="addTag($event)" [tags]="clip.tags" [type]="'clips'"></app-tag-attach>
    </div>

    <div  class="clip-detail-forms__duration" *ngIf="needsDuration">
        <mat-form-field class="mat-form-field--fullwidth" >
            <mat-label i18n>Doba videa v sekundách</mat-label>
            <input matInput i18n-placeholder placeholder="Doba videa v sekundách" required  formControlName="duration">
        </mat-form-field>
    </div>

    

    <div  class="clip-detail-forms__info" *ngIf="clip">
        <h3 i18n>Informace o klipu</h3>
        <div  class="clip-detail-forms__info-text">
            <div>
                <span> <strong i18n>Celkový čas:</strong></span>
                <span><strong i18n>Typ klipu:</strong></span>
                <span><strong i18n>Velikost:</strong></span>
                <span *ngIf="clip.resolution"> <strong i18n>Rozlišení:</strong></span>
                <span><strong i18n>Nahráno:</strong></span>
            </div>
            <div>
                <span>{{ clip.duration }} s</span>
                <span>{{ clip.mimeType }} </span>
                <span>{{ clip.size | convertDataSizes }}</span>
                <span *ngIf="clip.resolution">{{ clip.resolution.width }} &#10005; {{ clip.resolution.height }} </span>
                <span>{{ clip.uploadDate | date:'medium' }} </span>
            </div>
        </div>

        <div class="clip-detail-forms__original"  *ngIf="!needsDuration">
            <mat-checkbox color="primary" class="example-margin" formControlName="playOriginal" i18n>Přehrávat originální soubor</mat-checkbox>
        </div>
        
    </div>
</form>