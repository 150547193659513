<form class="layout-selection" [formGroup]="layoutSelectionFormGroup">
    <div *ngIf="selectedLayout && !showLayoutSelection" class="layout-selection__preview">
        <div class="layout-selection__preview__container">
            <app-layout-preview [layout]="selectedLayout" [previewTemplate]="largeTemplate" ></app-layout-preview>
            <!--button mat-mini-fab color="primary" (click)="changeLayout()"  class="layout-selection__edit" aria-label="Change url">
                <mat-icon>edit</mat-icon>
            </button-->
        </div>
        <div class="layout-selection__preview__actions">
            <button (click)="changeLayoutUrl()" mat-button i18n>Zadat externí odkaz</button>
            <button color="primary" mat-raised-button (click)="changeLayout()"  class="layout-selection__change" i18n>Změnit</button>
        </div>
    </div>
    <div *ngIf="showCustomUrl" class="layout-selection__url">
        <mat-form-field class="mat-mdc-form-field--fullwidth" >
            <mat-label i18n>Odkaz layoutu</mat-label>
            <input  matInput i18n-placeholder placeholder="Odkaz layoutu"  formControlName="layoutUrl">
        </mat-form-field>
        <button color="primary" mat-button (click)="changeLayout()"  class="layout-selection__change" i18n>Vybrat layout ze seznamu</button>
    </div>
    <mat-stepper  *ngIf="!isLoadingResults && showLayoutSelection" animationDuration="0s" class="layout-selection__stepper"  [linear]="true" #stepper >
        <mat-step  [stepControl]="layoutSelectionFormGroup">
            <ng-template matStepContent>
                <h3 i18n>Vyberte orientaci displeje:</h3>
                <div class="layout-selection__orientation">
                    <mat-card i18n-matTooltip matTooltip="Na šířku" matStepperNext (click)="setOrientation('Landscape')" matStepperNext class="layout-selection__orientation-card">
                        <img  src="assets/img/layout-horizontal.png">
                    </mat-card>
                    <mat-card i18n-matTooltip matTooltip="Na výšku" matStepperNext (click)="setOrientation('PortraitLeft')" matStepperNext class="layout-selection__orientation-card">
                        <img src="assets/img/layout-vertical.png">
                    </mat-card>
                </div>
            </ng-template>
        </mat-step>
        <mat-step  [stepControl]="layoutSelectionFormGroup">
            <ng-template matStepContent>
                <h3 i18n>Vyberte rozloženi:</h3>
                <div class="layout-selection__orientation__back"></div>
                <div class="layout-selection__list">
                    <app-layouts-list (selected)="onSelectedLayout($event)" *ngIf="!layoutSelectionFormGroup.get('pasteLink').value" [filters]="layoutFilters" [selectable]="true" [previewTemplate]="layoutTemplateList"></app-layouts-list>
                    <mat-form-field class="mat-mdc-form-field--fullwidth" *ngIf="layoutSelectionFormGroup.get('pasteLink').value">
                        <mat-label i18n>Layout</mat-label>
                        <input  matInput i18n-placeholder placeholder="Layout"  formControlName="layoutUrl" >
                    </mat-form-field>
                </div>
                <button color="primary" mat-button (click)="changeLayout()"  class="layout-selection__change" i18n>Zpět</button>

            </ng-template>
        </mat-step>
    </mat-stepper>

    
</form>