<div *ngIf="tags" class="tag-menu" i18n-matTooltip [matTooltip]="tags.length===0 ? noTag : assigned">
    
    <div *ngIf="tags.length > groupCount || tags.length === 0"  class="tag-menu__group">
        <mat-chip-listbox [selectable]="selectable" *ngIf="selectedTags.length > 0" i18n-aria-label aria-label="Menu štítků">
            <mat-chip-option  [selected]="tag.isSelected" [removable]="true" (removed)="selectTag(tag)" color="primary" *ngFor="let tag of selectedTags" class="tag-menu__item" >
              {{tag.value}}<mat-icon matChipRemove >cancel</mat-icon>
            </mat-chip-option>
        </mat-chip-listbox>
        <button mat-icon-button class="tag-menu__toggle" [matMenuTriggerFor]="menu" [disabled]="tags.length==0" i18n-aria-label aria-label="Přiřazené štítky">
            <mat-icon  [color]="tags.length>0 ? 'primary' : ''">more</mat-icon>
        </button>
    </div>

    <mat-chip-listbox [selectable]="selectable" *ngIf="tags.length <= groupCount">
        <mat-chip-option [selected]="!selectable ? false : tag.isSelected"  *ngFor="let tag of (tags | tagsGroupFilter:'client')" color="primary" class="tag-menu__item" >{{tag.value}}</mat-chip-option>
    </mat-chip-listbox>
    
    <mat-menu #menu="matMenu"  class="tag-menu__window" >
        <mat-chip-listbox  [selectable]="selectable" *ngIf="!selectable" i18n-aria-label aria-label="Štítky" [selectable]="false"  class="tag-menu__list" >
            <div *ngIf="(tags | tagsGroupFilter:'client').length > 0">
                <h5 *ngIf="isAdmin" i18n>Klientské štítky</h5>
                <mat-chip-option  *ngFor="let tag of (tags | tagsGroupFilter:'client')" class="tag-menu__item" >{{tag.value}}</mat-chip-option>
            </div>
            <div *ngIf="isAdmin">
                <div *ngIf="(tags | tagsGroupFilter:'admin').length>0">
                    <h5 i18n>Administrační štítky</h5>
                </div>
                <mat-chip-option  *ngFor="let tag of (tags | tagsGroupFilter:'admin')" class="tag-menu__item" >{{tag.value}}</mat-chip-option>
            </div>
        </mat-chip-listbox>

        <div *ngIf="selectable"  [selectable]="selectable" i18n-aria-label aria-label="Štítky"  class="tag-menu__list" >
          <h5 *ngIf="isAdmin" i18n>Klientské štítky</h5>
          <mat-chip-listbox  *ngIf="(tags | tagsGroupFilter:'client').length > 0"  [selectable]="true" aria-label="Štítky"  class="tag-menu__list"  [multiple]="true">
              <mat-chip-option [selected]="tag.isSelected" color="primary" *ngFor="let tag of (tags | tagsGroupFilter:'client')" (click)="selectTag(tag)" class="tag-menu__item" >{{tag.value}}</mat-chip-option>
          </mat-chip-listbox>
          <h5 *ngIf="isAdmin" i18n>Administrační štítky</h5>
          <mat-chip-listbox *ngIf="(tags | tagsGroupFilter:'admin').length > 0 && isAdmin" [selectable]="true" aria-label="Štítky"  class="tag-menu__list"  [multiple]="true" >
              <mat-chip-option [selected]="tag.isSelected" *ngFor="let tag of (tags | tagsGroupFilter:'admin')" color="primary"  (click)="selectTag(tag)" class="tag-menu__item" >{{tag.value}}</mat-chip-option>
          </mat-chip-listbox >

          <button class="mt-5 flex w-full justify-center"  mat-button color="primary" *ngIf="selectedTags.length > 0" i18n-aria-label aria-label="Štítky"  (click)="unselectTags()" i18n>Zrušit filtry</button>
        </div>
    </mat-menu>
</div>
