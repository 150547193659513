import {
  Component,
  OnChanges,
  Input,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { Campaign } from "../../models/campaign.model";

import { ScheduleService } from "../schedule.service";
import { CampaignPreview } from "src/app/models/campaign-preview.model";
import * as dayjs from "dayjs";
import * as duration from "dayjs/plugin/duration";

import { ContentClip } from "src/app/models/content-clip.model";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { CustomUtils } from "src/app/utils/custom-utils.component";
import { TimelineCampaign, TimelineClip } from "src/app/models/timeline.model";
dayjs.extend(duration);
@Component({
  selector: "app-timeline",
  templateUrl: "./timeline.component.html",
  styleUrls: ["./timeline.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TimelineComponent implements OnChanges {
  @Input() fixedPlaylist: Campaign[];
  @Input() repeatedPlaylist: Campaign[];
  @Input() deviceTime: any;

  @ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;

  labelsArray: string[];
  camaignsArray: any;

  isPreviewVisible: boolean;
  clickedCampaign: any;
  actualTime: string;
  actualPos: number;
  actualTimeButtonPos: number;
  firstRun: boolean;
  fixedItems: TimelineCampaign[] = [];
  repeatedItems: TimelineCampaign[] = [];

  constructor(private scheduleService: ScheduleService) {
    this.isPreviewVisible = false;
    this.firstRun = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.fixedPlaylist) {
      this.fixedPlaylist = changes.fixedPlaylist.currentValue;
    }

    if (changes.repeatedPlaylist) {
      this.repeatedPlaylist = changes.repeatedPlaylist.currentValue;
    }
    this.createTimelineLabels();
    this.hidePreview();
    this.createRepeatedTimes();
    this.createFixedTimes();
  }
  createRepeatedTimes() {
    this.repeatedItems = [];
    let totalSeconds = 0;

    if (this.repeatedPlaylist.length > 0) {
      let i = 0;

      while (totalSeconds < this.deviceTime.workingSeconds) {
        // repeat during device working time
        const campaign = this.repeatedPlaylist[i];
        if (campaign) {
          // check if campaign exist or reurn to loop begin
          //check if campaign is
          var isCampaignActive = this.hasActiveFilters(campaign, totalSeconds);
          if (isCampaignActive) {
            i++;
            continue;
          }

          let timelineCampaign = new TimelineCampaign();
          Object.keys(timelineCampaign).forEach((key) => {
            if (
              campaign[key] != undefined &&
              !Number.isNaN(campaign[key]) &&
              key != "videoclips"
            ) {
              timelineCampaign[key] = campaign[key];
            }
          });

          timelineCampaign.duration = 0;

          campaign.videoclips.forEach((clip) => {
            let timelineClip = new TimelineClip();
            Object.keys(timelineClip).forEach((key) => {
              if (clip[key] != undefined && !Number.isNaN(clip[key])) {
                timelineClip[key] = clip[key];
              }
            });

            // fix if image with no duration
            timelineClip.duration = clip.duration;
            timelineCampaign.duration += timelineClip.duration;
            timelineCampaign.videoclips.push(timelineClip);
          });

          this.repeatedItems.push(timelineCampaign);
          totalSeconds += timelineCampaign.duration;
          i++;
        } else {
          if (totalSeconds === 0) {
            break; // break if there is no campaign
          } else {
            i = 0; // go campaings from begining
          }
        }
      }
    }
  }

  createFixedTimes() {
    this.fixedItems = [];
    this.fixedPlaylist.forEach((campaign) => {
      // backward compatibility
      if (campaign.fixedStartTime) {
        campaign.fixedStartTimes = campaign.fixedStartTimes
          ? campaign.fixedStartTimes
          : [];
        campaign.fixedStartTimes.push(campaign.fixedStartTime);
      }

      //sort times for generating timeline
      campaign.fixedStartTimes.sort((a, b) => a.localeCompare(b));

      // create timelineCampagin and merge it with current campaign
      let timelineCampaign = new TimelineCampaign();
      Object.keys(timelineCampaign).forEach((key) => {
        if (
          campaign[key] != undefined &&
          !Number.isNaN(campaign[key]) &&
          key != "videoclips"
        ) {
          timelineCampaign[key] = campaign[key];
        }
      });

      timelineCampaign.duration = 0;

      //create timeline clip and merge and set duration
      campaign.videoclips.forEach((clip) => {
        let timelineClip = new TimelineClip();
        Object.keys(timelineClip).forEach((key) => {
          if (clip[key] != undefined && !Number.isNaN(clip[key])) {
            timelineClip[key] = clip[key];
          }
        });
        timelineClip.duration = this.fixNullDuration(clip); // fix if image with no duration
        timelineCampaign.duration += timelineClip.duration;
        timelineCampaign.videoclips.push(timelineClip);
      });

      // if campaign type is repeated or play once per schedule
      if (campaign.repeatFixedCampaign) {
        let lastTime = null;

        for (let time of campaign.fixedStartTimes) {
          let timeObject = CustomUtils.getTimeObjectFromTime(time);

          /*var isCampaignActive = this.hasActiveFilters(campaign, CustomUtils.timeToSeconds(time));
                    if(isCampaignActive) {
                        continue;
                    }*/

          // check next item or create first timeline item.
          if (lastTime) {
            let durationBetweenTimes = Math.round(
              dayjs.duration(timeObject.diff(lastTime)).asMinutes()
            );
            // check times if there is a time interuption
            if (durationBetweenTimes > 5) {
              timelineCampaign.startTime =
                CustomUtils.timeToSeconds(time) - this.deviceTime.startSecond;
              timelineCampaign.fixedRepeatDuration = 5 * 60;
              this.fixedItems.push(Object.assign({}, timelineCampaign));
            } else {
              this.fixedItems[this.fixedItems.length - 1].fixedRepeatDuration +=
                5 * 60; // add 300s to
            }
          } else {
            timelineCampaign.fixedRepeatDuration = 5 * 60;
            timelineCampaign.startTime =
              CustomUtils.timeToSeconds(time) - this.deviceTime.startSecond;
            this.fixedItems.push(Object.assign({}, timelineCampaign));
          }

          lastTime = timeObject;
        }
      } else {
        for (let time of campaign.fixedStartTimes) {
          timelineCampaign.startTime =
            CustomUtils.timeToSeconds(time) - this.deviceTime.startSecond;
          timelineCampaign.fixedRepeatDuration = timelineCampaign.duration;
          this.fixedItems.push(Object.assign({}, timelineCampaign));
        }
      }
    });
  }

  repeatedClipsInCampaign(campaign: TimelineCampaign) {
    let count = Math.ceil(campaign.fixedRepeatDuration / campaign.duration);
    if (count > 0) {
      return new Array<boolean>(count);
    } else {
      return new Array<boolean>();
    }
  }

  fixNullDuration(clip: ContentClip) {
    // set default value for null duration
    if (clip.clipType === "image" && clip.duration === 0) {
      return 10;
    } else if (clip.duration === 0) {
      return 10;
    } else {
      return clip.duration;
    }
  }

  hasActiveFilters(campaign, loopSecond: number): boolean {
    const currentDate = new Date().toISOString();
    const currentDay = new Date().getDay();
    const loopTime = dayjs(this.deviceTime.startTime, "HH:mm:ss").add(
      loopSecond,
      "second"
    );
    const loopDate = dayjs(this.deviceTime.forDay)
      .set("hour", loopTime.hour())
      .set("minute", loopTime.minute())
      .set("second", 0)
      .set("millisecond", 0);

    let startDate = dayjs(CustomUtils.fixPhpDate(campaign.startDate));
    let curentDayjsDate = dayjs(currentDate);

    if (campaign.useDaySelection) {
      // disable campaign by day selection
      if (!campaign.daysSelected.includes(currentDay)) {
        //console.log("disabled by day selection")
        return true;
      }
    }
    if (!campaign.untilStop) {
      if (CustomUtils.fixPhpDate(campaign.endDate) <= loopDate.toISOString()) {
        //console.log("disabled by end date")
        return true;
      }
    }

    if (startDate.isAfter(loopDate) && startDate.isAfter(curentDayjsDate)) {
      //console.log("disabled by has not start yet")
      return true;
    }

    if (campaign.useDayTime) {
      // disable clips with selected time range
      if (
        loopTime.format("HH:mm:ss") >= campaign.dayStartTime &&
        loopTime.format("HH:mm:ss") < campaign.dayEndTime
      ) {
      } else {
        //console.log("disabled by day time")
        return true;
      }
    }
    return false;
  }

  createTimelineLabels() {
    this.labelsArray = [];
    let time = this.deviceTime.startTime;

    while (time < this.deviceTime.endTime) {
      const actualTimes = time.split(":").map(Number);
      this.labelsArray.push(
        `${actualTimes[0].toString().padStart(2, "0")}:${actualTimes[1]
          .toString()
          .padStart(2, "0")}`
      );

      actualTimes[1]++;

      if (actualTimes[1] % 60 === 0) {
        actualTimes[0]++;
        actualTimes[1] = 0;
      }

      time = `${actualTimes[0].toString().padStart(2, "0")}:${actualTimes[1]
        .toString()
        .padStart(2, "0")}:00`;
    }
    this.actualTime = dayjs().format("HH:mm");

    this.actualPos = this.timeToPosition(this.actualTime);
    this.actualTimeButtonPos =
      (this.actualPos / this.deviceTime.workingSeconds) * 100;
    setTimeout(() => {
      this.jumpToActuialTime();
    });
  }

  timeToPosition(time: string) {
    const timeArray = time.split(":").map(Number);
    const startSecond: number =
      timeArray[0] * 3600 + timeArray[1] * 60 - this.deviceTime.startSecond;
    return startSecond;
  }

  getFixedCampaignEndTime(campaign: CampaignPreview) {
    if (
      dayjs(campaign.endDate).format("YYYY-MM-DD") ===
        dayjs(this.deviceTime.forDay).format("YYYY-MM-DD") &&
      campaign.endTime < campaign.fixedEndTime
    ) {
      return campaign.endTime.split(":").map(Number);
    } else {
      return campaign.fixedEndTime.split(":").map(Number);
    }
  }

  findCampaignById(id): Campaign {
    let campaign = this.repeatedPlaylist.find((campaign) => campaign.id == id);
    if (!campaign) {
      campaign = this.fixedPlaylist.find((campaign) => campaign.id == id);
    }
    return campaign;
  }

  showPreview(campaignId: number, clipIndex: number, event) {
    this.clickedCampaign = event.target;
    this.isPreviewVisible = true;
    let foundCampaign = this.findCampaignById(campaignId);
    const data = {
      campaign: foundCampaign,
      clip: foundCampaign.videoclips[clipIndex],
      event,
    };

    this.scheduleService.openCampaingPreview(data);
  }

  showFixedPreviewById(campaignId: number, clipIndex: number, event) {
    this.clickedCampaign = event.target;
    this.isPreviewVisible = true;
    let campaign = this.fixedPlaylist.find((item) => item.id === campaignId);
    const data = {
      campaign,
      clip: campaign.videoclips[clipIndex],
      event,
    };

    this.scheduleService.openCampaingPreview(data);
  }

  hidePreview() {
    this.scheduleService.closeCampaignPreview();
    this.isPreviewVisible = false;
  }

  jumpToActuialTime() {
    this.actualTime = dayjs().format("HH:mm");
    this.actualPos = this.timeToPosition(this.actualTime);

    const viewWidth = this.viewPort.elementRef.nativeElement.offsetWidth;
    const offset =
      viewWidth * (this.actualPos / this.deviceTime.workingSeconds);
    this.viewPort.scrollToOffset(this.actualPos - offset, "auto");
  }
}
