import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CampaignsComponent } from "./campaigns/campaigns.component";
import { DevicesComponent } from "./devices/devices.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { LoginComponent } from "./login/login.component";
import { HomepageComponent } from "./homepage/homepage.component";
import { TagsComponent } from "./tags/tags.component";
import { ClipsComponent } from "./clips/clips.component";

import { AuthGuard } from "./guards/auth.guard";
import { EditorComponent } from "./editor/editor.component";
import { SupportComponent } from "./support/support/support.component";
import { LoginRecoveryComponent } from "./login/login-recovery/login-recovery.component";
import { ModulesComponent } from "./modules/modules.component";
import { PasswordChangeComponent } from "./login/password-change/password-change.component";
import { Error404Component } from "./errors/error404/error404.component";
import { AccountDeleteComponent } from "./login/account-delete/account-delete.component";

const routes: Routes = [
  {
    path: "",
    component: HomepageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: DashboardComponent,
        outlet: "content",
        data: { title: "Dashboard" },
      },
    ],
  },
  { path: "login", component: LoginComponent, data: { title: "Přihlášení" } },
  {
    path: "account-delete",
    component: HomepageComponent,
    canActivate: [AuthGuard],
    data: { title: "Smazání účtu" },
    children: [
      {
        path: "",
        component: AccountDeleteComponent,
        outlet: "content",
      },
    ],
  },
  {
    path: "login-recovery",
    component: LoginRecoveryComponent,
    data: { title: "Obonova hesla" },
  },
  {
    path: "password-change",
    component: PasswordChangeComponent,
    data: { title: "Změna hesla" },
  },
  {
    path: "campaigns",
    component: HomepageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: CampaignsComponent,
        outlet: "content",
        data: { title: "Kampaně" },
      },
    ],
  },
  {
    path: "devices",
    component: HomepageComponent,
    canActivate: [AuthGuard],
    data: { title: "Zařízení" },
    children: [
      {
        path: "",
        component: DevicesComponent,
        outlet: "content",
      },
    ],
  },
  {
    path: "dashboard",
    component: HomepageComponent,
    children: [
      {
        path: "",
        component: DashboardComponent,
        canActivate: [AuthGuard],
        outlet: "content",
        data: { title: "Dashboard" },
      },
    ],
  },
  {
    path: "tags",
    component: HomepageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: TagsComponent,
        outlet: "content",
        data: { title: "Štítky" },
      },
    ],
  },
  {
    path: "clips",
    component: HomepageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: ClipsComponent,
        outlet: "content",
        data: { title: "Videa a obsah" },
      },
    ],
  },
  {
    path: "statistics",
    component: HomepageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./statistics/statistics.module").then(
            (m) => m.StatisticsModule
          ),
      },
    ],
  },
  {
    path: "layouts",
    loadChildren: () =>
      import("./layouts/layouts.module").then((m) => m.LayoutsModule),
    canActivate: [AuthGuard],
  },
  {
    path: "editor",
    component: HomepageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: EditorComponent,
        outlet: "content",
        data: { title: "Statistiky" },
      },
    ],
  },
  {
    path: "touchscreen",
    component: HomepageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./touch-screen/touch-screen.module").then(
            (m) => m.TouchScreenModule
          ),
      },
    ],
  },
  {
    path: "lector-lift",
    component: HomepageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./lektor-lift/lektor-lift.module").then(
            (m) => m.LektorLiftModule
          ),
      },
    ],
  },
  {
    path: "layouter",
    component: HomepageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./layouter/layouter.module").then((m) => m.LayouterModule),
      },
    ],
  },
  {
    path: "strips-editor",
    component: HomepageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./strips/strips.module").then((m) => m.StripsModule),
      },
    ],
  },
  {
    path: "support",
    component: HomepageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: SupportComponent,
        outlet: "content",
        data: { title: "Podpora" },
      },
    ],
  },
  {
    path: "modules",
    component: HomepageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: ModulesComponent,
        outlet: "content",
        data: { title: "Moduly" },
      },
    ],
  },
  { path: "**", pathMatch: "full", component: Error404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
