import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { AuthorizationService } from '../services/authorization.service';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { Tag } from '../models/tag.model';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class TagsService {
  serverUrl: string;
  headers: HttpHeaders;

  public tagManagerDialog = new Subject<any>();
  public tagNewDialog = new Subject<any>();
  public tagAttachDialog = new Subject<string>();
  public updateTagList = new Subject<string>();

  constructor(private http: HttpClient, private userService: UserService,  public dialog: MatDialog, private authorizationService: AuthorizationService) {
    this.serverUrl = `${environment.serverProtocol}://${environment.serverAddress}:${environment.serverPort}`;
    this.headers = new HttpHeaders().set('Authorization', 'Bearer ' +  this.authorizationService.accessTokenValue);
    this.headers = this.headers.set('x-company-id', this.userService.activeCompanyId + '');

  }

  fetchTagsByCompany(type?: string) {
    type = (typeof type !== 'undefined') ? type : '';

    const url = `${this.serverUrl}/api2/${type}/tags`;
    return this.http.get<Tag[]>(url, { headers: this.headers });
  }

  openTagNewDialog(type: string, attachTo?: number[]) {
    attachTo = attachTo === undefined ? null : attachTo;
    return this.tagNewDialog.next({type, attachTo });
  }
  addNewTag(tag: Tag) {
    const url = `${this.serverUrl}/api2/tag`;
    return this.http.post<Tag[]>(url, tag, { headers: this.headers });
  }
  updateTag(tag: Tag) {
    const url = `${this.serverUrl}/api2/tag`;
    return this.http.put<Tag[]>(url, tag, { headers: this.headers });
  }
  removeTag(tag: Tag) {
    const url = `${this.serverUrl}/api2/${tag.type}/tag?group=${tag.group}&value=${tag.value}`;
    return this.http.delete<Tag[]>(url, { headers: this.headers });
  }
  openAttachTags(type: string) {
    return this.tagNewDialog.next(type);
  }
  openTagManager(tag: Tag) {
    return this.tagManagerDialog.next(tag);
  }
  public updateTags() {
    this.updateTagList.next("");
  }
}
