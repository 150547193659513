<div class="campaigns-multiple-actions" *ngIf="selection" >
    <form class="campaigns-multiple-actions__forms" [formGroup]="clipMultpileGroup">
        <div class="campaigns-multiple-actions__header">
            <h3 i18n>Hromadné akce</h3>
            <p class="campaigns-multiple-actions__count" i18n>(vybráno {{ selection.length }} kampaní)</p>
        </div>
        <div  class="campaigns-multiple-actions__description">
            <mat-form-field class="mat-mdc-form-field--fullwidth" >
                <textarea  matInput i18n-placeholder placeholder="Popis kampaní"  formControlName="commonDescription" ></textarea>
                <mat-hint  i18n>Stávající popis se přepíše pro všechny vybrané kampaně</mat-hint>
            </mat-form-field>
        </div>
        <app-tag-attach [type]="'campaigns'" [tags]="commonTags" [autocomplete]="true" (removed)="remove($event)" (attached)="attach($event)"></app-tag-attach>
        <div>
            <button mat-raised-button [matMenuTriggerFor]="campaignMenu"><span i18n>Status kampaní</span><mat-icon >build</mat-icon></button>
            <mat-menu #campaignMenu="matMenu">
                  <button  mat-menu-item (click)="finishCampaigns()" ><mat-icon >assignment_turned_in</mat-icon><span i18n>Ukončit kampaně</span></button>
                  <button  mat-menu-item (click)="toggleActiveCampaign(false)" i18n-matTooltip matTooltip="Deaktivovat všechny vybrané kampň" aria-label="Aktivovat/pozastavit kampň" ><mat-icon >block</mat-icon><span i18n>Nastavit jako neaktivní</span></button>
                  <button mat-menu-item (click)="toggleActiveCampaign(true)" i18n-matTooltip matTooltip="Aktivovat všechny vybrané kampaně" aria-label="Aktivovat/pozastavit kampň" ><mat-icon >done</mat-icon><span i18n>Nastavit jako aktivní</span></button>
            </mat-menu>
      </div>
    </form>
    <div class="campaigns-multiple-actions__footer" *ngIf="template === 'multiple-actions'">
        <button color="primary" i18n-matTooltip matTooltip="Smazat vybrané"  mat-icon-button (click)="deleteSelected()" i18n-aria-label aria-label="Smazat vybrané"><mat-icon aria-hidden="false" >delete</mat-icon></button>
        <button color="primary" i18n-matTooltip matTooltip="Uložit vybrané kampaně"  mat-raised-button (click)="saveSelected()"><mat-icon aria-hidden="false" i18n-aria-label aria-label="uložit změny" >done</mat-icon><span i18n>Uložit vybrané kampaně</span></button>
    </div>
</div>