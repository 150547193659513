import { LOCALE_ID, NgModule } from "@angular/core";

import { MAT_DATE_LOCALE } from "@angular/material/core";

import { CampaignThumbnailComponent } from "../campaigns/campaign-thumbnail/campaign-thumbnail.component";
import { ClipPlayerComponent } from "../clips/clip-player/clip-player.component";
import { LoadingComponent } from "../loading/loading.component";
import { DateTimePickerComponent } from "../utils/date-time-picker/date-time-picker.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatIconModule } from "@angular/material/icon";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDialogModule } from "@angular/material/dialog";
import { MatMenuModule } from "@angular/material/menu";
import { CommonModule } from "@angular/common";
import { MatCardModule } from "@angular/material/card";
import { MatButtonModule } from "@angular/material/button";
import { NgxDropzoneModule } from "ngx-dropzone";
import { MatSelectModule } from "@angular/material/select";
import { LayoutsListComponent } from "../layouts/layouts-list/layouts-list.component";
import { LayoutPreviewComponent } from "../layouts/layout-preview/layout-preview.component";
import { LayoutPreviewFilterPipe } from "../layouts/layout-preview-filter.pipe";

@NgModule({
  declarations: [
    LoadingComponent,
    CampaignThumbnailComponent,
    ClipPlayerComponent,
    DateTimePickerComponent,
    LayoutsListComponent,
    LayoutPreviewComponent,
    LayoutPreviewFilterPipe,
  ],
  imports: [
    MatSelectModule,
    CommonModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatMenuModule,
    MatIconModule,
    NgxMaterialTimepickerModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatButtonModule,
    MatDialogModule,
    NgxDropzoneModule,
  ],
  exports: [
    LoadingComponent,
    CampaignThumbnailComponent,
    ClipPlayerComponent,
    DateTimePickerComponent,
    LayoutPreviewComponent,
    LayoutsListComponent,
  ],
  providers: [
    MatDatepickerModule,
    { provide: MAT_DATE_LOCALE, useValue: "cs" },
    { provide: LOCALE_ID, useValue: "cs" },
  ],
})
export class SharedModule {}
