<div class="support">
    <mat-card class="support__left card card--large mat-elevation-z8">
        <mat-card-title><mat-icon color="primary">alternate_email</mat-icon><span i18n>Kontakty</span></mat-card-title>
        <div class="support__left">
            <div class="support__illustration"><img src="assets/illustrations/support_illustration.jpg"></div>
            <div class="support__contact">
                <div>
                    <h3 i18n>Email:</h3>
                    <span>podpora@ismedia.cz</span>
                </div>
                <div>
                    <h3 i18n>Telefon:</h3>
                    <span>+420 730 511 422</span>
                </div>
            </div>
        </div>
    </mat-card>

    <!--mat-card class="support__left card card--large mat-elevation-z8">
        <mat-card-title><mat-icon>contact_support</mat-icon>Podpora</mat-card-title>
        <div class="support__links">
            <h3>Odkazy</h3>
            <a href="#">Manuál</a>
        </div>
        <div  class="support__faq">
            <h3>FAQ</h3>
            <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    1. Plánovač nefunguje
                  </mat-panel-title>
              
                </mat-expansion-panel-header>
                <p>This is the primary content of the panel.</p>
              </mat-expansion-panel>
        </div>
    </mat-card-->
</div>