import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CompanyService } from '../company/company.service';
import { UserService } from '../user/user.service';
import { ContactDialogComponent } from '../utils/contact-dialog/contact-dialog.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modules',
  templateUrl: './modules.component.html',
  styleUrls: ['./modules.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModulesComponent implements OnInit {
    hasLayouter  = true;
    isProduction = false;
  constructor(public companyService:CompanyService, public dialog: MatDialog, public userService: UserService) { }
    
  ngOnInit(): void {
    this.isProduction = environment.production;
  }
  openContactDialog() {
    let dialogRef = this.dialog.open(ContactDialogComponent, {
        height: '300px',
        width: '400px',
      });
  }
}
