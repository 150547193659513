import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewEncapsulation,
  SimpleChanges,
  OnChanges,
} from "@angular/core";
import { TagsService } from "../tags.service";
import { Tag, TagEvent } from "../../models/tag.model";
import { MatChipInputEvent, MatChip } from "@angular/material/chips";
import { COMMA, ENTER } from "@angular/cdk/keycodes";

import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogComponent } from "src/app/utils/confirm-dialog/confirm-dialog.component";
import { UserService } from "src/app/user/user.service";
import { FormControl } from "@angular/forms";
import { Subscription } from "rxjs";

@Component({
  selector: "app-tags-list",
  templateUrl: "./tags-list.component.html",
  styleUrls: ["./tags-list.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TagsListComponent implements OnInit, OnChanges  {
  @Input() type: string = null;
  @Input() checkable = false; // for check items by tag
  @Input() removable = false;
  @Input() selectable = true; // for filter list items by tag
  @Input() activeTags: Tag[];

  @Output() public selectedTags = new EventEmitter();
  @Output() public checked = new EventEmitter();

  checkedItems: Tag[] = [];
  selectedItems: Tag[] = [];
  visible = true;
  addOnBlur = true;
  isAdmin: boolean;
  tagsServiceSubscription: Subscription;
  tagControl = new FormControl("");
  isLoadingResults = false;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  tags: Tag[];

  constructor(
    private tagsService: TagsService,
    public dialog: MatDialog,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.isAdmin = this.userService.hasRole("admin");
    this.fetchTags();
    this.tagsServiceSubscription = this.tagsService.updateTagList.subscribe(
      () => {
        this.fetchTags();
      }
    );
  }
  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);

    if(changes['activeTags']) {
    
      if (this.checkable) {
        setTimeout(()=> {
        changes['activeTags'].currentValue.forEach(tag => {
          console.log(tag);
          
          /*let currentTag = this.tags.find(someTag => someTag.value === tag.value && someTag.group === tag.group);
          let isAlreadyChecked = this.checkedItems.some(someTag => someTag.value === tag.value && someTag.group === tag.group);

          if (currentTag && !isAlreadyChecked) {
            this.checkTag(currentTag, false);
          }
          if (isAlreadyChecked) {
            currentTag.isSelected = true;
          }*/
        });
        },1);
      }
    }
  }

  highlightTag(tag) {
    this.tags.find(someTag => someTag.value === tag.value && someTag.group === tag.group).isSelected = true
  }

  ngOnDestroy() {
    this.tagsServiceSubscription.unsubscribe();
  }

  fetchTags() {
    this.isLoadingResults = true;
    this.tagsService.fetchTagsByCompany(this.type).subscribe((data: Tag[]) => {
      this.tags = data;
      this.isLoadingResults = false;
     
    });
  }

  addTag(event: MatChipInputEvent, group: string): void {
    const tagValue = event.value;

    if ((tagValue || "").trim()) {
      const newTag = new Tag();
      newTag.value = tagValue.trim();
      newTag.type = this.type;
      newTag.group = group;

      this.tagsService.addNewTag(newTag).subscribe({
        next: () => {
          event.chipInput!.clear();
          //this.tagControl.setValue(null);
          console.log("tag request success");
          this.fetchTags();
        },
        error: this.handleError.bind(this),
      });
    }
  }

  handleUpdateResponse() {
    console.log("tag request success");
  }

  handleError() {
    console.log("tag request error");
  }

  removeTag(tag: Tag): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: "300px",
        panelClass: "",
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.tags.splice(index, 1);

          this.tagsService.removeTag(tag).subscribe(() => {
            this.selectedTags.emit(tag);
            this.tagsService.updateTags();
          });
        }
      });
    }
  }

  clickTag(tag: Tag) {
    if (this.selectable) {
      tag.isSelected = !tag.isSelected;
    } else {
      this.tagsService.openTagManager(tag);
    }
  }
 
  selectTag(tag: Tag, emit?: boolean) {
    emit = emit !== undefined ? emit: true;

    if (this.selectable) {
      const tagIndex = this.selectedItems.findIndex((item) => item === tag);
      if (tagIndex === -1) {
        this.selectedItems.push(tag);
        tag.isSelected = false;
      } else {
        this.selectedItems.splice(tagIndex, 1);
        tag.isSelected = true;
      }
      if(emit) {
        this.selectedTags.emit(this.selectedItems);
      }
    }
  }

  checkTag(tag: Tag, emit?: boolean) {
    emit = emit !== undefined ? emit: true;
    const index = this.checkedItems.findIndex(
      (item) => item.value === tag.value
    );
    if (index >= 0) {
      this.checkedItems.splice(index, 1);
      tag.isSelected = false;
      if(emit) {
        this.checked.emit({checkedTags:  this.checkedItems, removedTags: [tag]} as TagEvent);
      }
    } else {
      this.checkedItems.push(tag);
      tag.isSelected = true;
      this.checked.emit({checkedTags:  this.checkedItems, removedTags: []} as TagEvent);
    }
  }
}
