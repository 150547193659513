
<mat-card class="modules card card--large card--soft-shadow">
    <mat-card-title><mat-icon color="primary">extension</mat-icon><span i18n>Moduly</span></mat-card-title>
    <div class="modules__container">
        <mat-card class="modules__item" >
            <mat-card-title i18n>Šablony pro dotykový panel</mat-card-title>
            <mat-card-subtitle i18n>Umožňuje-li vaše televize interakci s uživatelem, můžete si vytvořit interaktivní obrazovku dle vaší libosti. Pomocí editoru dokážete nastavit šablonu, tlačítka či akce, kterými budete ovládat obsah.</mat-card-subtitle>
            <mat-card-actions align="end">
                <button mat-button  (click)="openContactDialog()" [disabled]="companyService.checkModule('hasTouchscreen')" i18n>Zapnout</button>
                <button *ngIf="companyService.checkModule('hasTouchscreen')" mat-raised-button color="primary" routerLink="/touchscreen" i18n>Spustit</button>
            </mat-card-actions>
        </mat-card>

        <mat-card  class="modules__item" *ngIf="userService.hasRole('admin')" >
            <mat-card-title i18n>Šablona televize</mat-card-title>
            <mat-card-subtitle i18n>Navrhněte si vlastní rozložení televize</mat-card-subtitle>
            <mat-card-actions align="end">
                <button mat-button  (click)="openContactDialog()" [disabled]="hasLayouter" i18n>Zapnout</button>
                <button *ngIf="hasLayouter" mat-raised-button color="primary" routerLink="/layouter" i18n>Spustit</button>
            </mat-card-actions>
        </mat-card>

        <mat-card class="modules__item" *ngIf="userService.hasRole('admin')" >
          <mat-card-title i18n>Návrh proužků</mat-card-title>
          <mat-card-subtitle i18n>Navrhněte si vlastní design proužků a naplňte jejich obsah.</mat-card-subtitle>
          <mat-card-actions align="end">
              <!--button mat-button  (click)="openContactDialog()" [disabled]="hasLayouter" i18n>Zapnout</button-->
              <button *ngIf="hasLayouter" mat-raised-button color="primary" routerLink="/strips-editor" i18n>Spustit</button>
          </mat-card-actions>
        </mat-card>

        <mat-card class="modules__item" *ngIf="userService.hasRole('admin')" >
            <mat-card-title i18n>Lektor - stůl</mat-card-title>
            <mat-card-subtitle i18n>Propojte stojany s vaši kampaní.</mat-card-subtitle>
            <mat-card-actions align="end">
                <!--button mat-button  (click)="openContactDialog()" [disabled]="hasLayouter" i18n>Zapnout</button-->
                <button *ngIf="hasLayouter" mat-raised-button color="primary" routerLink="/lector-lift" i18n>Spustit</button>
            </mat-card-actions>
        </mat-card>
        <!--mat-card>
            <mat-card-title>Synchronizace radia</mat-card-title>
            <mat-card-subtitle>Pokud vaše televize hraje společně s rádiem, můžete si zde nastavit vlastnosti synchronizace tak, aby se zařízení společně nerušily</mat-card-subtitle>
            <mat-card-actions align="end">
                <button mat-button  routerLink="#">Zapnout</button>
                <button mat-raised-button color="primary" routerLink="#">Nastavit</button>
            </mat-card-actions>
        </mat-card-->
    </div>
</mat-card>