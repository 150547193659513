import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { DevicesService } from '../devices.service';
import { catchError } from 'rxjs/operators';
import {of as observableOf} from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-device-authorization',
  templateUrl: './device-authorization.component.html',
  styleUrls: ['./device-authorization.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DeviceAuthorizationComponent implements OnInit {
  deviceAuthFormGroup: FormGroup;
  success = false;
  isLoadingResults = false;
  error: any;

  constructor(private _snackBar: MatSnackBar, private fb: FormBuilder, private devicesService: DevicesService, public dialogRef: MatDialogRef<DeviceAuthorizationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.deviceAuthFormGroup = this.fb.group({
      name: new FormControl(null),
      branch: new FormControl(null),
      code: new FormControl(null),
      layout: new FormControl("https://market.ismedia.cz/layout/render/layout/196"),
    });
  }
  saveDevice() {
    this.isLoadingResults = true;
    let data = {
      code: parseInt(this.deviceAuthFormGroup.get('code').value),
      name: this.deviceAuthFormGroup.get('name').value,
      branch: this.deviceAuthFormGroup.get('branch').value,
      layout: this.deviceAuthFormGroup.get('layout').value
    }
    this.devicesService.deviceApprove(data).pipe(
      catchError((err) => {
        this.isLoadingResults = false;
        return observableOf(err.error);
      }),
    ).subscribe((result: any) => {
      console.log(result);
      if (result.code === 200 ) {

        this._snackBar.openFromComponent(DeviceAuthorizationSuccess, {
          duration: 5000,
        });

        this.devicesService.updateDevices();
        this.dialogRef.close();
      } else {
        this.error = result;
      }
      this.isLoadingResults = false;
    })
  }

  reset() {
    this.error = false;
  }
}


@Component({
  selector: 'device-authorisation-success',
  templateUrl: 'device-authorisation-success.html',
  styles: [`
    .example-pizza-party {
      color: hotpink;
    }
  `],
})
export class DeviceAuthorizationSuccess {}