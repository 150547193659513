import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { ContentClip } from 'src/app/models/content-clip.model';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Tag } from 'src/app/models/tag.model';

@Component({
  selector: 'app-clip-detail-forms',
  templateUrl: './clip-detail-forms.component.html',
  styleUrls: ['./clip-detail-forms.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClipDetailFormsComponent implements OnInit {
  @Input() clip: ContentClip;
  @Output() validated = new EventEmitter();
  clipFormGroup: FormGroup;
  needsDuration = true;

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.clipFormGroup = this.fb.group({
      name: new FormControl(this.clip.name, Validators.required),
      description: new FormControl(this.clip.description),
      duration: new FormControl(this.clip.duration),
      source: new FormControl(this.clip.source),
      playOriginal: new FormControl(this.clip.playOriginal)
    });


    this.clipFormGroup.valueChanges.subscribe(value => {
      this.updateDataModel(value);
    });

    if (this.clip.clipType === "video" || this.clip.clipType === "youtube") {
      this.needsDuration = false;
    }
    this.validated.emit(!this.clipFormGroup.invalid);

  }
  addTag(newtag: Tag) {
    const index = this.clip.tags.findIndex(tag => tag.value === newtag.value);
    if (index === -1) {
      this.clip.tags.push(newtag);
    }
  }
  updateDataModel(value) {
    this.clip = Object.assign(this.clip, value);
    this.validated.emit(!this.clipFormGroup.invalid);
  }
}
