import { Injectable, } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../environments/environment';
import { AuthorizationService } from '../services/authorization.service';
import { map} from 'rxjs/operators';
import { ContentClip } from '../models/content-clip.model';
import { UserService } from '../user/user.service';
import { Tag } from '../models/tag.model';

@Injectable({
  providedIn: 'root'
})
export class ClipsService {
  public clipUploadDialog = new Subject<any>();
  public clipDetailDialog = new Subject<ContentClip>();
  public clipSelectionDialog = new Subject<any>();

  public newClipUploaded = new Subject<ContentClip[]>();
  public updateClips = new Subject<any>();
  public checkedClips = new Subject<any>();

  serverUrl: string;
  headers: HttpHeaders;

  constructor(private http: HttpClient, public dialog: MatDialog, private authorizationService: AuthorizationService,
    private userService: UserService) {
    this.serverUrl = `${environment.serverProtocol}://${environment.serverAddress}:${environment.serverPort}`;
    this.headers = new HttpHeaders();
    this.headers = this.headers.set('Authorization', 'Bearer ' +  this.authorizationService.accessTokenValue);
    this.headers = this.headers.set('x-company-id', this.userService.activeCompanyId + '');
  }

  fetchClipsByCompany(options) {
    let filterText = "";
    let clientTags = "";
    let adminTags = "";
    if (options.filter) {
        if (options.filter.fulltext) {
          filterText = "&filter[name]=" +  options.filter.fulltext
        }
        if (options.filter.clientTags.length>0) {
          
            clientTags = "&clientTags=";
            options.filter.clientTags.forEach((tag, index) => {
              clientTags+=tag.value;
              if(index != options.filter.clientTags.length -1 ) {
                clientTags+","
              }
            });

        }

        if (options.filter.adminTags.length>0) {
            adminTags = "&adminTags=";
            options.filter.adminTags.forEach((tag, index) => {
              adminTags+=tag.value;
              if(index != options.filter.adminTags.length -1 ) {
                adminTags+","
              }
            });
        }
    }

    const url = `${this.serverUrl}/api2/clips?orderDir=-1&offset=${options.offset}&limit=${options.limit}${filterText}${clientTags}${adminTags}`;
    return this.http.get<ContentClip[]>(url, { headers: this.headers, observe: 'response'} );
  }

  fetchClipsByTag(tag: Tag) {
    const tagParam = `?tag=${ tag.value }&group=${ tag.group }`;
    const url = `${this.serverUrl}/api2/clips${tagParam}`;
    return this.http.get<ContentClip[]>(url, { headers: this.headers });
  }

  fetchClipById(id: number) {
    const url = `${this.serverUrl}/api2/clip/${id}`;
    return this.http.get<ContentClip>(url, { headers: this.headers });
  }

  attachTag(tag: Tag, clips: number[]) {
    const url = `${this.serverUrl}/api2/clips/attach-tag?ids=${clips.join()}`;
    return this.http.put<Tag[]>(url, {tag}, { headers: this.headers });
  }

  openClipUploadDialog(type?: string) {
    return this.clipUploadDialog.next(type);
  }

  openClipDetailDialog(clip: ContentClip) {
    return this.clipDetailDialog.next(clip);
  }

  openClipSelectionDialog(selectedClips: number[]) {
    return this.clipSelectionDialog.next(selectedClips);
  }

  updateClip(clip: ContentClip) {
    const url = `${this.serverUrl}/api2/clip`;
    return this.http.put<any>(url, clip, { headers: this.headers });
  }

  saveClip(clip: ContentClip) {
    this.headers.set('companyId', this.userService.activeCompanyId.toString());
    const url = `${this.serverUrl}/api2/clip`;
    return this.http.post<any>(url, clip, { headers: this.headers });
  }

  deleteClip(clipId: number) {
    const url = `${this.serverUrl}/api2/clip/${clipId}`;
    return this.http.delete<any>(url, { headers: this.headers });
  }

  checkClips(clips: ContentClip[]) {
    this.checkedClips.next(clips);
  }

  updateClipsList() {
    this.updateClips.next(true);
  }

  uploadFile(formData: FormData) {

    this.headers.append('Content-Type', 'application/x-www-form-urlencoded');
    const url = `${this.serverUrl}/api2/file/upload`;
    console.log('upload file');
    return this.http.post<any>(url, formData, {
      reportProgress: true,
      observe: 'events',
      headers:  this.headers
    }).pipe(map((event) => {
      switch (event.type) {
        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };
        case HttpEventType.Response:
          return event.body;
        default:
          return `Unhandled event: ${event.type}`;
      }
    })
    );
  }
  getYoutubeInfo(url, params) {
    return this.http.get<any>(url, params);
  }
}
