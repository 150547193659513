<div class="clips-upload dialog">
    <div  mat-dialog-title class="clips-upload__title">
        <h2 *ngIf="type==='video'" i18n>Upload videa</h2>
        <h2 *ngIf="type==='external' || type==='live'" i18n>Vložení odkazu</h2>
        <h2 *ngIf="type==='stream'" i18n>Vložení live videa</h2>
        <button class="dialog__close" mat-icon-button mat-dialog-close i18n-aria-label aria-label="Zavřít dialog">
            <mat-icon>clear</mat-icon>
        </button>
    </div>

    <div class="dialog__content" *ngIf="!isCompleted && !isLoadingResults">
        <ngx-dropzone *ngIf="type==='video'" class="ngx-dropzone" (change)="onSelect($event)"  [accept]="'video/avi, video/mpeg,  video/m4v, video/mp4, video/quicktime, image/png, image/jpeg'">
            <ngx-dropzone-label><h3 i18n>Přetáhní nebo vyber soubory.</h3><br><span i18n>Soubor musí být ve formátu mp4, m4v, mpeg, avi, mov, jpg, png</span></ngx-dropzone-label>
        </ngx-dropzone>
        <div *ngIf="type==='external' || type==='live'"> 
            <h3 i18n>Prosím zadejte odkaz </h3>
            <p class="p--small" i18n>*odkaz musí začínat http, https, rtsp</p>
            <mat-form-field class="mat-mdc-form-field--fullwidth" >
                <input  matInput i18n-placeholder placeholder="Odkaz" required  [formControl]="source">
                <mat-error *ngIf="source.hasError('pattern')" i18n>Napište url ve správném formátu</mat-error>
                <button mat-button *ngIf="source.value" matSuffix mat-icon-button i18n-aria-label aria-label="Vymazat" (click)="resetClip(0)">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>
    </div>

    <div *ngIf="isLoadingResults" class="clips-upload__progress dialog__content">
        <div *ngFor="let file of files" class="clips-upload__progress__item">
            <h3>{{ file.name }}</h3>
            <div *ngIf="file.progress < 100; else clipProcess" class="clips-upload__progress__spinner">
                <span>{{ file.progress }}%</span>
                <mat-progress-bar  mode="determinate" [value]="file.progress"></mat-progress-bar>
            </div>
        </div>
    </div>

    <div class="dialog__content" *ngIf="isCompleted && !uploadError">
        <div class="clips-upload__step" *ngIf="type==='video'">
            <div class="clips-upload__current">
                <mat-horizontal-stepper [linear]="isLinear" #stepper (selectionChange)="changeStep($event)">
                    <mat-step *ngFor="let clip of clips" >
                        <ng-template matStepLabel>{{clip.name}} </ng-template>
                        <div class="clips-upload__current__body">
                            <button  matStepperPrevious class="clips-upload__current__arrow"><mat-icon>keyboard_arrow_left</mat-icon></button>
                            <app-clip-detail-forms [clip]="clip" (validated)="checkFormValidation($event)"></app-clip-detail-forms>
                            <app-clip-player [clip]="clip" class="clips__player"></app-clip-player>
                            <button  matStepperNext class="clips-upload__current__arrow"><mat-icon>keyboard_arrow_right</mat-icon></button>
                        </div>
                    </mat-step>
                </mat-horizontal-stepper>
            </div>
            <div class="clips-upload__separator"></div>
            <div class="clips-upload__global">
                <app-clips-multiple-actions  [template]="'upload-actions'" [selection]="clips" ></app-clips-multiple-actions>
            </div>
        </div>
        <div class="clips-upload__single" *ngIf="type==='external' || type==='live'">
            <mat-form-field class="mat-mdc-form-field--fullwidth" >
                <input  matInput i18n-placeholder placeholder="Odkaz" required  [formControl]="source">
                <button mat-button *ngIf="source.value" matSuffix mat-icon-button i18n-aria-label aria-label="Vymazat" (click)="resetClip(0)">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
            <div class="clips-upload__single__body" *ngIf="!externalSourceError; else sourceErrorMessage">
                <app-clip-detail-forms [clip]="clips[0]" (validated)="checkFormValidation($event)"></app-clip-detail-forms>
                <app-clip-player *ngIf="!clipError; else clipErrors" [clip]="clips[0]" (videoLoaded)="updateClipProperties($event, 0)"></app-clip-player>
            </div>
        </div>
    </div>
    <div  *ngIf="uploadError" class="clips-upload__error  dialog__content">
        <mat-icon>error</mat-icon>
        <p i18n>Nahrávání na server se nezdařilo. Opakujte prosím později nebo kontaktujte podporu. </p><a href="mailto:podpora@ismedia.cz">podpora@ismedia.cz</a>
    </div>
    <div mat-dialog-actions class="dialog__footer dialog__footer--reverse">
        <button mat-raised-button color="primary" *ngIf="files.length>0" [disabled]="!isUploadValid" (click)="updateClips()" ><span i18n>Uložit</span><mat-icon color="white">check</mat-icon></button>
        <button mat-raised-button color="primary" *ngIf="files.length === 0" [disabled]="!isUploadValid" (click)="saveClip(0)" ><span i18n>Uložit</span><mat-icon color="white">check</mat-icon></button>
        <div *ngIf="clips.length > 1 && isCompleted">
            <button i18n-matTooltip matTooltip="Nelze smazat klip který se zpracovává"  matSuffix mat-raised-button color="transparent" [disabled]="clips[selectedIndex].isTranscoding" (click)="deleteClip(selectedIndex)" ><span i18n>Smazat aktivni klip</span><mat-icon color="white">delete</mat-icon></button>
        </div>
    </div>
</div>

<ng-template #loading>
    <app-loading></app-loading>
</ng-template>

<ng-template #clipErrors>
    <div >
        <h3 i18n>Chyba</h3>
        <p i18n>Vložený odkaz je videostream </p>
    </div>
</ng-template>

<ng-template #sourceErrorMessage>
    <div >
        <h3 i18n>Chyba</h3>
        <p i18n>Vložený odkaz neobsahuje video </p>
    </div>
</ng-template>

<ng-template #clipProcess>
    <div class="clips-upload__progress__process">
        <p i18n>Klip se právě zpracovává. Počkejte prosím</p>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</ng-template>


