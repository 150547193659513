import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CampaignPreview } from "src/app/models/campaign-preview.model";
import { Campaign } from "src/app/models/campaign.model";
import { Tag } from "src/app/models/tag.model";
import { ConfirmDialogComponent } from "src/app/utils/confirm-dialog/confirm-dialog.component";
import { CustomUtils } from "src/app/utils/custom-utils.component";
import { CampaignsService } from "../campaigns.service";

@Component({
  selector: "app-campaigns-multiple-actions",
  templateUrl: "./campaigns-multiple-actions.component.html",
  styleUrls: ["./campaigns-multiple-actions.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CampaignsMultipleActionsComponent implements OnInit {
  @Input() selection: Campaign[];
  @Input() template = "multiple-actions";
  @Output() selectionChange = new EventEmitter();

  allTags: Tag[] = [];
  commonTags: Tag[] = [];
  commonDescription: string;
  clipMultpileGroup: FormGroup;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private campaignsService: CampaignsService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.clipMultpileGroup = this.fb.group({
      commonDescription: new FormControl(this.commonDescription),
    });

    this.clipMultpileGroup
      .get("commonDescription")
      .valueChanges.subscribe((value) => {
        this.selection.forEach((campaign) => {
          campaign.description = value;
        });
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes.selection);
    if (changes.selection) {
      this.selection = changes.selection.currentValue;
      this.checkCommonTags();
    }
  }

  checkCommonTags() {
    const foundTags: Tag[] = [];
    this.commonTags = [];
    this.selection.forEach((campaign, index) => {
      this.commonTags = foundTags.concat(
        campaign.tags.filter((item) => foundTags.indexOf(item) < 0)
      );
    });

    this.selection.forEach((campaign, index) => {
      this.commonTags = this.commonTags.filter((foundTag) => {
        return campaign.tags.some((tag) => tag.value === foundTag.value);
      });
    });
  }

  async deleteSelected() {
    let index = 0;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "300px",
      panelClass: "",
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.selection
          .reduce((p, campaign) => {
            return p.then(() => {
              return new Promise((resolve, reject) => {
                index++;
                this.campaignsService.deleteCampaign(campaign.id).subscribe(
                  () => {
                    let message = $localize`odstraněno`;
                    this.snackBar.open(
                      index + "/" + this.selection.length + " " + message,
                      "",
                      {
                        duration: 3000,
                      }
                    );
                    resolve();
                  },
                  (error) => {
                    let message = $localize`se nepodařilo smazat`;
                    this.snackBar.open(
                      index + "/" + this.selection.length + " " + message,
                      "",
                      {
                        duration: 3000,
                      }
                    );
                    console.log(error);
                    resolve();
                  }
                );
              });
            });
          }, Promise.resolve())
          .then(() => {
            this.campaignsService.updateCampaignsList();
            this.selectionChange.emit(false);
          });
      }
    });
  }

  remove(removedTag: Tag) {
    this.selection.forEach((campaign) => {
      campaign.tags = campaign.tags.filter((item) => item !== removedTag);
    });
  }

  attach(tag: Tag) {
    this.selection.forEach((campaign) => {
      const tags = campaign.tags;
      campaign.tags = tags.concat(
        this.commonTags.filter((item) => campaign.tags.indexOf(item) < 0)
      );
    });
  }
  async saveSelected() {
    let index = 0;
    this.selection
      .reduce((p, campaign) => {
        return p.then(() => {
          return new Promise((resolve, reject) => {
            this.campaignsService.updateCampaign(campaign).subscribe(
              (result) => {
                index++;
                let message = $localize`uloženo`;
                this.snackBar.open(
                  index + "/" + this.selection.length + " " + message,
                  "",
                  {
                    duration: 3000,
                  }
                );
                resolve();
              },
              (error) => {
                index++;
                let message = $localize`se nepodařilo uložit`;
                this.snackBar.open(
                  index + "/" + this.selection.length + " " + message,
                  "",
                  {
                    duration: 3000,
                  }
                );
                resolve();
              }
            );
          });
        });
      }, Promise.resolve())
      .then(() => {
        this.campaignsService.updateCampaignsList();
        console.log("All files transferred");
      });
  }
  finishCampaigns() {
    let actualDate = new Date().toISOString();
    this.selection.forEach((campaign) => {
      if (
        (campaign.startDate < actualDate && campaign.untilStop) ||
        (campaign.startDate < actualDate && campaign.endDate > actualDate)
      ) {
        campaign.state = "ended";
        campaign.untilStop = false;
        campaign.endDate = actualDate;
        campaign.endTime = CustomUtils.getActualTime();
        campaign.isActive = true;
      }
    });
    this.saveSelected();
  }
  toggleActiveCampaign(setActive: boolean) {
    this.selection.forEach((campaign) => {
      if (setActive) {
        campaign.state = "active";
        campaign.isActive = true;
        if (campaign.endDate < new Date().toISOString()) {
          campaign.untilStop = true;
        } else if (campaign.startDate > new Date().toISOString()) {
          campaign.startDate = new Date().toISOString();
        }
      } else {
        campaign.state = "paused";
        campaign.isActive = false;
      }
    });
    this.saveSelected();
  }
}
