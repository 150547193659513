import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { User } from '../../models/user.model';
import { UserService } from 'src/app/user/user.service';
import { Title } from '@angular/platform-browser';
import { CustomUtils } from 'src/app/utils/custom-utils.component';
import { TwoFactorComponent } from 'src/app/user/two-factor/two-factor.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
selector: 'app-top-bar',
templateUrl: './top-bar.component.html',
styleUrls: ['./top-bar.component.scss'],
encapsulation: ViewEncapsulation.None
})
export class TopBarComponent implements OnInit {
    user: User;
    companyId: number;
    pageTitle =  "";
    languagesIcons = {
        en: "assets/img/flags/gb.svg",
        cs: "assets/img/flags/cz.svg"
    }
    activeLangugeCode = "en"

    constructor( 
            public dialog: MatDialog,
            public titleService: Title,
            private userService: UserService, 
            private authorizationService: AuthorizationService) 
    { }

    ngOnInit() {
        this.activeLangugeCode = localStorage.getItem('language');
        if(!this.activeLangugeCode) {
            this.activeLangugeCode = CustomUtils.getBrowserLanguageCode();
        }
        this.companyId = this.userService.activeCompanyId;
        this.user = this.userService.user;
       
    }

    getTitle() {
        return  this.pageTitle;
    }
    selectLanguage(code: string) {
        this.activeLangugeCode = code;
        localStorage.setItem('language', this.activeLangugeCode);
        window.location.replace('/' + this.activeLangugeCode + '/index.html'); 
    }
    logout() {
        this.authorizationService.logout();
    }

    public get languageIcon():string {
        return this.languagesIcons[this.activeLangugeCode];
    }

    enable2FA() {
      const dialogRef =  this.dialog.open(TwoFactorComponent, {
        disableClose: true,
        width: "800px",
        maxHeight: "100vh",
        maxWidth: "95vw",
        panelClass: "two-factor",
        data: {},
      });
    }
   
}
