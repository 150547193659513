<div class="devices-menu-list" [matTooltip]="devices.length===0 ? noDevice : assigned">
    <button  mat-icon-button class="devices-menu-list__toggle" (click)="openDevicesMenu()" [matMenuTriggerFor]="menu" [disabled]="devices.length==0" i18n-aria-label aria-label="Přiřazené zařízení">
        <mat-icon [color]="devices.length>0 ? 'primary' : ''">important_devices</mat-icon>
    </button>
    <mat-menu #menu="matMenu"  class="devices-menu-list__window">
        <h5 i18n>Přiřazené zařízení</h5>
        <div i18n-aria-label aria-label="Přiřazené zařízení" class="devices-menu-list__list" *ngIf="devicesList; else loading">
            <div  *ngFor="let device of devicesList" class="devices-menu-list__item" ><span class="devices-menu-list__id">{{device.id}}</span>{{device.name}}</div>
        </div>
    </mat-menu>
</div>

<ng-template #loading>
    <app-loading></app-loading>
</ng-template>

