
<div  class="homepage__show" [class.is-active]="!isNavOpen">
    <button (click)="toggleNav()" mat-button color="primary" ><mat-icon>menu</mat-icon><span>Menu</span></button>
</div>
<div  class="homepage__hide" [class.is-active]="isNavOpen" >
    <button   class="mat-mdc-mini-fab--white"  (click)="toggleNav()"  mat-mini-fab  >
        <mat-icon color="primary" class="mat-icon--white">menu_open</mat-icon>
    </button>
</div>
<div class="homepage__topbar" [class.hidden-nav]="!isNavOpen">
    <app-top-bar></app-top-bar>
</div>
<mat-drawer-container class="homepage">
    <mat-drawer class="homepage__menu flex flex-colum justify-between" #drawer mode="side" [opened]="isNavOpen" >
        <div class="homepage__content" >   
            <div class="homepage__company">
                <div class="homepage__logo">
                    <img [src]="companyService.logo ? companyService.logo : '/assets/img/logos/is-media-logo.jpg'">
                </div>
                <div class="homepage__company-select" *ngIf="userService.hasRole('admin')">
                <app-company-select [companyId]="companyService.companyId"></app-company-select>
                </div>
            </div>
            <app-main-navigation class="main-navigation" ></app-main-navigation>
            <div class="homepage__menu-footer">
                <a href="https://ismedia.cz" target="_blank" class="homepage__product-logo">
                <img src="assets/img/logos/direktor_logo_RGB.svg">
                </a>
                <div class="homepage__contact">
                <a  href="mailto:podpora@ismedia.cz">podpora@ismedia.cz</a>
                <a href="tel:+420730511422">+420 730 511 422</a>
                </div>
                <a class="homepage__agreements"  target="_blank"  href="assets/documents/obchodni_podminky.pdf">
                <mat-icon>download</mat-icon>
                <span i18n>Obchodní podmínky</span>
                </a> 
            </div>
        </div>
    </mat-drawer>
    <mat-drawer-content class="homepage__content" [class.hasMenuToggle]="!isNavOpen">
            <div class="homepage__container">
                <router-outlet name="content"></router-outlet>
            </div>
            <router-outlet name="dialog"></router-outlet>
    </mat-drawer-content>
</mat-drawer-container>