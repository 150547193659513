import { Injectable } from '@angular/core';
import { AuthorizationService } from '../services/authorization.service';
import { LoggedUser, User } from '../models/user.model';
import { Company } from '../models/company.model';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
providedIn: 'root'
})
export class UserService {
  private loggedUser: User | null = null;
  public companyChangeSubject = new Subject<Company>();
  private apiServer: string = `${environment.serverProtocol}://${environment.serverAddress}:${environment.serverPort}`;

  headers = new HttpHeaders({
    "x-company-id": "none",
    "Content-Type": "application/json",
  });
  constructor(private router: Router, private http: HttpClient) {
    try {
      let localUser = localStorage.getItem('currentUser');
      if (localUser) {
        this.loggedUser = JSON.parse(localUser);
        this.headers = this.headers.set('Authorization', 'Bearer ' +  this.loggedUser.accessToken);
        this.headers = this.headers.set('x-company-id', this.activeCompanyId ? this.activeCompanyId.toString() : 'none');
      }
    } catch (error) {
      console.log("Can't find user");
      this.router.navigate(['/login']);
    }
  }

  get user(): User {
      return this.loggedUser;
  }

  get userRoles(): string[] {
      return this.loggedUser.role;
  }

  get userName(): string {
      return this.loggedUser.name;
  }
  public saveLocalUser(user: User) {
   
    this.loggedUser = user;
    this.loggedUser.selectedCompany = this.loggedUser.company;
    this.headers = this.headers.set('Authorization', 'Bearer ' +  this.loggedUser.accessToken);
    this.headers = this.headers.set('x-company-id', this.activeCompanyId ? this.activeCompanyId.toString() : 'none');
    localStorage.setItem('currentUser', JSON.stringify(this.loggedUser));
  }

  public hasRoles(roles: string[]): boolean {
      let hasPermission = false;

      roles.forEach((role) => {
      if (this.loggedUser.role.includes(role)) {
          hasPermission = true;
          return;
      };
      });
      return hasPermission;
  }

  public hasRole(role: string): boolean {
      if (this.loggedUser) {
      return this.loggedUser.role.includes(role);
      } else {
      return false;
      }
  }


  public hasPrivilege(privilege: string): boolean {
      if (this.loggedUser) {
      return  Object.keys(this.loggedUser.privileges).some(key => key === privilege);
      } else {
      return false;
      }
  }



  get isCompanySelected(): boolean {
    return this.loggedUser!.selectedCompany !== null;
  }

  get isAdmin() {
      return this.user.isAdmin;
  }

  get activeCompany(): Company | null {
    return this.loggedUser!.selectedCompany!;
  }

  set activeCompany(company: Company | null) {
    if (this.loggedUser && company) {
      this.loggedUser.selectedCompany = company;
      this.companyChangeSubject.next(company);
      localStorage.setItem('currentUser', JSON.stringify(this.loggedUser));
    }
  }

  get activeCompanyId(): number | null {
    if (this.loggedUser?.selectedCompany) {
      return this.loggedUser.selectedCompany.companyId ? this.loggedUser.selectedCompany.companyId : null;
    }
    return null;
  }

  public setActiveCompany(company: Company | null, reload?: boolean) {
    this.activeCompany = company;
    if (reload) location.replace("/");
  }

  init2FA() {
    const url = `${this.apiServer}/api2/user/otp`;
    return this.http
      .get<any>(url, {headers: this.headers})
  }

  complete2FA(data: any) {
    const url = `${this.apiServer}/api2/user/otp`;
    return this.http
      .post<any>(url, data, {headers: this.headers})
  }
}
