<div class="playlist-select"  >
    <mat-form-field  class="mat-mdc-form-field--fullwidth" *ngIf="playlists; else loading">
       
        <input type="text"
            #playlistInput
            placeholder="Vyberte playlist"
            i18n-placeholder
            i18n-aria-label
            aria-label="Playlist"
            matInput
            [formControl]="slectedName"
            [matAutocomplete]="auto"
        >
        <mat-autocomplete [autoActiveFirstOption]="true" [panelWidth]="300" class="campaign-type__autocomplete" autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="onSelectPlaylist($event)">
            <mat-option [value]="null">
                <mat-icon color="primary">add</mat-icon><span i18n>Přidat nový</span>
            </mat-option>
            <mat-option  *ngFor="let playlist of playlists" [value]="playlist.id" >
                {{ playlist.name }}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div>

<ng-template #loading>
    <app-loading [size]="'xsmall'"></app-loading>
</ng-template>