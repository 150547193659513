import { Component, OnInit, Inject, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-custom-message",
  templateUrl: "./custom-message.component.html",
  styleUrls: ["./custom-message.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CustomMessageComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CustomMessageComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {}

  ngOnInit() {}

  close() {
    this.dialogRef.close();
  }
}
