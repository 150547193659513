import { Component, OnInit, Inject, ViewEncapsulation } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ContentClip } from "src/app/models/content-clip.model";
import { ClipsService } from "../clips.service";

@Component({
  selector: "app-clips-selection",
  templateUrl: "./clips-selection.component.html",
  styleUrls: ["./clips-selection.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ClipsSelectionComponent implements OnInit {
  selection: ContentClip[];
  selectedClips: ContentClip[];

  constructor(
    public dialogRef: MatDialogRef<ClipsSelectionComponent>,
    private clipService: ClipsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.selectedClips = this.data.selection;
  }
  saveSelect() {
    this.dialogRef.close();
    this.clipService.checkClips(this.selection);
  }
  updateSelection(selection) {
    this.selection = selection;
  }
}
