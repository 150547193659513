

<div class="main-navigation__list">
    <div class="main-navigation__category" i18n>Plánování</div>
    <button class="main-navigation__item" routerLinkActive="is-active" mat-button color="primary" routerLink="/dashboard" ><mat-icon color="primary">dashboard</mat-icon><span i18n>Dashboard</span></button>
    <button class="main-navigation__item" routerLinkActive="is-active" mat-button color="primary" routerLink="/campaigns" ><mat-icon  color="primary">date_range</mat-icon><span i18n>Kampaně</span></button>
    <button class="main-navigation__item" routerLinkActive="is-active" mat-button color="primary" routerLink="/devices" ><mat-icon  color="primary">important_devices</mat-icon><span i18n>Zařízení</span></button>
    <button class="main-navigation__item" routerLinkActive="is-active" mat-button color="primary" routerLink="/clips" ><mat-icon  color="primary"  >music_video</mat-icon><span i18n>Videa a obsah</span></button>
    <button class="main-navigation__item" routerLinkActive="is-active" mat-button color="primary" routerLink="/tags" ><mat-icon  color="primary">label</mat-icon><span i18n>Štítky</span></button>
    <div class="main-navigation__category" i18n>Nástroje</div>
    <!--button class="main-navigation__item" routerLinkActive="is-active" mat-button color="primary" routerLink="/editor"><mat-icon>format_shapes</mat-icon>Editopr prezentací</button-->
    <button class="main-navigation__item" routerLinkActive="is-active" mat-button color="primary" routerLink="/modules" ><mat-icon  color="primary">extension</mat-icon><span i18n>Moduly</span></button>
    <button class="main-navigation__item" routerLinkActive="is-active" mat-button color="primary" routerLink="/layouts" ><mat-icon  color="primary">view_compact</mat-icon><span i18n>Rozložení</span></button >
    <button class="main-navigation__item" routerLinkActive="is-active" mat-button color="primary" routerLink="/statistics" ><mat-icon  color="primary">bar_chart</mat-icon><span i18n>Statistiky</span></button>
    <!--button class="main-navigation__item"  mat-button color="primary" (click)="openTimes()"><mat-icon>bar_chart</mat-icon>Test casu</button-->
</div>

