import { CustomUtils } from "../utils/custom-utils.component";
import { ContentClip } from "./content-clip.model";
import { Tag } from "./tag.model";

export class CampaignPreview {
  id: number;
  name: string;
  companyId: number;
  companyName: string;
  description: string;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  untilStop: boolean;
  dayEndTime: string;
  daysSelected: Array<string>;
  type: string;
  weight: number;
  interval: number;
  fixedStartTime: string;
  fixedEndTime: string;
  totalCampaignTime: string;
  campaignPreviewUrl: string;
  state: string;
  tags: Array<Tag>;
  isActive: boolean;
  edited: string;
  preview: {
    small: {
      url: string;
    };
    large: {
      url: string;
    };
  };
  color: string;
  isSelected: boolean;
  author?: string;
  constructor() {
    this.id = -1;
    this.name = "";
    this.companyId = -1;
    this.companyName = "";
    this.description= "";
    this.startDate= CustomUtils.getDefaultDate(0, { h: 0, m: 0 }).toISOString();
    this.startTime = CustomUtils.getDefaultDate(0, { h: 0, m: 0 }).format("HH:mm"),
    this.endDate= CustomUtils.getDefaultDate(7, { h: 23, m: 55 }).toISOString();
    this.endTime = CustomUtils.getDefaultDate(7, { h: 23, m: 55 }).format("HH:mm");
    this.untilStop = false;
    this.daysSelected =[];
    this.type =  "weight";
    this.weight = 50;
    this.interval = 2;
    this.fixedStartTime =  CustomUtils.getActualTime();
    this.fixedEndTime =  CustomUtils.getActualTime(); // TODO remove
    this.totalCampaignTime = "0";
    //this.campaignPreviewUrl: string;
    this.state = "";
    this.tags = [];
    this.isActive= true;
    this.preview = {
      small: {
        url: ""
      },
      large: {
        url: ""
      }
    };
    this.color = CustomUtils.getRandomColor();
    this.isSelected = false;
  }
}
