
<div class="clip-item clip-item--small" [ngClass]="{'clip-item--has-controls': controls, 'clip-item--selected' : isChecked, 'clip-item--multiple-actions' : multipleActions}" >
        <form [formGroup]="clipFormGroup">
            <div class="clip-item__options"  *ngIf="type==='editable'">
                <mat-button-toggle-group formControlName="_clipOptions" class="clip-item__fullscreen" name="clipOptions" i18n-aria-label aria-label="Možnosti klipu" multiple>
                    <mat-button-toggle value="fullscreen" i18n-matTooltip matTooltip="Spouštět klip přes celou obrazovku">
                        <mat-icon>screenshot_monitor</mat-icon>
                    </mat-button-toggle>
                </mat-button-toggle-group>
                <button class="clip-item__more" mat-icon-button [matMenuTriggerFor]="clipOptionsMenu" i18n-matTooltip matTooltip="Více možností klipu"><mat-icon>more_vert</mat-icon></button>
                <mat-menu #clipOptionsMenu="matMenu">
                    <button mat-menu-item (click)="openClipDetail()"  i18n-aria-label aria-label="Upravit detail klipu">
                        <mat-icon>edit</mat-icon>
                        <span i18n>Upravit detail</span>
                    </button>
                    <button *ngIf="type==='editable'" mat-menu-item (click)="remove()"  i18n-aria-label aria-label="Smazat klip">
                        <mat-icon>delete</mat-icon>
                        <span i18n>Odebrat klip</span>
                    </button>
                </mat-menu>
            </div>
        </form>
        <div class="clip-item__transcoding" *ngIf="clip.isTranscoding" (click)="select()">
            <span i18n>Klip se právě zpracovává</span>
            <app-loading [size]="'small'"></app-loading>
        </div>

        <div class="clip-item__checklayer" *ngIf="type==='checkable'" (click)="select($event)" >
            <button *ngIf="isChecked" class="clip-item__selection-counter" i18n-matTooltip matTooltip="Vybraný klip" mat-mini-fab aria-label="Edit clip">
                <span class="clip-item__clip-index">{{ selectedIndex }}.</span>
            </button>
        </div>

        <button mat-raised-button (click)="selectClone()" *ngIf="isChecked && type==='checkable'" class="clip-item__add-clone mat-mdc-raised-button--small" i18n-matTooltip matTooltip="Opakovat klip" aria-label="repeat clip" i18n>
            Opakovat
        </button>

        <mat-card class="clip-item__body mat-elevation-z2" >
            <div class="clip-item__checkbox" *ngIf="type==='multiple-actions'">
                <mat-checkbox [(ngModel)]="isChecked"  color="primary" (ngModelChange)="select()"></mat-checkbox>
            </div>

            <div class="clip-item__preview" (click)="openClipDetail()" (mouseenter)="toggleVideoPreview(true)" (mouseleave)="toggleVideoPreview(false)">
                <div  class="clip-item__preview__background"  [ngClass]="{'is-active ': !showVideo}">
                    <img loading="lazy" (error)="addPlaceholder($event)" [src]="clip.preview.small.url" alt="video">
                </div>
                <div  class="clip-item__preview__background" *ngIf="startPreload" [ngClass]="{'is-active ': showVideo}">
                    <app-clip-player (videoLoaded)="showVideo = true" [clip]="clip" class="clip-item__player" [autoplay]="true" [controls]="false" [muted]='true'></app-clip-player>
                </div>
            </div>
            <mat-card-header class="clip-item__editable-header" *ngIf="type==='editable'">
                <form [formGroup]="clipFormGroup">
                    <mat-card-title (click)="openClipDetail()" [matTooltipPosition]="'above'"  [matTooltip]="clip.name">{{ (clip.name.length>32)? (clip.name | slice:0:32)+'..':(clip.name)  }}</mat-card-title>
                    <div class="clip-item__duration" *ngIf="!isVideo">
                        <mat-form-field  class="is-ui mat-mdc-form-field mat-mdc-form-field--small">
                            <input  matInput i18n-placeholder placeholder="Čas trvání" type="number" formControlName="duration" >
                            <span matSuffix>sec</span>
                        </mat-form-field>
                        <span  class="clip-item__header__duration" *ngIf="isVideo">{{ clip.duration | number:'1.0-0'}}&nbsp;s</span>
                    </div>
                    <div class="clip-item__volume" *ngIf="isVideo">
                        <mat-slider color="primary" min="-10" max="0" step="1" showTickMarks  >
                            <input matSliderThumb formControlName="volume">
                        </mat-slider>
                        <div class="clip-item__volume__units">{{ percentsToDB(this.clip.volume) }} dB</div>
                        <button  color="primary" mat-icon-button (click)="muteVideo()" >
                            <mat-icon i18n-matTooltip matTooltip="Zvuk klipu je vypnutý" *ngIf="clip.mute">volume_off</mat-icon>
                            <mat-icon i18n-matTooltip matTooltip="Zvuk klipu je zapnutý" *ngIf="!clip.mute">volume_up</mat-icon>
                        </button>
                    </div>
                </form>
            </mat-card-header>
            <mat-card-header class="clip-item__header"  *ngIf="type!=='editable'">
                <mat-card-title  (click)="openClipDetail()" [matTooltip]="clip.name">{{ (clip.name.length>32)? (clip.name | slice:0:32)+'..':(clip.name)  }}</mat-card-title>
                <span  class="clip-item__header__duration" *ngIf="isVideo">{{ clip.duration | number:'1.0-0'}}s</span>
            </mat-card-header>
        </mat-card>
</div>
