<div #layoutPreview class="layout-preview" [ngClass]="'layout-preview--'+previewTemplate" >
    <div class="layout-preview__wrapper">
        <div class="layout-preview__body" [ngStyle]="{'width.px' :calculateDimension(layout.width),  'height.px' :calculateDimension(layout.height)}" >
            <div class="layout-preview__block" [class.is-strip]="region.type=='strip'" [ngStyle]="{'top.%': yToPercents(region.top), 'left.%': xToPercents(region.left), 'width.%' : xToPercents(region.width), 'height.%': yToPercents(region.height)}" *ngFor="let region of layout.regions" >
                <button *ngIf="region.type=='strip' && previewTemplate!=='small'">{{ region.name }}</button>
                <span *ngIf="region.type=='dynamic' && previewTemplate!=='small'">{{ region.name }}</span>
                <span *ngIf="region.type==null && previewTemplate!=='small'">{{ region.name }}</span>
            </div>
        </div>
    </div>
</div>

<ng-template #loading>
    <app-loading></app-loading>
</ng-template>