<form *ngIf="!isLoadingResults; else loading" class="playlist-detail" >
    <div class="playlist-detail__settings">
        <p i18n>Kampaň je zařazena v playlistu: </p>
        <div class="playlist-detail__name">
            <app-playlist-select (playlistIdChanged)="onSelectedNewPlaylist($event)" [playlist]="playlist"></app-playlist-select>
            <button i18n-matTooltip matTooltip="Úprava kampaně" color="primary" class="mat-mdc-mini-fab--small" mat-mini-fab  i18n-aria-label aria-label="Upravit kampaň" (click)="openPlaylistEdit()" >
                <mat-icon>edit</mat-icon>
            </button>
        </div>
        <p i18n>který má nastavenou intenzitu opakování na: </p>
        <div class="playlist-detail__slider">
            <span class="playlist-detail__slider__label" i18n>Malou</span>
            <mat-slider
                color="primary"
                [max]=100
                [min]=1
                showTickMarks 
                discrete >
                <input matSliderThumb (valueChange)="playlistValueChanged()" [(ngModel)]="playlist.weight">
            </mat-slider>
            <span class="playlist-detail__slider__label" i18n>Velkou</span>
        </div>
    </div>
    <div class="playlist-detail__campaigns" *ngIf="template==='sort'">
        <h4 i18n>Pořadí kampaní v playlistu</h4>
        <div class="playlist-detail__list" [sortablejs]="playlist.campaigns" [sortablejsOptions]="options">
            <div class="playlist-detail__item" *ngFor="let campaignItem of playlist.campaigns" [ngClass]="{'is-active' : campaignItem.id === campaign.id}"> 
                <div class="playlist-detail__handler"><mat-icon>drag_indicator</mat-icon></div>
                <h3>
                    {{ campaignItem.name }}
                </h3>
            </div>
        </div>
    </div>
</form>

<ng-template #loading>
    <app-loading></app-loading>
</ng-template>
