import {
  Component,
  OnInit,
  Input,
  HostListener,
  ViewEncapsulation,
} from "@angular/core";
import { CampaignPreview } from "src/app/models/campaign-preview.model";
import { CampaignsService } from "../campaigns.service";
import { ContentClip } from "src/app/models/content-clip.model";

@Component({
  selector: "app-campaign-thumbnail",
  templateUrl: "./campaign-thumbnail.component.html",
  styleUrls: ["./campaign-thumbnail.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CampaignThumbnailComponent implements OnInit {
  @Input() campaign: any;
  @Input() activeClip?: ContentClip;
  @Input() properties: any;
  @Input() template: string = "preview";
  clipIndex: number;

  @HostListener("document:click", ["$event"])
  clickout(event) {
    const preview = event.target.closest("#campaign-preview");
    if (!preview) {
      this.hidePreview();
    }
  }

  @HostListener("window:keyup", ["$event"])
  keyEvent(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.hidePreview();
    }
  }

  constructor(private campaignsService: CampaignsService) {}

  ngOnInit() {}

  openCampaignDetail(id) {
    this.campaignsService.openCampaignDetail(id);
  }
  hidePreview() {
    this.campaign = null;
  }
}
