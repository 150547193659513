<div class="schedule-time" [ngClass]="{'schedule-time--looped': fixedCampaignOptions.repeatFixedCampaign}">
    <div class="schedule-time__header">
        <div class="schedule-time__filters">
            <mat-slide-toggle color="primary" i18n-matTooltip matTooltip="Bude kampaň opakovaně přehrávat ve zvoleném období" [(ngModel)]="fixedCampaignOptions.repeatFixedCampaign" (change)="changeRepeat($event)" i18n>Opakovat kampaň</mat-slide-toggle>
            <mat-slide-toggle color="primary" [(ngModel)]="fullDay" (change)="changeType($event)" i18n>Zobrazit celý den</mat-slide-toggle>
            <mat-form-field  appearance="fill">
                <mat-label i18n>Měřítko</mat-label>
                <mat-select [disabled]="fixedCampaignOptions.repeatFixedCampaign" (selectionChange)="changeStep($event)" [(value)]="stepInMinutes">
                  <mat-option [value]="5" i18n>5 minut</mat-option>
                  <mat-option [value]="10" i18n>10 minut</mat-option>
                  <mat-option [value]="15" i18n>15 minut</mat-option>
                  <mat-option [value]="30" i18n>30 minut</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="schedule-time__times">
        <div *ngFor="let hour of visibleHours; index as i" class="schedule-time__hour">
            <div class="schedule-time__column-select">
                <button (click)="selectColumn(hour)" mat-icon-button color="primary" i18n-aria-label aria-label="Vybrat celý sloupec">
                    <mat-icon>south</mat-icon>
                </button>
            </div>
            <h2>{{ hour }}</h2>
            <mat-chip-listbox  (chipSelectionChanges)="onChange($event)"  [multiple]="true" [selectable]="true" i18n-aria-label aria-label="Možné časy přehrání">
                <mat-chip-option (mouseover)="overRow(step)" (click)="selectTime(hour, step)" [value]="setFinalTime(hour, step)"  [selected]="isSelected(hour, step)" class="schedule-time__minute" *ngFor="let step of steps">{{ step }}</mat-chip-option>
            </mat-chip-listbox>
            <div *ngIf="i === 0" class="schedule-time__row-select">
                <div *ngFor="let step of steps" class="schedule-time__row-item">
                    <button *ngIf="step == hoveredStep" (click)="selectRow(step)" mat-icon-button color="primary" i18n-aria-label aria-label="Vybrat celý sloupec" >
                        <mat-icon>east</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>