<form class="campaign-name" [formGroup]="nameFormGroup" *ngIf="campaign">
    <div  class="campaign-name__name">
        <mat-form-field class="mat-mdc-form-field--fullwidth" >
            <mat-label i18n>Název kampaně</mat-label>
            <input  matInput i18n-placeholder placeholder="Název Kampaně" required  formControlName="name">
        </mat-form-field>
    </div>
    <div class="campaign-name__description">
        <mat-form-field class="mat-mdc-form-field--fullwidth">
            <mat-label i18n>Popisek kampaně</mat-label>
            <textarea matInput i18n-placeholder placeholder="Popisek kampaně" formControlName="description"></textarea>
        </mat-form-field>
    </div>
    <div class="campaign-name__tags">
        <app-tag-attach  (attached)="attachTag($event)" (removed)="removeTag($event)" [tags]="campaign.tags | tagsGroupFilter:'client'" [group]="'client'" [type]="'campaigns'"></app-tag-attach>
    </div>
    <div class="campaign-name__tags" *ngIf="isAdmin">
        <app-tag-attach  (attached)="attachTag($event)" (removed)="removeTag($event)"  [tags]="campaign.tags | tagsGroupFilter:'admin'" [group]="'admin'" [type]="'campaigns'"></app-tag-attach>
    </div>
    <div class="campaign-name__color">
        <mat-form-field >
            <mat-label i18n>Barva kampaně</mat-label>
            <input  type="color" matInput i18n-placeholder placeholder="Barva Kampaně" formControlName="color">
        </mat-form-field>
    </div>
</form>

