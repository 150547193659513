import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { ContentClip } from "../models/content-clip.model";
import { Campaign, CampaingEndAction } from "../models/campaign.model";
import { CampaignPreview } from "../models/campaign-preview.model";

import { AuthorizationService } from "../services/authorization.service";
import { User } from "../models/user.model";
import { Observable, Subject, of } from "rxjs";
import { Tag } from "../models/tag.model";
import { UserService } from "../user/user.service";
import { Playlist } from "../models/playlist.model";
import { CustomUtils } from "../utils/custom-utils.component";

@Injectable({
  providedIn: "root",
})
export class CampaignsService {
  public campaignDetailDialog = new Subject<number>();
  public newCampaignDialog = new Subject<Campaign>();
  public contentClipSelection = new Subject<ContentClip[]>();
  public updateCampaignList = new Subject<any>();
  public campaignsSelectionDialog = new Subject<any>();
  public checkedCampaigns = new Subject<any>();
  public onRemovePlaylist = new Subject<boolean>();

  public playlistCache: Playlist;

  private headers: HttpHeaders;
  user: User;
  serverUrl: string;

  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    private authorizationService: AuthorizationService,
    private userService: UserService
  ) {
    this.serverUrl = `${environment.serverProtocol}://${environment.serverAddress}:${environment.serverPort}`;
    this.headers = new HttpHeaders();
    this.headers = this.headers.set(
      "Authorization",
      "Bearer " + this.authorizationService.accessTokenValue
    );
    this.headers = this.headers.set(
      "x-company-id",
      this.userService.activeCompanyId + ""
    );
  }
  fetchCompanyCampaigns(ids?: number[]): Observable<Campaign[]> {
    const idsParam = typeof ids === "undefined" ? "" : `?ids=${ids.toString()}`;
    const url = `${this.serverUrl}/api2/campaigns${idsParam}`;
    return this.http.get<Campaign[]>(url, { headers: this.headers });
  }
  fetchCampaignById(id: number): Observable<Campaign> {
    const url = `${this.serverUrl}/api2/campaign/${id}`;
    return this.http.get<Campaign>(url, { headers: this.headers });
  }
  fetchCampaignsByTag(tag: Tag) {
    const tagParam = `?tag=${tag.value}&group=${tag.group}`;
    const url = `${this.serverUrl}/api2/campaigns${tagParam}`;
    return this.http.get<CampaignPreview[]>(url, { headers: this.headers });
  }
  fetchCampaignsByDevices(ids: number[]) {
    const param = `?deviceIds=${ids.join()}`;
    const url = `${this.serverUrl}/api2/campaigns${param}`;
    return this.http.get<CampaignPreview[]>(url, { headers: this.headers });
  }
  saveNewCampaign(campaign: Campaign): any {
    const url = `${this.serverUrl}/api2/campaign`;
    return this.http.post<Campaign>(url, campaign, { headers: this.headers });
  }
  updateCampaign(campaign: Campaign): any {
    const url = `${this.serverUrl}/api2/campaign`;
    return this.http.put<Campaign>(url, campaign, { headers: this.headers });
  }
  deleteCampaign(id: number): any {
    const url = `${this.serverUrl}/api2/campaign/${id}`;
    return this.http.delete<Campaign>(url, { headers: this.headers });
  }
  addClipsToContent(clips) {
    return this.contentClipSelection.next(clips);
  }

  openNewCampaign(newCampaign?: Campaign) {
    // pass new campaign in case you know device
    return this.newCampaignDialog.next(newCampaign);
  }

  openCampaignDetail(campaignId: number) {
    return this.campaignDetailDialog.next(campaignId);
  }

  attachTag(tag: Tag, devices: number[]) {
    const url = `${
      this.serverUrl
    }/api2/campaigns/attach-tag?ids=${devices.join()}`;
    return this.http.put<Tag[]>(url, { tag }, { headers: this.headers });
  }

  updateCampaignsList() {
    this.updateCampaignList.next(true);
  }

  openCampaignsSelection(selected: number[] = []) {
    return this.campaignsSelectionDialog.next(selected);
  }
  removePlaylist() {
    return this.onRemovePlaylist.next(true);
  }
  
  convertCampaignToCampaignPreview(campaign: Campaign ): CampaignPreview {
    let campaignPreview: CampaignPreview = new CampaignPreview();

    for (const key of Object.keys(campaignPreview)) {
      if (key in campaign) { 
        campaignPreview[key] = campaign[key];
      }
  }
    return campaignPreview;
  }
}
