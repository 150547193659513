import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { DevicesService } from '../devices.service';
import { DevicePreview } from 'src/app/models/device-preview.model';

@Component({
  selector: 'app-devices-menu-list',
  templateUrl: './devices-menu-list.component.html',
  styleUrls: ['./devices-menu-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DevicesMenuListComponent implements OnInit {
    noDevice: string = $localize `Není přiřazeno žádné zařízení`;
    assigned: string = $localize `Kliknutím zobrazíte přiřazené zařízení`;
    @Input() devices:number[];
    devicesList: DevicePreview[];
    constructor(private devicesService: DevicesService) { }

    ngOnInit() {
    }

    fetchDevicesByIds() {
        this.devicesService.fetchUserDevices(this.devices).subscribe((data) => {
        this.devicesList = data;
        })
    }

    openDevicesMenu() {
        this.fetchDevicesByIds();
    }
}
