import {
  Component,
  Input,
  SimpleChange,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  OnInit,
  AfterContentInit,
  AfterViewInit,
  ViewEncapsulation,
} from "@angular/core";
import { ContentClip } from "src/app/models/content-clip.model";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
declare let videojs: any;

@Component({
  selector: "app-clip-player",
  templateUrl: "./clip-player.component.html",
  styleUrls: ["./clip-player.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ClipPlayerComponent implements OnChanges, AfterViewInit {
  @Input() clip: ContentClip;
  @Input() autoplay? = false;
  @Input() controls? = true;
  @Input() muted? = false;
  @Output() videoLoaded = new EventEmitter();
  videoPlayer: HTMLVideoElement;
  noPreview: boolean;
  scaleSize = 0.35;

  @ViewChild("videoLivePlayer", { read: ElementRef })
  videoLivePlayer: ElementRef;
  @ViewChild("videoPlayer", { read: ElementRef })
  set mainVideoEl(el: ElementRef) {
    if (el) {
      this.videoPlayer = el.nativeElement;
    }
  }

  youtubeId = null;
  livePlayerObject: any;
  youtubeAttributes: string;
  url: SafeResourceUrl;

  constructor(public sanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges) {
    const clip: SimpleChange = changes.clip;
    this.clip = clip.currentValue;

    if (changes.autoplay) {
      this.autoplay = changes.autoplay.currentValue;
    }

    if (
      this.clip.clipType === "youtube" ||
      this.clip.mimeType === "video/x-youtube-live"
    ) {
      this.youtubeId = this.clip.source.split("v=")[1].split("&")[0];
      const autoplay = this.autoplay ? ";autoplay" : "";
      this.youtubeAttributes =
        "accelerometer; encrypted-media; gyroscope; picture-in-picture" +
        autoplay;
      const url = "https://www.youtube.com/embed/" + this.youtubeId;
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this.videoLoaded.emit();
    }

    if (this.clip.clipType === "live") {
      if (this.clip.mimeType === "application/x-mpegURL") {
        const fileExtension = this.clip.source
          .split(".")
          .pop()
          .split(/\#|\?/)[0];
        this.initVideoJs();
      }
      if (this.clip.mimeType === "application/x-rtsp") {
        this.noPreview = true;
      }
    }

    if (this.clip.clipType === "html") {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.clip.source
      );
    }

    if (this.clip.clipType === "video") {
      this.initVideo();
    }
  }

  ngAfterViewInit() {}
  initVideo() {
    setTimeout(() => {
      this.videoPlayer.src = this.clip.source;
    }, 10);
  }
  initVideoJs() {
    const options = {
      controls: false,
      autoplay: true,
    };
    setTimeout(() => {
      this.livePlayerObject = new videojs(
        this.videoLivePlayer.nativeElement,
        options,
        function onPlayerReady() {
          console.log("stream is ready");
        }
      );
    }, 10);
  }
  onVideoLoaded(event: any) {
    this.videoLoaded.emit(event.target);
  }
  onPlayerError(error: any) {
    console.log(error);
  }

  calculateScale(event: any) {
    let containerWidth = event.target.parentElement.clientWidth;
    this.scaleSize = containerWidth / 1920;
  }
}
