import {
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Playlist } from "src/app/models/playlist.model";
import { ConfirmDialogComponent } from "src/app/utils/confirm-dialog/confirm-dialog.component";
import { PlaylistService } from "../playlist.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SaveSnackbarComponent } from "src/app/utils/save-snackbar/save-snackbar.component";
import { DeleteSnackbarComponent } from "src/app/utils/delete-snackbar/delete-snackbar.component";

@Component({
  selector: "app-playlist-edit",
  templateUrl: "./playlist-edit.component.html",
  styleUrls: ["./playlist-edit.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PlaylistEditComponent implements OnInit {
  isLoadingResults = false;
  playlist: Playlist;
  playlistForm: FormControl;
  isDeleting = false;
  isNew = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private playlistService: PlaylistService,
    public dialogRef: MatDialogRef<PlaylistEditComponent>
  ) {
    this.playlist =
      data.playlist != null ? data.playlist : this.createNewPlaylist();
    this.playlistForm = new FormControl(this.playlist.name);

    this.playlistForm.valueChanges.subscribe((name) => {
      this.playlist.name = name;
    });
    this.isNew = data.playlist ? false : true;
  }
  ngOnInit(): void {}

  createNewPlaylist() {
    let playlist = new Playlist();
    playlist.campaigns = [];
    playlist.name = "";
    playlist.weight = 50;
    return playlist;
  }
  savePlaylist() {
    if (this.playlist.id) {
      this.playlistService.updatePlaylist(this.playlist).subscribe(() => {
        this.openSaveSnackBar();
        this.dialogRef.close(this.playlist);
      });
    } else {
      this.playlistService
        .saveNewPlaylist(this.playlist)
        .subscribe((response) => {
          this.playlist.id = response.playlistId;
          this.dialogRef.close(this.playlist);
        });
    }
  }

  deletePlaylist() {
    const dialogConfirmRef = this.dialog.open(ConfirmDialogComponent, {
      width: "300px",
      panelClass: "",
      data: {
        message: $localize`Všechny kampaně v playlistu budou nastaveny na "Priorita přehávání"`,
        question: $localize`Přejete si opravdu smazat playlist?`,
      },
    });
    dialogConfirmRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.playlistService.deletePlaylist(this.playlist.id).subscribe(() => {
          this.openDeleteSnackBar();
          this.dialogRef.close(null);
        });
      }
    });
  }

  openSaveSnackBar() {
    this._snackBar.openFromComponent(SaveSnackbarComponent, {
      duration: 3000,
    });
  }

  openDeleteSnackBar() {
    this._snackBar.openFromComponent(DeleteSnackbarComponent, {
      duration: 3000,
    });
  }
}
