<mat-card class="account-delete card card--large mat-elevation-z8 ">
    <mat-card-title><mat-icon color="primary">person_cancel</mat-icon><span i18n>Smazání účtu</span></mat-card-title>
    <div class=" account-delete__container">
        <p i18n>Je nám opravdu líto, že chcete odejít.<p>
        <p i18n>Vaš účet bude smazán do 14 dní od odeslání žádosti.</p>
        <div  class="login-screen__actions mt-5">
            <button mat-raised-button (click)="openDeleteComfirm()"  color="primary" i18n>Odeslat žádost o smazání účtu</button>
        </div>
    </div>
</mat-card>
  
  <ng-template #loadingTemplate>
      <app-loading></app-loading>
  </ng-template>
      