import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthorizationService } from '../services/authorization.service';
import { environment } from '../../environments/environment';
import { Layout } from '../models/layout.model';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class LayoutsService {
  serverUrl: string;
  headers: HttpHeaders;

  constructor(private http: HttpClient, private userService: UserService,  private authorizationService: AuthorizationService) {
    this.serverUrl = `${environment.serverProtocol}://${environment.serverAddress}:${environment.serverPort}`;
    this.headers = new HttpHeaders();
    this.headers = this.headers.set('Authorization', 'Bearer ' +  this.authorizationService.accessTokenValue);
    this.headers = this.headers.set('x-company-id', this.userService.activeCompanyId + '');
  }

  public fetchLayoutsByCompany() {
    const url = `${this.serverUrl}/api2/layouts`;
    return this.http.get<Layout[]>(url, { headers: this.headers });
  }

  public fetchLayoutsById(id: number) {
    const url = `${this.serverUrl}/api2/layout/${id}`;
    return this.http.get<Layout[]>(url, { headers: this.headers });
  }

  
}
