<div *ngIf="campaign">
    <div id="campaign-preview" *ngIf="template==='preview'" class="campaign-thumbnail" [ngStyle]="{'left.px': properties.x, 'top.px': properties.y, 'width.px': properties.width}" >
        <div class="campaign-thumbnail__image">
            <app-clip-player  *ngIf="activeClip; else defaultClip" [clip]="activeClip" class="clips__player"></app-clip-player>
        </div>
        <div class="campaign-thumbnail__bottom">
            <div class="campaign-thumbnail__title">
                <h4>{{ campaign.name }}</h4>
                <div *ngIf="campaign.type=='weight'" i18n>váha - {{campaign.weight}}</div>
                <div *ngIf="campaign.type=='interval'" i18n>Opakování každý {{campaign.interval}} klip</div> 
                <div *ngIf="campaign.type=='fixed'" i18n> Fixni přehrávání  od: {{campaign.fixedStartTime}} do: {{campaign.fixedEndTime}} </div>
            </div>
            <button color="primary" class="mat-mini-fab--small" mat-mini-fab i18n-aria-label aria-label="Upravit kampaň" (click)="openCampaignDetail(campaign.id)" >
                <mat-icon>edit</mat-icon>
            </button>
        </div>
    </div>

    <div id="campaign-preview" *ngIf="template==='statistics'" class="campaign-thumbnail" [ngStyle]="{'left.px': properties.x, 'top.px': properties.y, 'width.px': properties.width}" >

        <div class="campaign-thumbnail__bottom">
            <div class="campaign-thumbnail__title">
                <h4>{{ campaign.name }}</h4>
                <div>{{campaign.count}}</div>
            </div>
            <button color="primary" class="mat-mini-fab--small" mat-mini-fab i18n-aria-label aria-label="Otevřít detail kampaně" (click)="openCampaignDetail(campaign.id)" >
                <mat-icon>edit</mat-icon>
            </button>
        </div>
    </div>
</div>

<ng-template #defaultClip>
    <app-clip-player  [clip]="campaign.videoclips[0]" class="clips__player"></app-clip-player>
</ng-template>

