import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  AfterViewInit,
  Injectable,
  ViewEncapsulation,
} from "@angular/core";
import { Campaign, CampaingEndAction } from "../../models/campaign.model";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  FormArray,
  Validators,
  ValidatorFn,
} from "@angular/forms";

import * as dayjs from "dayjs";
import { DateAdapter, NativeDateAdapter } from "@angular/material/core";
import { CampaignTimeComponent } from "../campaign-time/campaign-time.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-campaign-date",
  templateUrl: "./campaign-date.component.html",
  styleUrls: ["./campaign-date.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CampaignDateComponent implements OnInit, AfterViewInit {
  days: Array<any>;
  campaingEndAction = CampaingEndAction;
  @Input() campaign: Campaign;
  @Output() validated = new EventEmitter();
  dateFormGroup: FormGroup;

  constructor(
    private fb: FormBuilder,
    private dateAdapter: DateAdapter<Date>,
    public dialog: MatDialog
  ) {
    this.days = [
      {
        name: "Pondělí",
        value: 1,
      },
      {
        name: "Útery",
        value: 2,
      },
      {
        name: "Středa",
        value: 3,
      },
      {
        name: "Čtvrtek",
        value: 4,
      },
      {
        name: "Pátek",
        value: 5,
      },
      {
        name: "Sobota",
        value: 6,
      },
      {
        name: "Neděle",
        value: 7,
      },
    ];
  }

  ngOnInit() {
    this.dateAdapter.setLocale("cs");
    this.dateAdapter.getFirstDayOfWeek = () => {
      return 1;
    };

    if (!this.campaign.startTime) {
      this.campaign.startTime = "00:00";
    }

    this.dateFormGroup = this.fb.group({
      startDate: new FormControl(this.campaign.startDate, Validators.required),
      startTime: new FormControl(this.campaign.startTime, Validators.required),
      endDate: new FormControl(this.campaign.endDate),
      endTime: new FormControl(this.campaign.endTime),
      actionAfterEnd: new FormControl(this.campaign.actionAfterEnd),
      untilStop: new FormControl(this.campaign.untilStop),
      useDayTime: new FormControl(this.campaign.useDayTime),
      useDaySelection: new FormControl(this.campaign.useDaySelection),
      dayStartTime: new FormControl(this.campaign.dayStartTime),
      dayEndTime: new FormControl(this.campaign.dayEndTime),
      daysSelected: new FormControl(this.campaign.daysSelected),
      days: new FormArray([]),
      startFixedTimes: new FormArray([]),
    });

    this.addCheckboxes();

    this.untilStop.valueChanges.subscribe((checked) => {
      if (!checked) {
        this.endDate.setValidators([Validators.required]);
        this.endTime.setValidators([Validators.required]);
      } else {
        this.endDate.setValidators(null);
        this.endTime.setValidators(null);
      }
      this.endDate.updateValueAndValidity();
      this.endTime.updateValueAndValidity();

      if (!this.endDate.valid) {
        this.endDate.setValue(this.addDaysToDate(1));
      }
      if (!this.endTime.valid) {
        this.endTime.setValue(this.getActualTime(), { emitEvent: false });
      }
    });

    this.useDayTime.valueChanges.subscribe((checked) => {
      if (checked) {
        this.dayStartTime.setValidators([Validators.required]);
        this.dayEndTime.setValidators([Validators.required]);
      } else {
        this.dayStartTime.setValidators(null);
        this.dayEndTime.setValidators(null);
      }
      this.dayStartTime.updateValueAndValidity();
      this.dayEndTime.updateValueAndValidity();
    });

    this.dateFormGroup.valueChanges.subscribe((value) => {
      this.updateDataModel(value);
    });
  }

  ngAfterViewInit() {
    this.checkValidity();
  }

  checkValidity() {
    this.validated.emit({
      status: this.dateFormGroup.status,
      message: "Musíte vybrat nebo vytvořit playlist",
    });
  }

  private addCheckboxes() {
    this.days.map((o, i) => {
      let isSelected = false;
      this.campaign.daysSelected.forEach((value) => {
        if (value === o.value) {
          isSelected = true;
        }
      });
      const control = new FormControl(isSelected);
      (this.dateFormGroup.controls.days as FormArray).push(control);
    });
  }

  minSelectedCheckboxes(min = 1) {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const totalSelected = formArray.controls
        .map((control) => control.value)
        .reduce((prev, next) => (next ? prev + next : prev), 0);

      return totalSelected >= min ? null : { required: true };
    };
    return validator;
  }
  get daysArray(): FormArray {
    return this.dateFormGroup.get("days") as FormArray;
  }
  get untilStop() {
    return this.dateFormGroup.get("untilStop") as FormControl;
  }
  get endDate() {
    return this.dateFormGroup.get("endDate") as FormControl;
  }
  get endTime() {
    return this.dateFormGroup.get("endTime") as FormControl;
  }
  get useDayTime() {
    return this.dateFormGroup.get("useDayTime") as FormControl;
  }
  get dayStartTime() {
    return this.dateFormGroup.get("dayStartTime") as FormControl;
  }
  get dayEndTime() {
    return this.dateFormGroup.get("dayEndTime") as FormControl;
  }
  updateDataModel(value) {
    this.campaign = Object.assign(this.campaign, value);
    this.campaign.daysSelected = this.dateFormGroup.value.days
      .map((v, i) => (v ? this.days[i].value : null))
      .filter((v) => v !== null);

    this.campaign.startDate = this.mergeDatesWithTime(
      this.campaign.startDate,
      this.campaign.startTime
    );

    this.campaign.endDate = this.mergeDatesWithTime(
      this.campaign.endDate,
      this.campaign.endTime
    );

    this.checkValidity();
  }

  changeTime(time: string) {
    console.log(time);
  }
  mergeDatesWithTime(date: string, time: string): string {
    if (time) {
      const timeArray = time.split(":").map(Number);
      const editedDate = dayjs(date)
        .set("hour", timeArray[0])
        .set("minute", timeArray[1])
        .set("second", 0)
        .set("millisecond", 0)
        .toISOString(); // new Date(date).setHours(timeArray[0], timeArray[1], 0, 0);
      return editedDate;
    } else {
      if (!date) {
        date = new Date().toISOString();
      }
      time = this.getActualTime();
      const timeArray = time.split(":").map(Number);
      const editedDate = dayjs(date)
        .set("hour", timeArray[0])
        .set("minute", timeArray[1])
        .set("second", 0)
        .set("millisecond", 0)
        .toISOString(); // new Date(date).setHours(timeArray[0], timeArray[1], 0, 0);
      return editedDate;
    }
  }
  addDaysToDate(days: number) {
    return dayjs().add(days, "day").toISOString();
  }
  private getActualTime() {
    const today = new Date();
    const time = today.getHours() + ":" + today.getMinutes();
    return time;
  }

  openTimes() {
    const dialogRef = this.dialog.open(CampaignTimeComponent, {
      disableClose: true,
      width: "100%",
      minHeight: "500px",
      maxWidth: "95vw",
      data: {
        times: this.campaign.fixedStartTimes,
        repeatFixedCampaign: this.campaign.repeatFixedCampaign,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        this.campaign.fixedStartTime = null;
        this.campaign.fixedStartTimes = result.data.times;
        this.campaign.repeatFixedCampaign = result.data.repeatFixedCampaign;
        this.checkValidity();
      }
    });
  }
}
