import { Injectable, inject } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn } from '@angular/router';
import { UserService } from '../user/user.service';


@Injectable({ providedIn: 'root' })
class PermissionsService  {

  constructor(
    private router: Router,
    private userService: UserService,
  ) { }
    
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const currentUser = this.userService.user;
    if ( currentUser) {
        

         // authorised so return true
        return true;
    } else {
      // not logged in so redirect to login page with the return url
      this.redirectForUnauthorized();
      return false;
    }
  }
  redirectForUnauthorized() {
    this.router.navigate(["/login"]);
  }
}
export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  return inject(PermissionsService).canActivate(next, state);
}
