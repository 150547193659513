import { Component, OnInit } from "@angular/core";
import { UserService } from "src/app/user/user.service";
import { Company } from "src/app/models/company.model";

@Component({
  selector: "app-company-select-dialog",
  templateUrl: "./company-select-dialog.component.html",
  styleUrls: ["./company-select-dialog.component.scss"],
})
export class CompanySelectDialogComponent implements OnInit {
  public selectedCompany: Company | null= null;
  constructor(private userService: UserService) {}

  ngOnInit() {
    this.selectedCompany = this.userService.activeCompany;
  }
  companySelect(company: any) {
    this.selectedCompany = company;
  }
  saveCompany() {
    this.userService.activeCompany = this.selectedCompany;
    location.reload();
  }
}
