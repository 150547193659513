<div class="dialog campaigns-selection">
    <div mat-dialog-title>
        <h2>Výběr Kampaně</h2>
        <button class="dialog__close" mat-icon-button mat-dialog-close i18n-aria-label aria-label="Zavřít dialog">
            <mat-icon>clear</mat-icon>
        </button>
    </div>

    <div class="dialog__content campaigns-selection__content" mat-dialog-content>
        <app-campaigns-list [multipleActions]="false" [short]="true" [checkable]="true" [checkedCampaigns]="checkedCampaignIds" (checkCampaign)="changeAttachedCampaign($event)"></app-campaigns-list>
    </div>

    <div mat-dialog-actions class="dialog__footer dialog__footer--reverse">
        <button mat-raised-button color="primary" (click)="saveSelect()" i18n-aria-label aria-label="Vybrat kampaň"><span i18n>Vybrat</span><mat-icon color="white">check</mat-icon></button>
    </div>
</div>