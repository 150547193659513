<div class="campaigns-list" [ngClass]="{'campaigns-list--multiple-actions' : multipleActions && selection.selected.length > 0, 'campaigns-list--checked' : selection.selected.length > 0}">

    <div class="campaigns-list__header">
        <div class="campaigns-list__filters" [ngClass]="{'campaigns-list__filters--short' : short}">
            <div class="campaigns-list__filters__search">
                <mat-form-field  class="campaigns-list__filters__input mat-mdc-form-field--fullwidth ">
                    <mat-label i18n>Hledej kampaň</mat-label>
                    <input (keyup)="filterByFulltext($event.target['value'])" [ngModel]="this.filters.fulltext" matInput>
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
                <button  class="campaigns-list__filters__clear" *ngIf="hasFilters()" mat-button (click)="resetFilters()">Vymazat filtry</button>
                <app-tag-menu [selectedTags]="filters.tags" (selected)="filterByTags($event)" [tagType]="'campaigns'" class="campaigns-list__filters__tag-menu" [selectable]="true" ></app-tag-menu>
              </div>
            <div class="campaigns-list__header__actions" >
                <button *ngIf="!short" mat-raised-button color="primary" (click)="openNewCampaign()"><span i18n>Přidat novou kampaň</span><mat-icon>add</mat-icon></button>
            </div>
        </div>
        <div class="campaigns-list__filters__states" *ngIf="!short" i18n-aria-label aria-label="Filtrovat podle stavu" >
            <app-campaign-state [defaultActive]="filters.states" (selectedStates)="filterByState($event);"></app-campaign-state>
        </div>
    </div>
    
    <div class="campaigns-list__content">
        <div class="campaigns-list__wrapper mat-elevation-z2">
          <table mat-table [dataSource]="dataSource"  class="mat-table--fullwidth" matSort  matSortActive="id" matSortDisableClear matSortDirection="desc">
              <!-- Preview Column -->
              <ng-container ng-container matColumnDef="id"  >
                  <th mat-header-cell *matHeaderCellDef class="campaigns-list__checkbox-header" >
                      <mat-checkbox 
                              *ngIf="checkable"
                              (change)="$event ? masterToggle() : null"
                              color="primary"
                              [checked]="selection.hasValue() && isAllSelected()"
                              [indeterminate]="selection.hasValue() && !isAllSelected()"
                              [aria-label]="checkboxLabel()">
                      </mat-checkbox>
                      <span mat-sort-header i18n>ID</span>
                  </th>
                  <td mat-cell *matCellDef="let campaign" class="campaigns-list__checkbox-column" > 
                  <mat-checkbox  *ngIf="checkable" class="campaigns-list__checkbox" (click)="$event.stopPropagation()"
                      color="primary"
                      (change)="$event ? selection.toggle(campaign) : null"
                      [checked]="selection.isSelected(campaign)"
                      [aria-label]="checkboxLabel(campaign)">
                  </mat-checkbox>
                  <span class="campaigns-list__id">{{campaign.id}}</span>
              </td>
              </ng-container>

              <!-- Preview Column -->
              <ng-container matColumnDef="preview">
                  <th mat-header-cell *matHeaderCellDef i18n>Náhled</th>
                  <td mat-cell *matCellDef="let campaign; let i = index " class="campaigns-list__card__preview"> 
                  <div *ngIf="campaign.preview" class="campaigns-list__preview-container" (click)="openCampaignDetail(campaign.id)">
                      <img i18n-alt alt="náhled kampaně" loading=lazy (error)="addPlaceholder($event, i)" [src]="campaign.preview.small.url!= null ? campaign.preview.small.url : 'assets/img/placeholders/device-thumbnail.png'">
                  </div>
                  </td>
              </ng-container>
              
              <!-- Color Column -->
              <ng-container matColumnDef="color">
                  <th mat-header-cell *matHeaderCellDef i18n>Barva</th>
                  <td mat-cell *matCellDef="let campaign"  class="campaigns-list__color"><span [style.background-color]="campaign.color"></span></td>
              </ng-container>

              <!-- Title Column -->
              <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Název</th>
                  <td mat-cell *matCellDef="let campaign" class="campaigns-list__title" (click)="openCampaignDetail(campaign.id)"><h3>{{campaign.name}}</h3></td>
              </ng-container>

              <!-- Start Column -->
              <ng-container matColumnDef="startDate">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Začátek</th>
                  <td mat-cell *matCellDef="let campaign"><span> {{campaign.startDate | date:'shortDate'}} - {{campaign.startTime}}</span></td>
              </ng-container>

              <!-- end Column -->
              <ng-container matColumnDef="endDate">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Konec</th>
                  <td mat-cell *matCellDef="let campaign"><span>{{campaign.endDate | date:'shortDate'}} - {{campaign.endTime}}</span></td>
              </ng-container>

              <!-- Weight Column -->
              <ng-container matColumnDef="type">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Typ</th>
                  <td mat-cell *matCellDef="let element"> 
                      <span *ngIf="element.type=='weight'" i18n>váha - {{element.weight}}</span>
                      <span *ngIf="element.type=='interval'" i18n>Opakování každý {{element.interval}} klip</span>
                      <span *ngIf="element.type=='fixed'" i18n>Fixni přehrávání  {{element.fixedTime}}</span>
                      <span *ngIf="element.type=='playlist'" i18n>Playlist</span>
                  </td>
              </ng-container>

              <!-- state Column -->
              <ng-container matColumnDef="state">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Status</th>
                  <td mat-cell *matCellDef="let campaign">
                      <app-campaign-state [state]="campaign.state"></app-campaign-state>
                  </td>
              </ng-container>

              <!-- devices Column -->
              <ng-container matColumnDef="selectedDevices">
                  <th mat-header-cell *matHeaderCellDef i18n>Na zařízení</th>
                  <td mat-cell *matCellDef="let campaign" class="campaigns-list__on-device">
                  <app-devices-menu-list [devices]="campaign.selectedDevices"></app-devices-menu-list>
                  </td>
              </ng-container>

              <!-- Tags Column -->
              <ng-container matColumnDef="tags">
                  <th mat-header-cell *matHeaderCellDef i18n>Štítky</th>
                  <td mat-cell *matCellDef="let campaign">
                      <app-tag-menu [tags]="campaign.tags"></app-tag-menu>
                  </td>
              </ng-container>
              <!-- Campaign edits -->
              <ng-container matColumnDef="edit" >
                  <th mat-header-cell *matHeaderCellDef i18n min-width="60px">Úpravy</th>
                  <td mat-cell *matCellDef="let campaign" class="campaigns-list__actions" min-width="60px">
                      <button i18n-matTooltip matTooltip="Úprava kampaně" color="primary" class="mat-mdc-mini-fab--small campaigns-list__actions__edit" mat-mini-fab i18n-aria-label aria-label="Upravit kampaň" (click)="openCampaignDetail(campaign.id)" >
                          <mat-icon>edit</mat-icon>
                      </button>
                  </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="campaigns-list__row"></tr>
          </table>
          <div class="campaigns-list__loading"  *ngIf="isLoadingResults">
            <app-loading></app-loading>
          </div>
        </div>
        <div class="campaigns-list__actions-bar" *ngIf="selection.selected.length>0 && multipleActions">
            <app-campaigns-multiple-actions [selection]="selection.selected" (selectionChange)="multipleActionsChanged($event)"></app-campaigns-multiple-actions>
        </div>
    </div>
</div>
