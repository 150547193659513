import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TagsService } from './tags.service';
import { Tag } from '../models/tag.model';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TagsComponent implements OnInit {

    constructor(private tagsService: TagsService) { }

    ngOnInit() {
    }

    newTag(type: string) {
        const tag = new Tag();
        tag.value = null;
        tag.type = type;
        tag.group = 'client';
        this.tagsService.openTagManager(tag);
    }

}
