import { Pipe, PipeTransform } from '@angular/core';
import { Layout,  LayoutPreviewFilters } from '../models/layout.model';

@Pipe({
  name: 'layoutPreviewFilter'
})
export class LayoutPreviewFilterPipe implements PipeTransform {
    transform(layouts: Layout[], filters: LayoutPreviewFilters): any {
        if (layouts && filters) {
            let filteredResult: Layout[] = [];
            if(filters.orientation) {
                filteredResult = layouts.filter(layout => layout.orientation === filters.orientation);
            }
            return filteredResult;
        } else {
          return layouts
        }
      }
}
