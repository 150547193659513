import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { GoogleMapsModule } from '@angular/google-maps';


import { DevicesMapComponent } from './devices-map.component';

@NgModule({
    declarations: [
        DevicesMapComponent,
    ],
    imports: [
      CommonModule,
      HttpClientModule,
      HttpClientJsonpModule,
      GoogleMapsModule
    ],
    exports: [
        DevicesMapComponent,
    ],
  })
  export class DevicesMapModule {}