import { CustomUtils } from "../utils/custom-utils.component";
import { ContentClip } from "./content-clip.model";
import { Tag } from "./tag.model";

export enum CampaingEndAction {
  StopCampaign = "StopCampaign",
  DeleteCampaing = "DeleteCampaing",
  DeleteCampaingWithContent = "DeleteCampaingWithContent",
}

export class Campaign {
  id: number;
  name: string;
  description: string;
  companyId: number;
  companyName: string;
  author: string;
  lastEditor: string;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  actionAfterEnd: CampaingEndAction;
  untilStop: boolean;
  useDayTime: boolean;
  dayStartTime: string;
  dayEndTime: string;
  useDaySelection: boolean;
  daysSelected: Array<string>;
  type: string; //playlist | weight | interval | fixed
  weight: number;
  interval: number;
  fixedStartTime: string;
  fixedStartTimes: string[];
  fixedRepeat: boolean;
  fixedEndTime: string;
  repeatFixedCampaign: boolean;
  playlistId: number;
  playlistSort: number;
  totalCampaignTime: number;
  videoclips: Array<ContentClip>;
  selectedDevices: Array<number>;
  state: string;
  tags: Array<Tag>;
  isActive: boolean;
  edited: string;
  created: string;
  color: string;
  fullscreen: boolean;
  preview: {
    small: {
      url: string;
    };
    large: {
      url: string;
    };
  };
  fixedClips?: Array<ContentClip>;
  duration?: number;
  isTemporarilyInactive?: boolean;
  yPos?: string;
  radioFade: boolean;
  constructor() {
    this.id= 1;
    this.type= "weight";
    this.name= "";
    this.description= "";
    this.weight= 50;
    this.interval= 2;
    this.untilStop= false;
    this.useDayTime= false;
    this.useDaySelection= false;
    this.daysSelected= [];
    this.selectedDevices= [];
    this.startDate= CustomUtils.getDefaultDate(0, { h: 0, m: 0 }).toISOString();
    this.startTime= CustomUtils.getDefaultDate(0, { h: 0, m: 0 }).format("HH:mm");
    this.endDate= CustomUtils.getDefaultDate(7, { h: 23, m: 55 }).toISOString();
    this.endTime= CustomUtils.getDefaultDate(7, { h: 23, m: 55 }).format("HH:mm");
    this.actionAfterEnd= CampaingEndAction.StopCampaign;
    this.videoclips= [];
    this.tags= [];
    this.isActive= true;
    this.fixedStartTime= CustomUtils.getActualTime();
    this.fixedStartTimes= [];
    this.fixedEndTime= CustomUtils.getActualTime(); // TODO remove
    this.color= CustomUtils.getRandomColor();
    this.fullscreen= false;
    this.companyId= null;
    this.radioFade= false;
    this.repeatFixedCampaign= false;
  }
}
