import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Campaign } from '../../models/campaign.model';
import { DevicePreview } from '../../models/device-preview.model';
import { DevicesService } from '../../devices/devices.service';

import { ContentClip } from '../../models/content-clip.model';

@Component({
  selector: 'app-campaign-recapitulation',
  templateUrl: './campaign-recapitulation.component.html',
  styleUrls: ['./campaign-recapitulation.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class CampaignRecapitulationComponent implements OnChanges
{
  @Input() campaign: Campaign;
  selectedDevicesInfo: DevicePreview[];
  constructor(private devicesService: DevicesService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.campaign) {
      this.campaign = changes.campaign.currentValue;
      if (changes.campaign.currentValue !== changes.campaign.previousValue) {
        this.fetchDevicesInfo();
      }
    }
  }

  fetchDevicesInfo() {
    if ( this.campaign.selectedDevices.length > 0 ) {
      this.devicesService.fetchUserDevices(this.campaign.selectedDevices).subscribe((data) => {
        this.selectedDevicesInfo = data;
      });
    }
  }
}
