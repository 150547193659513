
<mat-card class="clips card card--large card--soft-shadow">
    <mat-card-title><mat-icon color="primary">music_video</mat-icon><span i18n>Video a obsah</span></mat-card-title>
    <div class="clips__container">
        <mat-tab-group animationDuration="0" (selectedTabChange)="tabChanged($event)" mat-stretch-tabs="false">
            <mat-tab >
                <ng-template mat-tab-label i18n>Aktivní</ng-template>
                <app-clips-list *ngIf="activeTabIndex == 0" [multipleActions]="true" [category]="'active'"></app-clips-list>
            </mat-tab>
            <mat-tab >
                <ng-template mat-tab-label i18n>Archiv</ng-template>
                <app-clips-list *ngIf="activeTabIndex == 1" [multipleActions]="true" [category]="'archive'"></app-clips-list>
            </mat-tab>
        </mat-tab-group>
    </div>
  
</mat-card>