import { Component, OnInit } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { CompanyService } from 'src/app/company/company.service';
import { CompanyStats } from 'src/app/models/company-stats.model';
import {of as observableOf} from 'rxjs';

@Component({
  selector: 'app-current-states',
  templateUrl: './current-states.component.html',
  styleUrls: ['./current-states.component.scss']
})
export class CurrentStatesComponent implements OnInit {
  isLoadingResults = false;
  stats: CompanyStats;
  constructor(private companyService: CompanyService ) { }

  ngOnInit() {
   this.fetchStats();
  }
  fetchStats() {
    this.isLoadingResults = true;
    this.companyService.fetchCompanyStatistics().pipe(
      
      catchError(() => {
        this.isLoadingResults = false;
        console.log("Error with downloading clips");
        return observableOf([]);
      }),
    ).subscribe((data: CompanyStats) => {
      this.isLoadingResults = false;
      this.stats = data;
    })
  }
}
