import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ClipsService } from './clips.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-clips',
  templateUrl: './clips.component.html',
  styleUrls: ['./clips.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClipsComponent implements OnInit {
  activeTabIndex = 0;
  constructor(private clipsService: ClipsService, public dialog: MatDialog) {}
  ngOnInit() {}

  openUploadDialog() {
    this.clipsService.openClipUploadDialog();
  }
  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.activeTabIndex = tabChangeEvent.index;
  }
}
