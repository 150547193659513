<div class="timeline">
    <div class="timeline__container">
       
        <div class="timeline__campaign-type">
            <div class="timeline__campaign-type__row"><span i18n>Fixní kampaně</span></div>
            <div class="timeline__campaign-type__row"><span i18n>Opakující se kampaně *</span></div>
        </div>
        <cdk-virtual-scroll-viewport  itemSize="deviceTime.workingSeconds" minBufferPx="200" maxBufferPx="400" orientation="horizontal">
            <div class="timeline__list">
                <div  class="timeline__current-time" [ngStyle]="{'left.px' : actualPos}"></div>
                <div  class="timeline__loops" style="top: 0" >
              
                    <div class="timeline__campaign timeline__campaign--fixed" *ngFor="let campaign of fixedItems" [ngStyle]="{'width.px': campaign.fixedRepeatDuration, 'left.px': campaign.startTime,  'overflow': 'hidden',  'background-color':  campaign.color}" i18n-matTooltip matTooltip="Kampaň není dnes aktivní"  [matTooltipDisabled]="!campaign.isTemporarilyInactive" >
                        <div class="timeline__campaign__wrapper" *ngFor="let clipLoops of repeatedClipsInCampaign(campaign); let i = index" >
                            <div class="timeline__campaign__clip" 
                                (click)="showPreview(campaign.id, clipIndex, $event)"  
                                [ngStyle]="{'width.px': clip.duration, 'background-color':  campaign.color }"
                                *ngFor="let clip of campaign.videoclips; let clipIndex = index">
                            </div>
                        </div>
                    </div>
                </div>
                <div  class="timeline__loops" >
                    <div  class="timeline__campaign" *cdkVirtualFor="let campaign of repeatedItems; templateCacheSize: 0" >
                        <div class="timeline__campaign__clip"
                            (click)="showPreview(campaign.id, clipIndex, $event)" 
                            [ngStyle]="{'width.px': clip.duration, 'background-color': campaign.color}" 
                            *ngFor="let clip of campaign.videoclips; let clipIndex = index">
                        </div>
                    </div>
                </div>
                <div class="timeline__units" *ngIf="labelsArray.length>0" [ngStyle]="{'margin-left.px': -15}">
                    <div class="timeline__units__second" [ngStyle]="{'width.px': 60}"  *ngFor="let label of labelsArray">
                        {{label}}
                    </div>
                </div>
            </div>   
        </cdk-virtual-scroll-viewport>
        <div></div>
        <div class="timeline__actions">
            <div class="timeline__actual-time" [ngStyle]="{'left' : actualTimeButtonPos +'%'}">
                <button matTooltip="Aktuální čas" mat-icon-button (click)="jumpToActuialTime()"> <mat-icon>access_time</mat-icon></button>
                <span>{{ actualTime }}</span>
            </div>
        </div>
    </div>
    <div class="timeline__note">
        <p i18n>* Časy jsou pouze orientační. Nereflektují rychlost stahování ani update kampaní proto může dojít k posunu smyčky na zařízení. </p>
    </div>
</div>



<ng-template #loading>
    <app-loading></app-loading>
</ng-template>

